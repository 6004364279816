import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import Page from '../../shared/Page';
import UsesCompany from '../../shared/company/UsesCompany';
import { UserStore } from '../../../app/stores/user/UserStore';
import Card from '../../shared/Card';
import Container from '../../shared/Container';
import Button from '../../shared/Button';
import Modal from '../../shared/Modal';
import { PaymentMethodStore } from '../../../app/stores/payment-methods/PaymentMethodStore';
import { IPaymentMethod } from '../../../app/stores/payment-methods/interfaces/IPaymentMethod';
import CreatePaymentMethodModal from './CreatePaymentMethodModal';
import { NotificationStore } from '../../../app/stores/notifications/NotificationStore';
import Notification from '../../shared/Notification';
import generateGuid from '../../../app/utils/generateGuid';

export interface PaymentMethodsListPageProps {
    userStore?: UserStore;
    paymentMethodStore?: PaymentMethodStore;
    notificationStore?: NotificationStore;
}

@inject('userStore', 'paymentMethodStore', 'notificationStore')
@observer
class PaymentMethodsListPage extends React.Component<PaymentMethodsListPageProps, unknown> {
    @observable private paymentMethods: IPaymentMethod[] = [];
    @observable private createPaymentMethodModalOpen = false;

    public componentDidMount() {
        const { userStore } = this.props;

        if (userStore?.currentCompany) {
            this.refreshPaymentMethods();
        }
    }

    private onPaymentMethodCreated(): void {
        const { userStore, notificationStore } = this.props;

        this.createPaymentMethodModalOpen = false;

        notificationStore?.addNotification({
            message: 'Betalingsmethode succesvol toegevoegd',
            variant: 'success',
            autoClose: true
        });

        if (userStore?.currentCompany) {
            this.refreshPaymentMethods();
        }
    }

    private refreshPaymentMethods(): void {
        const { userStore, paymentMethodStore } = this.props;

        if (userStore?.currentCompany) {
            paymentMethodStore?.getPaymentMethods(
                userStore.currentCompany.company.uuid
            ).then((vatRates: IPaymentMethod[]) => {
                this.paymentMethods = vatRates;
            });
        }
    }

    private renderPaymentMethodsList(): React.ReactElement {
        if (!this.paymentMethods.length) {
            return (
                <Notification
                    variant="info"
                    message="Er zijn nog geen betalingsmethodes toegevoegd"
                    id={generateGuid()}
                />
            );
        }

        return (
            <table className="table" id="payment-methods-list">
                <thead>
                    <tr>
                        <th>Naam</th>
                    </tr>
                </thead>
                <tbody>
                    {this.paymentMethods.map((paymentMethod: IPaymentMethod) => (
                        <tr key={paymentMethod.uuid}>
                            <td>{paymentMethod.name}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    }

    public render(): React.ReactNode {
        const { paymentMethodStore, notificationStore, userStore } = this.props;

        return (
            <Page
                title="Overzicht van betalingsmethodes"
                actions={(
                    <Button
                        variant="primary"
                        id="create-payment-method"
                        disabled={!userStore?.currentCompany}
                        onClick={() => {
                            this.createPaymentMethodModalOpen = true;
                        }}
                    >
                        Toevoegen
                    </Button>
                )}
            >
                <Container center className="w-3/4">
                    <UsesCompany
                        onCompanySelected={() => {
                            this.refreshPaymentMethods();
                        }}
                    >
                        <Card>
                            {paymentMethodStore?.isLoading('get-payment-methods') ? 'Loading...' : this.renderPaymentMethodsList()}
                        </Card>
                    </UsesCompany>
                </Container>
                <Modal
                    isOpen={this.createPaymentMethodModalOpen}
                    onRequestClose={() => {
                        this.createPaymentMethodModalOpen = false;
                        notificationStore?.clear(CreatePaymentMethodModal.NOTIFICATION_NAMESPACE);
                    }}
                    title="Betalingsmethode toevoegen"
                    closable
                >
                    <CreatePaymentMethodModal
                        onPaymentMethodCreated={() => {
                            this.onPaymentMethodCreated();
                        }}
                    />
                </Modal>
            </Page>
        );
    }
}

export default PaymentMethodsListPage;
