import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import { RouteComponentProps, withRouter } from 'react-router';
import moment from 'moment-timezone';
import Page from '../../shared/Page';
import UsesCompany from '../../shared/company/UsesCompany';
import { InvoiceStore } from '../../../app/stores/invoices/InvoiceStore';
import Button from '../../shared/Button';
import Container from '../../shared/Container';
import { UserStore } from '../../../app/stores/user/UserStore';
import { IConceptInvoice } from '../../../app/stores/invoices/interfaces/IConceptInvoice';
import { FormErrors } from '../../../app/errors/FormErrors';
import { NotFoundError } from '../../../app/errors/NotFoundError';
import { NotificationStore } from '../../../app/stores/notifications/NotificationStore';
import { DataInvalidError } from '../../../app/errors/DataInvalidError';
import { IInvoice } from '../../../app/stores/invoices/interfaces/IInvoice';
import CreateOrEditInvoice from '../../shared/invoices/CreateOrEditInvoice';
import { ICompanyDetails } from '../../../app/stores/company/interfaces/ICompanyDetails';
import { CompanyStore } from '../../../app/stores/company/CompanyStore';
import { IUserCompany } from '../../../app/stores/user/interfaces/IUserCompany';
import { IStatus } from '../../../app/stores/statuses/interfaces/IStatus';
import { StatusStore } from '../../../app/stores/statuses/StatusStore';
import { history } from '../../../app/utils/history';
import Card from '../../shared/Card';
import { IInvoiceStatus } from '../../../app/stores/invoices/interfaces/IInvoiceStatus';
import Divider from '../../shared/Divider';

interface EditInvoicePageParams {
    uuid: string;
}

export interface EditInvoicePageProps extends RouteComponentProps<EditInvoicePageParams> {
    invoiceStore?: InvoiceStore;
    userStore?: UserStore;
    companyStore?: CompanyStore;
    notificationStore?: NotificationStore;
    statusStore?: StatusStore;
}

@inject('invoiceStore', 'userStore', 'notificationStore', 'companyStore', 'statusStore')
@observer
class EditInvoiceConceptPage extends React.Component<EditInvoicePageProps, unknown> {
    @observable private invoice?: IInvoice;
    @observable private company?: ICompanyDetails;
    @observable private formErrors: FormErrors;
    @observable private statuses: IStatus[] = [];

    public constructor(props: EditInvoicePageProps) {
        super(props);

        this.formErrors = new FormErrors();
    }

    public componentDidMount(): void {
        const { userStore } = this.props;

        if (userStore?.currentCompany) {
            this.refreshInvoice().then(() => {
                this.refreshStatuses();
                this.refreshCompanyDetails(userStore.currentCompany!.company.uuid);
            });
        }
    }

    private refreshStatuses(): void {
        const { statusStore } = this.props;

        statusStore?.getStatuses().then((statuses: IStatus[]) => {
            this.statuses = statuses;
        });
    }

    private refreshCompanyDetails(companyUuid: string): void {
        const { companyStore } = this.props;

        companyStore?.getCompanyDetails(companyUuid).then(
            (company: ICompanyDetails | undefined) => {
                this.company = company;
            }
        );
    }

    private async refreshInvoice(): Promise<void> {
        const {
            invoiceStore,
            notificationStore,
            match: {
                params: {
                    uuid
                }
            }
        } = this.props;

        notificationStore?.clear();

        invoiceStore?.getInvoice(uuid).then((invoice: IInvoice | undefined) => {
            this.invoice = invoice;

            if (invoice) {
                // this.setStatus(this.statuses);
            }
        }).catch((e: NotFoundError | undefined) => {
            notificationStore?.addNotification({
                message: e?.message || 'Factuur niet gevonden',
                messageDetails: e?.details,
                variant: 'danger',
                closable: false
            });
            this.invoice = undefined;
        });
    }

    private updateInvoice(): void {
        const {
            invoiceStore,
            notificationStore,
            match: {
                params: { uuid }
            }
        } = this.props;

        this.formErrors.clear();
        notificationStore?.clear();

        if (this.invoice) {
            invoiceStore?.updateInvoice(
                uuid,
                this.invoice
            ).then((invoice: IConceptInvoice | IInvoice | undefined) => {
                if (invoice) {
                    if ((invoice as IInvoice).invoice_number) {
                        history.push(`/invoices/${invoice.uuid}/details`);
                        return;
                    }

                    notificationStore?.addNotification({
                        variant: 'success',
                        message: `Factuur succesvol gewijzigd`
                    });
                }
            }).catch((e: DataInvalidError) => {
                this.formErrors.set(e.errors);
            });
        }
    }

    private changeCompany(companyUuid: string): void {
        this.refreshInvoice().then(() => {
            this.refreshStatuses();
            this.refreshCompanyDetails(companyUuid);
        });
    }

    public render(): React.ReactNode {
        const {
            invoiceStore
        } = this.props;
        return (
            <Page
                title="Factuur aanpassen"
                actions={this.invoice ? (
                    <>

                        <Button
                            disabled={!this.invoice}
                            id="save-invoice"
                            className="mr-4"
                            variant="secondary"
                            onClick={() => {
                                this.updateInvoice();
                            }}
                            loading={invoiceStore?.isLoading('update-invoice')}
                        >
                            Opslaan
                        </Button>
                    </>
                ) : undefined}
            >
                <Container center className="w-3/4">
                    <UsesCompany
                        onCompanySelected={(userCompany: IUserCompany): void => {
                            this.changeCompany(userCompany.company.uuid);
                        }}
                    >
                        <div className="invoice-grid">
                            <div>
                                {this.invoice && (
                                    <CreateOrEditInvoice<IInvoice>
                                        onInvoiceChanged={(invoice: IInvoice) => {
                                            this.invoice = invoice;
                                        }}
                                        errors={this.formErrors}
                                        invoice={this.invoice}
                                        customer={this.invoice.customer}
                                        company={this.company}
                                    />
                                )}
                            </div>
                            <div>
                                <Card
                                    id="invoice-status-history"
                                    title="Statusgeschiedenis"
                                    className=""
                                >
                                    {this.invoice?.statuses?.map((invoiceStatus: IInvoiceStatus) => (
                                        <>
                                            <div
                                                key={invoiceStatus.uuid}
                                                className="flex mb-4 invoice-status-history-item"
                                            >
                                                <strong>{invoiceStatus.locale}</strong>
                                                <span className="ml-auto">
                                                    {moment(invoiceStatus.status_since).format('DD-MM-YYYY')}
                                                </span>
                                            </div>
                                            <Divider />
                                        </>
                                    ))}
                                </Card>
                            </div>
                        </div>
                    </UsesCompany>
                </Container>
            </Page>
        );
    }
}

export default withRouter(EditInvoiceConceptPage);
