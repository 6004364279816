import * as React from 'react';
import clsx from 'clsx';

interface FormMessageProps extends React.HTMLAttributes<HTMLDivElement> {
    type: 'valid' | 'invalid';
}

const FormMessage = React.forwardRef<HTMLDivElement, FormMessageProps>((
    {
        type,
        children
    },
    ref
): React.ReactElement => (
    <div
        ref={ref}
        className={clsx(
            'form-message',
            type
        )}
    >
        {children}
    </div>
));

FormMessage.displayName = 'FormMessage';

export default FormMessage;
