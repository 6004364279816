export enum ResponseStatus {
    OK = 200,
    CREATED = 201,
    EMPTY = 204,
    BAD_REQUEST = 400,
    INVALID_CREDENTIALS = 401,
    INVALID_PERMISSIONS = 403,
    NOT_FOUND = 404,
    CONFLICT = 409,
    FILE_TOO_LAGE = 413,
    INVALID = 422,
    ERROR = 500
}
