import { LoadingStore } from '../loading/LoadingStore';
import { IIntervalType } from './interfaces/IIntervalType';
import { api } from '../../services/api';
import { ResponseStatus } from '../../services/api/ResponseStatus';
import { IResponse } from '../../services/api/IResponse';
import { application } from '../../application';
import { ApiError } from '../../errors/ApiError';
import { SomethingHappenedError } from '../../errors/SomethingHappenedError';

export class IntervalTypeStore extends LoadingStore {
    public async getIntervalTypes(): Promise<IIntervalType[]> {
        this.setLoading('get-interval-types');

        try {
            const response = await api.get<IResponse<IIntervalType[]>>('/api/intervaltypes');

            if (response.status === ResponseStatus.OK) {
                return response.data.data;
            }
        } catch (e) {
            /* istanbul ignore next */
            application.handleError(new ApiError());
            /* istanbul ignore next */
            return [];
        }

        /* istanbul ignore next */
        application.handleError(new SomethingHappenedError());
        /* istanbul ignore next */
        return [];
    }
}

const intervalTypeStore = new IntervalTypeStore();

export {
    intervalTypeStore
};
