import * as React from 'react';
import clsx from 'clsx';

interface ListItemProps extends React.HTMLAttributes<HTMLDivElement> {
}

const ListItem = React.forwardRef<HTMLDivElement, ListItemProps>((
    {
        children,
        className,
        ...rest
    },
    ref
): React.ReactElement => (
    <div
        ref={ref}
        className={clsx(
            'list-item',
            className
        )}
        {...rest}
    >
        {children}
    </div>
));

ListItem.displayName = 'ListItem';

export default ListItem;
