import { LoadingStore } from '../loading/LoadingStore';
import { api } from '../../services/api';
import { IResponse } from '../../services/api/IResponse';
import { ResponseStatus } from '../../services/api/ResponseStatus';
import { application } from '../../application';
import { ApiError } from '../../errors/ApiError';
import { SomethingHappenedError } from '../../errors/SomethingHappenedError';
import { DataInvalidError } from '../../errors/DataInvalidError';
import { IErrorResponse } from '../../services/api/IErrorResponse';
import { IPaymentMethod } from './interfaces/IPaymentMethod';

export class PaymentMethodStore extends LoadingStore {
    public async getPaymentMethods(companyUuid: string): Promise<IPaymentMethod[]> {
        this.setLoading('get-payment-methods');

        try {
            const response = await api.get<IResponse<IPaymentMethod[]>>(`/api/companies/${companyUuid}/paymentmethods`);

            if (response.status === ResponseStatus.OK) {
                return response.data.data;
            }
        } catch (e) {
            /* istanbul ignore next */
            application.handleError(new ApiError(e));
            /* istanbul ignore next */
            return [];
        } finally {
            this.setLoaded('get-payment-methods');
        }
        /* istanbul ignore next */
        application.handleError(new SomethingHappenedError());
        /* istanbul ignore next */
        return [];
    }

    public async createPaymentMethod(companyUuid: string, name: string): Promise<IPaymentMethod | undefined> {
        this.setLoading('create-payment-methods');

        try {
            const response = await api.post<IResponse<IPaymentMethod> | IErrorResponse>('/api/paymentmethods', {
                company_uuid: companyUuid,
                name
            });

            if (response.status === ResponseStatus.CREATED) {
                return (response.data as IResponse<IPaymentMethod>).data;
            }
        } catch (e) {
            if (e.response?.status === ResponseStatus.INVALID) {
                throw new DataInvalidError(
                    (e.response.data as IErrorResponse).message,
                    (e.response.data as IErrorResponse).errors!
                );
            } else if (e.response?.status === ResponseStatus.CONFLICT) {
                throw new DataInvalidError(
                    (e.response.data as IErrorResponse).message,
                    {}
                );
            }
            /* istanbul ignore next */
            application.handleError(new ApiError(e));
            /* istanbul ignore next */
            return undefined;
        } finally {
            this.setLoaded('create-payment-methods');
        }
        /* istanbul ignore next */
        application.handleError(new SomethingHappenedError());
        /* istanbul ignore next */
        return undefined;
    }
}

const paymentMethodStore = new PaymentMethodStore();

export {
    paymentMethodStore
};
