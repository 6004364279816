export enum NotificationType {
    NO_CONNECTION = 'NO_CONNECTION',
    BACKEND_ERROR = 'BACKEND_ERROR',
    PERMISSIONS_ERROR = 'PERMISSIONS_ERROR',
    TEST = 'TEST'
}

export interface INotification {
    id: string;
    variant: 'danger' | 'success' | 'info';
    message: string;
    autoClose?: boolean;
    closable?: boolean
    messageDetails?: string;
    messageDetailsList?: string[];
    type?: NotificationType;
    once?: boolean;
    namespace?: string;
}
