import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { GuardedRouteProps } from './interfaces/GuardedRouteProps';
import { permissionStore } from '../../../app/stores/permissions/PermissionStore';
import { authStore } from '../../../app/stores/auth/AuthStore';

const GuestRoute = ({
    component,
    permissions,
    ...rest
}: RouteProps & GuardedRouteProps): React.ReactElement => (
    <Route
        {...rest}
        render={(props: RouteComponentProps) => (
            !authStore.loggedIn() && permissionStore.canAccessRoute(permissions)
                ? React.createElement(component, props)
                : <Redirect to="/dashboard" />
        )}
    />
);

export default GuestRoute;
