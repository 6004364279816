import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { RouteComponentProps, withRouter } from 'react-router';
import { observable } from 'mobx';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Container from '../../shared/Container';
import UsesCompany from '../../shared/company/UsesCompany';
import Card from '../../shared/Card';
import Page from '../../shared/Page';
import { UserStore } from '../../../app/stores/user/UserStore';
import { CompanyStore } from '../../../app/stores/company/CompanyStore';
import Notification from '../../shared/Notification';
import generateGuid from '../../../app/utils/generateGuid';
import { IListCustomer } from '../../../app/stores/customers/interfaces/IListCustomer';
import { IPaginated } from '../../../app/utils/interface/IPaginated';
import getPage from '../../shared/Paginate/helpers/getPage';
import { PermissionStore } from '../../../app/stores/permissions/PermissionStore';
import Paginate from '../../shared/Paginate';
import { history } from '../../../app/utils/history';
import Button from '../../shared/Button';
import { CustomerStore } from '../../../app/stores/customers/CustomerStore';
import { NotificationStore } from '../../../app/stores/notifications/NotificationStore';

interface CustomerListParams {
    page?: string;
}

export interface CustomerListPageProps extends RouteComponentProps<CustomerListParams> {
    userStore?: UserStore;
    companyStore?: CompanyStore;
    permissionStore?: PermissionStore;
    customerStore?: CustomerStore;
    notificationStore?: NotificationStore;
}

@inject('userStore', 'companyStore', 'permissionStore', 'customerStore', 'notificationStore')
@observer
class CustomerListPage extends React.Component<CustomerListPageProps, unknown> {
    @observable private customerList?: IPaginated<IListCustomer>;

    public componentDidMount(): void {
        const { userStore, match: { params } } = this.props;

        if (userStore?.currentCompany) {
            this.refreshCustomers(userStore.currentCompany.company.uuid, getPage(params));
        }
    }

    private handlePageChange(selected: number): void {
        const { userStore } = this.props;

        if (userStore?.currentCompany) {
            history.push(`/customers/${selected + 1}`);
            this.refreshCustomers(userStore.currentCompany.company.uuid, selected + 1);
        }
    }

    private refreshCustomers(companyUuid: string, page: number): void {
        const { companyStore } = this.props;

        companyStore?.getCustomerList(
            companyUuid,
            page
        ).then((customers: IPaginated<IListCustomer> | undefined) => {
            this.customerList = customers;
        });
    }

    private deleteCustomer(customer: IListCustomer): void {
        const {
            customerStore,
            notificationStore,
            userStore,
            match: { params }
        } = this.props;

        customerStore?.deleteCustomer(customer).then(() => {
            notificationStore?.addNotification({
                variant: 'success',
                message: 'Klant succesvol verwijderd'
            });

            if (userStore?.currentCompany) {
                this.refreshCustomers(userStore.currentCompany?.company.uuid, getPage(params));
            }
        }).catch(() => {});
    }

    private renderCustomerList(): React.ReactNode {
        const { companyStore, permissionStore } = this.props;

        return (
            <div className="mb-4">
                <Card>
                    {this.customerList && !this.customerList.data.length ? (
                        <Notification
                            message="Geen klanten gevonden"
                            variant="info"
                            id={generateGuid()}
                        />
                    ) : (
                        <table className="table" id="customers-list">
                            <thead>
                                <tr>
                                    <th>Naam</th>
                                    <th>E-mailadres</th>
                                    <th>Openstaand bedrag</th>
                                    {permissionStore?.canOneOf(['delete_customer']) && (
                                        // eslint-disable-next-line jsx-a11y/control-has-associated-label
                                        <th />
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                {companyStore?.isInitialOrLoading('get-customer-list') ? (
                                    <tr>
                                        <td colSpan={2}>Gegevens worden geladen...</td>
                                    </tr>
                                ) : this.customerList?.data.map((customer: IListCustomer) => (
                                    <tr key={customer.uuid}>
                                        <td>{`${customer.firstname} ${customer.lastname}`}</td>
                                        <td>{customer.email}</td>
                                        <td>{`€ ${customer.unpaid}`}</td>
                                        <td>
                                            <Button
                                                className="delete-customer"
                                                variant="danger"
                                                icon={<FontAwesomeIcon icon={faTrash} />}
                                                onClick={() => {
                                                    this.deleteCustomer(customer);
                                                }}
                                            >
                                                Verwijderen
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </Card>
            </div>
        );
    }

    public render(): React.ReactNode {
        const { match: { params } } = this.props;

        return (
            <Page title="Klantenoverzicht">
                <Container center className="w-3/4">
                    <UsesCompany
                        onCompanySelected={() => {
                            this.handlePageChange(getPage(params) - 1);
                        }}
                    >
                        <div className="flex">
                            {!!this.customerList?.data.length && (
                                <Paginate
                                    pageCount={this.customerList.meta.last_page}
                                    className="ml-auto mb-4"
                                    onPageChange={({ selected }) => {
                                        this.handlePageChange(selected);
                                    }}
                                    forcePage={getPage(params) - 1}
                                />
                            )}
                        </div>
                        {this.renderCustomerList()}
                    </UsesCompany>
                </Container>
            </Page>
        );
    }
}

export default withRouter(CustomerListPage);
