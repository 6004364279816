import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import { INewVatRate } from '../../../app/stores/vat-rates/interfaces/INewVatRate';
import FormGroup from '../../shared/Forms/Group';
import FormLabel from '../../shared/Forms/Label';
import FormInput from '../../shared/Forms/Input';
import { FormErrors } from '../../../app/errors/FormErrors';
import Button from '../../shared/Button';
import { VatRatesStore } from '../../../app/stores/vat-rates/VatRatesStore';
import { UserStore } from '../../../app/stores/user/UserStore';
import { DataInvalidError } from '../../../app/errors/DataInvalidError';
import { IVatRate } from '../../../app/stores/vat-rates/interfaces/IVatRate';
import FormMessage from '../../shared/Forms/Message';
import { NotificationStore } from '../../../app/stores/notifications/NotificationStore';
import { INotification } from '../../../app/stores/notifications/interfaces/INotification';
import Notification from '../../shared/Notification';

export interface CreateVatRatesModalProps {
    onVatRateEdited: () => void;
    vatRatesStore?: VatRatesStore;
    userStore?: UserStore;
    notificationStore?: NotificationStore;
    vatRate: IVatRate;
}

@inject('vatRatesStore', 'userStore', 'notificationStore')
@observer
class EditVatRateModal extends React.Component<CreateVatRatesModalProps, unknown> {
    public static NOTIFICATION_NAMESPACE = 'edit_vat_rate';

    @observable private formValues: INewVatRate = {
        name: this.props.vatRate.name,
        percentage: this.props.vatRate.percentage
    };
    @observable private formErrors: FormErrors;

    public constructor(props: CreateVatRatesModalProps) {
        super(props);

        this.formErrors = new FormErrors();
    }

    private createVatRate(event: React.FormEvent) {
        event.preventDefault();

        const {
            onVatRateEdited,
            vatRatesStore,
            userStore,
            notificationStore,
            vatRate
        } = this.props;

        notificationStore?.clear(EditVatRateModal.NOTIFICATION_NAMESPACE);
        this.formErrors.clear();

        if (userStore?.currentCompany) {
            vatRatesStore?.editVatRate(
                vatRate.uuid,
                this.formValues,
            ).then((editedVatRate: IVatRate | undefined) => {
                if (editedVatRate) {
                    onVatRateEdited();
                }
            }).catch((e: DataInvalidError) => {
                notificationStore?.addNotification({
                    variant: 'danger',
                    message: e.message,
                    namespace: EditVatRateModal.NOTIFICATION_NAMESPACE
                });
                this.formErrors.set(e.errors);
            });
        }
    }

    public render(): React.ReactNode {
        const { notificationStore } = this.props;

        return (
            <>
                <div className="mb-4">
                    {notificationStore?.all(
                        EditVatRateModal.NOTIFICATION_NAMESPACE
                    ).map((notification: INotification) => (
                        <Notification key={notification.id} {...notification} />
                    ))}
                </div>
                <form
                    onSubmit={(event: React.FormEvent) => this.createVatRate(event)}
                >
                    <FormGroup>
                        <FormLabel>Naam</FormLabel>
                        <FormInput
                            name="name"
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                this.formValues.name = event.target.value;
                            }}
                            value={this.formValues.name}
                            hasError={this.formErrors.has('name')}
                            maxLength={50}
                        />
                        {this.formErrors.has('name') && (
                            <FormMessage type="invalid">
                                {this.formErrors.first('name')}
                            </FormMessage>
                        )}
                    </FormGroup>
                    <FormGroup>
                        <FormLabel>Tarief</FormLabel>
                        <FormInput
                            name="percentage"
                            subType="currency"
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                this.formValues.percentage = event.target.value;
                            }}
                            value={this.formValues.percentage}
                            hasError={this.formErrors.has('percentage')}
                            append="%"
                        />
                        {this.formErrors.has('percentage') && (
                            <FormMessage type="invalid">
                                {this.formErrors.first('percentage')}
                            </FormMessage>
                        )}
                    </FormGroup>
                    <Button
                        type="submit"
                        variant="primary"
                    >
                        Opslaan
                    </Button>
                </form>
            </>
        );
    }
}

export default EditVatRateModal;
