import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import { RouteComponentProps, withRouter } from 'react-router';
import moment from 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { UserStore } from '../../../app/stores/user/UserStore';
import { CompanyStore } from '../../../app/stores/company/CompanyStore';
import Page from '../../shared/Page';
import Container from '../../shared/Container';
import UsesCompany from '../../shared/company/UsesCompany';
import getPage from '../../shared/Paginate/helpers/getPage';
import generateGuid from '../../../app/utils/generateGuid';
import Card from '../../shared/Card';
import Notification from '../../shared/Notification';
import { history } from '../../../app/utils/history';
import Paginate from '../../shared/Paginate';
import { IPaginated } from '../../../app/utils/interface/IPaginated';
import { IListPayment } from '../../../app/stores/payments/interfaces/IListPayment';
import Button from '../../shared/Button';
import { PaymentStore } from '../../../app/stores/payments/PaymentStore';

export interface PaymentsListPageParams {
    page?: string;
}

export interface PaymentsListPageProps extends RouteComponentProps<PaymentsListPageParams> {
    userStore?: UserStore;
    companyStore?: CompanyStore;
    paymentStore?: PaymentStore;
}

@inject('userStore', 'companyStore', 'paymentStore')
@observer
class PaymentsListPage extends React.Component<PaymentsListPageProps, unknown> {
    @observable private paymentList?: IPaginated<IListPayment>;

    public componentDidMount(): void {
        const { userStore, match: { params } } = this.props;

        if (userStore?.currentCompany) {
            this.refreshPayments(userStore.currentCompany.company.uuid, getPage(params));
        }
    }

    private handlePageChange(selected: number): void {
        const { userStore } = this.props;

        if (userStore?.currentCompany) {
            history.push(`/payments/${selected + 1}`);
            this.refreshPayments(userStore.currentCompany.company.uuid, selected + 1);
        }
    }

    private refreshPayments(companyUuid: string, page: number): void {
        const { companyStore } = this.props;

        companyStore?.getPayments(
            companyUuid,
            page
        ).then((payments: IPaginated<IListPayment> | undefined) => {
            this.paymentList = payments;
        });
    }

    private renderPaymentsList(): React.ReactNode {
        const { companyStore, paymentStore } = this.props;

        return (
            <div className="mb-4">
                <Card>
                    {this.paymentList && !this.paymentList.data.length ? (
                        <Notification
                            message="Geen betalingen gevonden"
                            variant="info"
                            id={generateGuid()}
                        />
                    ) : (
                        <table className="table" id="payments-list">
                            <thead>
                                <tr>
                                    <th>Betalingsdatum</th>
                                    <th>Factuurdatum</th>
                                    <th>Factuurnummer</th>
                                    <th>Klant</th>
                                    <th>Bedrag</th>
                                    <th>Betalingsmethode</th>
                                    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                                    <th />
                                </tr>
                            </thead>
                            <tbody>
                                {companyStore?.isInitialOrLoading('get-payments') ? (
                                    <tr>
                                        <td colSpan={2}>Gegevens worden geladen...</td>
                                    </tr>
                                ) : this.paymentList?.data.map((payment: IListPayment) => (
                                    <tr key={payment.uuid}>
                                        <td>{moment(payment.paid_at).format('DD-MM-YYYY')}</td>
                                        <td>{moment(payment.invoice_date).format('DD-MM-YYYY')}</td>
                                        <td>
                                            <Button
                                                variant="secondary"
                                                onClick={() => {
                                                    history.push(`/invoices/${payment.invoice_uuid}/details`);
                                                }}
                                            >
                                                {payment.invoice_number}
                                            </Button>
                                        </td>
                                        <td>{payment.customer_name}</td>
                                        <td>{`€ ${payment.amount}`}</td>
                                        <td>{payment.payment_method.name}</td>
                                        <td>
                                            <Button
                                                className="edit-payment"
                                                variant="secondary"
                                                onClick={() => {
                                                    paymentStore!.activePayment = payment;
                                                }}
                                                icon={<FontAwesomeIcon icon={faPen} />}
                                            >
                                                Aanpassen
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </Card>
            </div>
        );
    }

    public render(): React.ReactNode {
        const { match: { params }, paymentStore, userStore } = this.props;

        return (
            <Page title="Betalingoverzicht">
                <Container center className="w-3/4">
                    <UsesCompany
                        onCompanySelected={() => {
                            this.handlePageChange(getPage(params) - 1);
                        }}
                    >
                        <div className="flex">
                            {!!this.paymentList?.data.length && (
                                <Paginate
                                    pageCount={this.paymentList.meta.last_page}
                                    className="ml-auto mb-4"
                                    onPageChange={({ selected }) => {
                                        this.handlePageChange(selected);
                                    }}
                                    forcePage={getPage(params) - 1}
                                />
                            )}
                        </div>
                        {this.renderPaymentsList()}
                        {paymentStore?.useEditPaymentModal(() => {
                            paymentStore!.activePayment = undefined;

                            if (userStore?.currentCompany) {
                                this.refreshPayments(userStore.currentCompany.company.uuid, getPage(params));
                            }
                        })}
                    </UsesCompany>
                </Container>
            </Page>
        );
    }
}

export default withRouter(PaymentsListPage);
