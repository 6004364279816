import * as React from 'react';
import {
    Dropdown as RsDropdown, DropdownMenu,
    DropdownToggle
} from 'reactstrap';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

export interface DropdownProps {
    text: string;
}

const Dropdown = React.forwardRef<RsDropdown, React.PropsWithChildren<DropdownProps>>((
    {
        children,
        text
    },
    ref
): React.ReactElement => {
    const [open, setOpen] = useState<boolean>(false);

    const toggle = (event: React.MouseEvent | React.KeyboardEvent) => {
        event.stopPropagation();

        setOpen(!open);
    };

    return (
        <RsDropdown
            ref={ref}
            isOpen={open}
            toggle={toggle}
        >
            <DropdownToggle>
                <FontAwesomeIcon
                    icon={faChevronDown}
                    className="mr-4"
                />
                {text}
            </DropdownToggle>
            <DropdownMenu
                onClick={(event: React.MouseEvent) => {
                    event.stopPropagation();
                }}
            >
                {children}
            </DropdownMenu>
        </RsDropdown>
    );
});

Dropdown.displayName = 'Dropdown';

export default Dropdown;
