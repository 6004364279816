import * as React from 'react';
import clsx from 'clsx';

const Divider = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>((
    {
        className
    },
    ref
): React.ReactElement => (
    <div
        ref={ref}
        className={clsx(
            'horizontal-divider',
            className
        )}
    />
));

Divider.displayName = 'Divider';

export default Divider;
