import React from 'react';
import { observable } from 'mobx';
import ReactModal from 'react-modal';
import { LoadingStore } from '../loading/LoadingStore';
import { api } from '../../services/api';
import { ResponseStatus } from '../../services/api/ResponseStatus';
import { DataInvalidError } from '../../errors/DataInvalidError';
import { IResponse } from '../../services/api/IResponse';
import { IPayment } from './interfaces/IPayment';
import { IErrorResponse } from '../../services/api/IErrorResponse';
import { application } from '../../application';
import { ApiError } from '../../errors/ApiError';
import { SomethingHappenedError } from '../../errors/SomethingHappenedError';
import { DetailedMessageError } from '../../errors/DetailedMessageError';
import { IListInvoice } from '../invoices/interfaces/IListInvoice';
import { INewPayment } from '../invoices/interfaces/payments/INewPayment';
import { notificationStore } from '../notifications/NotificationStore';
import AddPaymentModal from '../../../components/shared/payments/AddPaymentModal';
import { IListPayment } from './interfaces/IListPayment';
import EditPaymentModel from '../../../components/shared/payments/EditPaymentModal';
import Modal from '../../../components/shared/Modal';

export class PaymentStore extends LoadingStore {
    @observable private _activeInvoice?: IListInvoice;
    @observable private _activePayment?: IListPayment;

    public set activeInvoice(invoice: IListInvoice | undefined) {
        this._activeInvoice = invoice;
    }

    public set activePayment(payment: IListPayment | undefined) {
        this._activePayment = payment;
    }

    public async createPayment(
        invoiceUuid: string,
        newPayment: INewPayment
    ): Promise<IResponse<IPayment, { rest_amount: string }> | undefined> {
        this.setLoading('create-payment');

        try {
            const response = await api.post<IResponse<IPayment, { rest_amount: string }> | IErrorResponse>(
                `api/invoices/${invoiceUuid}/pay`,
                newPayment
            );

            if (response.status === ResponseStatus.CREATED) {
                return response.data as IResponse<IPayment, { rest_amount: string }>;
            }
        } catch (e) {
            switch (e.response?.status) {
                case ResponseStatus.INVALID:
                    throw new DataInvalidError(
                        (e.response.data as IErrorResponse).message,
                        (e.response.data as IErrorResponse).errors!
                    );
                case ResponseStatus.BAD_REQUEST:
                    throw new DetailedMessageError(
                        (e.response.data as IErrorResponse).message,
                        (e.response.data as IErrorResponse).details || ''
                    );
                default:
                    /* istanbul ignore next */
                    application.handleError(new ApiError(e));
                    /* istanbul ignore next */
                    return undefined;
            }
        } finally {
            this.setLoaded('create-payment');
        }

        /* istanbul ignore next */
        application.handleError(new SomethingHappenedError());

        /* istanbul ignore next */
        return undefined;
    }

    public async editPayment(
        paymentUuid: string,
        updatedPayment: INewPayment
    ): Promise<IResponse<IPayment, { rest_amount: string }> | undefined> {
        this.setLoading('edit-payment');

        try {
            const response = await api.put<IResponse<IPayment, { rest_amount: string }> | IErrorResponse>(
                `api/payments/${paymentUuid}`,
                updatedPayment
            );

            if (response.status === ResponseStatus.OK) {
                return response.data as IResponse<IPayment, { rest_amount: string }>;
            }
        } catch (e) {
            switch (e.response?.status) {
                case ResponseStatus.INVALID:
                    throw new DataInvalidError(
                        (e.response.data as IErrorResponse).message,
                        (e.response.data as IErrorResponse).errors!
                    );
                case ResponseStatus.BAD_REQUEST:
                    throw new DetailedMessageError(
                        (e.response.data as IErrorResponse).message,
                        (e.response.data as IErrorResponse).details || ''
                    );
                default:
                    /* istanbul ignore next */
                    application.handleError(new ApiError(e));
                    /* istanbul ignore next */
                    return undefined;
            }
        } finally {
            this.setLoaded('edit-payment');
        }

        /* istanbul ignore next */
        application.handleError(new SomethingHappenedError());

        /* istanbul ignore next */
        return undefined;
    }

    public useCreatePaymentModal(onSuccess: () => void): React.ReactNode {
        if (this._activeInvoice) {
            return (
                <ReactModal
                    isOpen
                    overlayClassName="modal-backdrop modal-container"
                    className="modal-content mx-auto"
                    onRequestClose={() => {
                        this._activeInvoice = undefined;
                        notificationStore.clear(AddPaymentModal.NOTIFICATION_NAMESPACE);
                    }}
                >
                    <AddPaymentModal
                        invoice={this._activeInvoice}
                        onClose={() => {
                            this._activeInvoice = undefined;
                            notificationStore.clear(AddPaymentModal.NOTIFICATION_NAMESPACE);
                        }}
                        onPaymentCreated={onSuccess}
                    />
                </ReactModal>
            );
        }

        return undefined;
    }

    public useEditPaymentModal(onSuccess: () => void): React.ReactNode {
        if (this._activePayment) {
            return (
                <Modal
                    isOpen
                    onRequestClose={() => {
                        notificationStore?.clear(EditPaymentModel.NOTIFICATION_NAMESPACE);
                        this._activePayment = undefined;
                    }}
                    title="Betaling aanpssen"
                    closable
                >
                    <EditPaymentModel
                        payment={this._activePayment}
                        onPaymentUpdated={onSuccess}
                    />
                </Modal>
            );
        }

        return undefined;
    }
}

const paymentStore = new PaymentStore();

export {
    paymentStore
};
