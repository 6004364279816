import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import moment from 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsAltV, faPlus } from '@fortawesome/free-solid-svg-icons';
import clsx from 'clsx';
import {
    WidthProvider, Responsive, Layouts, Layout
} from 'react-grid-layout';
import Card from '../Card';
import { ICustomer } from '../../../app/stores/customers/interfaces/ICustomer';
import { INewConceptInvoice } from '../../../app/stores/invoices/interfaces/INewConceptInvoice';
import { CompanyStore } from '../../../app/stores/company/CompanyStore';
import { UserStore } from '../../../app/stores/user/UserStore';
import InvoiceItem from './InvoiceItem';
import { IInvoiceItem } from '../../../app/stores/invoices/interfaces/IInvoiceItem';
import generateGuid from '../../../app/utils/generateGuid';
import Button from '../Button';
import { FormErrors } from '../../../app/errors/FormErrors';
import { InvoiceStore } from '../../../app/stores/invoices/InvoiceStore';
import { ICalculation } from '../../../app/stores/invoices/interfaces/ICalculation';
import Notification from '../Notification';
import { ICompanyDetails } from '../../../app/stores/company/interfaces/ICompanyDetails';
import debounce from '../../../app/utils/debounce';
import { IConceptInvoice } from '../../../app/stores/invoices/interfaces/IConceptInvoice';
import { VatRatesStore } from '../../../app/stores/vat-rates/VatRatesStore';
import { IVatRate } from '../../../app/stores/vat-rates/interfaces/IVatRate';
import { IConceptInvoiceItem } from '../../../app/stores/invoices/interfaces/IConceptInvoiceItem';
import FormInput from '../Forms/Input';
import { FormSwitcher } from '../Forms/FormSwitcher';
import { IInvoice } from '../../../app/stores/invoices/interfaces/IInvoice';
import FormGroup from '../Forms/Group';

export interface CreateOrEditInvoiceProps<T> {
    onInvoiceChanged: (invoice: T) => void;
    errors: FormErrors;
    invoice: T;
    companyStore?: CompanyStore;
    userStore?: UserStore;
    invoiceStore?: InvoiceStore;
    customer?: ICustomer;
    company?: ICompanyDetails;
    vatRatesStore?: VatRatesStore;
    asQuote?: boolean;
}

const ResponsiveGridLayout = WidthProvider(Responsive);

@inject('companyStore', 'userStore', 'invoiceStore', 'vatRatesStore')
@observer
class CreateOrEditInvoice<T extends IConceptInvoice | INewConceptInvoice | IInvoice = IConceptInvoice>
    extends React.Component<CreateOrEditInvoiceProps<T>, unknown> {
    @observable private totals: ICalculation = {
        sub_total: '0,00',
        total: '0,00'
    };
    private debouncedTotalCalculation: () => Promise<void>;
    @observable private vatRates: IVatRate[] = [];

    public constructor(props: CreateOrEditInvoiceProps<T>) {
        super(props);

        this.debouncedTotalCalculation = debounce<() => Promise<void>>(this.calculateTotals.bind(this), 500);
    }

    public componentDidMount(): void {
        const { invoice, company } = this.props;

        if (Object.prototype.hasOwnProperty.call(invoice, 'totals')) {
            this.totals = (invoice as IConceptInvoice).totals;
        }

        if (company) {
            this.refreshVatRates(company);
        }

        this.debouncedTotalCalculation();
    }

    public componentDidUpdate(prevProps: Readonly<CreateOrEditInvoiceProps<T>>): void {
        const { company } = this.props;

        if (!company && prevProps.company) {
            this.vatRates = [];
            return;
        }

        if (company && !prevProps.company) {
            this.refreshVatRates(company);
        }
    }

    private onItemOrderChanged(layouts: Layout[]): void {
        const { invoice, onInvoiceChanged } = this.props;

        layouts.forEach((layout: Layout) => {
            const itemIndex = invoice.items.findIndex((item: IConceptInvoiceItem): boolean => item.uuid === layout.i);

            if (itemIndex !== -1) {
                invoice.items[itemIndex] = {
                    ...invoice.items[itemIndex],
                    order: layout.y
                };
            }
        });

        onInvoiceChanged(invoice);
    }

    private changeItemsIncludingVat(including: boolean): void {
        const { invoice, onInvoiceChanged } = this.props;

        invoice.items_include_vat = including;
        this.debouncedTotalCalculation();
        onInvoiceChanged(invoice);
    }

    private changeInvoiceValue(invoiceDate: string, propertyName: string): void {
        const { invoice, onInvoiceChanged } = this.props;

        if (propertyName in invoice) {
            // @ts-ignore
            invoice[propertyName] = invoiceDate;
            onInvoiceChanged(invoice);
        }
    }

    private refreshVatRates(company: ICompanyDetails): void {
        const { vatRatesStore } = this.props;

        vatRatesStore?.getVatRates(company.company_uuid).then((vatRates: IVatRate[]) => {
            this.vatRates = vatRates;
        });
    }

    private changeItem(index: number, key: keyof IInvoiceItem, value: string): void {
        const { onInvoiceChanged, invoice } = this.props;

        invoice.items[index] = {
            ...invoice.items[index],
            [key]: value
        };
        onInvoiceChanged(invoice);
        this.debouncedTotalCalculation();
    }

    private addInvoiceItem(): void {
        const { onInvoiceChanged, invoice } = this.props;

        invoice.items.push({
            uuid: generateGuid(), // For mapping only, should not be used in the backend
            description: '',
            price: '',
            quantity: '',
            total: '',
            vat_rate: {
                uuid: generateGuid(),
                name: '',
                percentage: ''
            },
            vat_rate_uuid: '',
            order: invoice.items.length
        });

        onInvoiceChanged(invoice);
    }

    private removeInvoiceItem(index: number): void {
        const { invoice, onInvoiceChanged } = this.props;

        invoice.items.splice(index, 1);

        onInvoiceChanged(invoice);
        this.debouncedTotalCalculation();
    }

    private async calculateTotals(): Promise<void> {
        const { invoiceStore, invoice } = this.props;

        invoiceStore?.calculateTotals(
            invoice.items,
            invoice.items_include_vat
        ).then((totals: ICalculation) => {
            this.totals = totals;
        });
    }

    private makeLayout(items: IConceptInvoiceItem[]): Layouts {
        return {
            xss: items.map((item: IConceptInvoiceItem) => ({
                i: item.uuid,
                x: 1,
                y: item.order,
                w: 1,
                h: 1
            }))
        };
    }

    private renderCustomerSection(): React.ReactNode {
        const { customer } = this.props;

        return customer && (
            <>
                <div><strong>{`${customer.company_name}`}</strong></div>
                <div>{`${customer.firstname} ${customer.lastname}`}</div>
                <div>{customer.address}</div>
                <div>{`${customer.address_postcode} ${customer.address_city}`}</div>
            </>
        );
    }

    public render(): React.ReactNode {
        const {
            userStore,
            errors,
            invoice,
            customer,
            company,
            asQuote
        } = this.props;

        return (
            <Card className={clsx(
                'invoice-wrapper mx-auto',
                !customer && 'invoice-wrapper-disabled'
            )}
            >
                {company?.logo && (
                    <div className="mb-16">
                        <img
                            alt="logo"
                            src={company.logo}
                            id="company-logo"
                            className="company-logo"
                        />
                    </div>
                )}
                <div className="grid grid-cols-3 grid-gap-4 mb-8">
                    <div className="customer-details">
                        {this.renderCustomerSection()}
                    </div>
                    <div />
                    <div className="company-details">
                        <strong>{userStore?.currentCompany?.company.name}</strong>
                        {company && (
                            <>
                                <div>{company.address}</div>
                                <div className="mb-4">
                                    {/* eslint-disable-next-line max-len */}
                                    {`${company.address_postcode} ${company.address_city}`}
                                </div>
                                <div>{`Kvk: ${company.coc_number}`}</div>
                                <div>{`BTW: ${company.vat_number}`}</div>
                                <div>{`Bank: ${company.bank_number}`}</div>
                            </>
                        )}
                    </div>
                </div>
                <div className="grid grid-cols-3 grid-gap-4 mb-16">
                    <div>
                        <h1>Concept</h1>
                    </div>
                    <div />
                    <div>
                        {asQuote ? (
                            <div>
                                {`Offertedatum: ${moment()
                                    .format('DD-MM-YYYY')}`}
                            </div>
                        ) : (
                            <>
                                <div>
                                    <FormGroup>
                                        FactuurDatum
                                        <FormInput
                                            name="invoice_date"
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                                                this.changeInvoiceValue(event.target.value, 'invoice_date');
                                            }}
                                            type="date"
                                            value={'invoice_date' in invoice ? moment(invoice.invoice_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')}
                                            hasError={errors.has('invoice_date')}
                                        />
                                    </FormGroup>
                                </div>
                                <div>
                                    <FormGroup>
                                        Vervaldatum
                                        <FormInput
                                            name="due_date"
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                                                this.changeInvoiceValue(event.target.value, 'due_date');
                                            }}
                                            type="date"
                                            value={'due_date' in invoice ? moment(invoice.due_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')}
                                            hasError={errors.has('due_date')}
                                        />
                                    </FormGroup>
                                </div>
                            </>
                        )}
                    </div>
                </div>
                <section className="items-section">
                    {errors.has('items') && (
                        <Notification
                            className="mb-4"
                            id={generateGuid()}
                            variant="danger"
                            message={errors.first('items')!}
                        />
                    )}

                    {!!invoice.items.length && (
                        <>
                            <div className="mb-8 flex justify-end">
                                <FormSwitcher
                                    firstOptionText="Incl. BTW"
                                    secondOptionText="Excl. BTW"
                                    name="items_including_vat"
                                    value={invoice.items_include_vat}
                                    onSwitch={(including: boolean) => {
                                        this.changeItemsIncludingVat(including);
                                    }}
                                />
                            </div>
                            <div className="invoice-items-header">
                                <div className="invoice-items-header-item">
                                    <strong>Omschrijving</strong>
                                </div>
                                <div className="invoice-items-header-item">
                                    <strong>Aantal</strong>
                                </div>
                                <div className="invoice-items-header-item">
                                    <strong>Prijs</strong>
                                </div>
                                <div className="invoice-items-header-item">
                                    <strong>Totaal</strong>
                                </div>
                                <div className="invoice-items-header-item">
                                    <strong>BTW</strong>
                                </div>
                                <div className="invoice-items-header-item" />
                            </div>
                            <ResponsiveGridLayout
                                className="invoice-items"
                                layouts={this.makeLayout(invoice.items)}
                                cols={{
                                    xss: 1, xs: 1, s: 1, md: 1, lg: 1
                                }}
                                containerPadding={[0, 0]}
                                margin={[12, 12]}
                                draggableHandle=".drag-handle"
                                rowHeight={38}
                                maxRows={invoice.items.length}
                                isResizable={false}
                                onLayoutChange={(layouts: Layout[]) => {
                                    this.onItemOrderChanged(layouts);
                                }}
                            >
                                {invoice.items.map((item: IConceptInvoiceItem, index: number) => (
                                    <div
                                        key={item.uuid}
                                        className="invoice-item"
                                    >
                                        <div className="drag-handle">
                                            <FontAwesomeIcon icon={faArrowsAltV} />
                                        </div>
                                        <InvoiceItem
                                            errors={errors.filterPrefix(`items.${index}.`)}
                                            onItemRemoved={(): void => this.removeInvoiceItem(index)}
                                            onDescriptionChange={(value: string) => {
                                                this.changeItem(index, 'description', value);
                                            }}
                                            description={item.description}
                                            onPriceChange={(value: string) => {
                                                this.changeItem(index, 'price', value);
                                            }}
                                            price={String(item.price)}
                                            onQuantityChange={(value: string) => {
                                                this.changeItem(index, 'quantity', value.replace(',', '.'));
                                            }}
                                            quantity={String(item.quantity.toString().replace('.', ','))}
                                            vatRates={this.vatRates}
                                            onVatRateChanged={(value: string): void => {
                                                this.changeItem(index, 'vat_rate_uuid', value);
                                            }}
                                            vatRateUuid={item.vat_rate_uuid ? String(item.vat_rate_uuid) : ''}
                                        />
                                    </div>
                                ))}
                            </ResponsiveGridLayout>
                            <div className="totals-container">
                                <div className="totals-container-part">
                                    <strong>Subtotaal</strong>
                                </div>
                                <div className="totals-container-part">
                                    <FormInput
                                        disabled
                                        value={this.totals.sub_total}
                                        name="sub_total"
                                    />
                                </div>
                            </div>
                            <div className="totals-container">
                                <div className="totals-container-part">
                                    <strong>Totaal</strong>
                                </div>
                                <div className="totals-container-part">
                                    <FormInput
                                        disabled
                                        value={this.totals.total}
                                        name="total"
                                    />
                                </div>
                            </div>
                        </>
                    )}
                    <div className="mt-4">
                        <Button
                            id="add-invoice-items"
                            disabled={!customer}
                            variant="primary"
                            icon={<FontAwesomeIcon icon={faPlus} />}
                            onClick={() => this.addInvoiceItem()}
                        >
                            Regel toevoegen
                        </Button>
                    </div>
                </section>
            </Card>
        );
    }
}

export default CreateOrEditInvoice;
