import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import { RouteComponentProps, withRouter } from 'react-router';
import moment from 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { DropdownItem } from 'reactstrap';
import { UserStore } from '../../../app/stores/user/UserStore';
import { CompanyStore } from '../../../app/stores/company/CompanyStore';
import { PaymentStore } from '../../../app/stores/payments/PaymentStore';
import Page from '../../shared/Page';
import Container from '../../shared/Container';
import UsesCompany from '../../shared/company/UsesCompany';
import getPage from '../../shared/Paginate/helpers/getPage';
import generateGuid from '../../../app/utils/generateGuid';
import Card from '../../shared/Card';
import Notification from '../../shared/Notification';
import { history } from '../../../app/utils/history';
import Paginate from '../../shared/Paginate';
import Status from '../../shared/Status';
import { IListAllQuotes } from '../../../app/stores/quotes/interfaces/IListAllQuotes';
import { IListQuote } from '../../../app/stores/quotes/interfaces/IListQuote';
import { IListConceptQuote } from '../../../app/stores/quotes/interfaces/IListConceptQuote';
import { PermissionStore } from '../../../app/stores/permissions/PermissionStore';
import { QuoteStore } from '../../../app/stores/quotes/QuoteStore';
import Dropdown from '../../shared/Dropdown';

export interface QuoteListPageParams {
    page?: string;
}

export interface QuoteListPageProps extends RouteComponentProps<QuoteListPageParams> {
    userStore?: UserStore;
    companyStore?: CompanyStore;
    paymentStore?: PaymentStore;
    permissionStore?: PermissionStore;
    quoteStore?: QuoteStore;
}

@inject('userStore', 'companyStore', 'paymentStore', 'quoteStore', 'permissionStore')
@observer
class QuoteListPage extends React.Component<QuoteListPageProps, unknown> {
    @observable private quoteList?: IListAllQuotes;

    public componentDidMount() {
        const { userStore, match: { params } } = this.props;

        if (userStore?.currentCompany) {
            this.refreshQuote(userStore.currentCompany.company.uuid, getPage(params));
        }
    }

    private handlePageChange(selected: number): void {
        const { userStore } = this.props;

        if (userStore?.currentCompany) {
            history.push(`/quotes/${selected + 1}`);
            this.refreshQuote(userStore.currentCompany.company.uuid, selected + 1);
        }
    }

    private refreshQuote(companyUuid: string, page: number): void {
        const { companyStore } = this.props;

        companyStore?.getAllQuotes(companyUuid, page)
            .then((quotes: IListAllQuotes | undefined): void => {
                if (quotes) {
                    this.quoteList = quotes;
                }
            });
    }

    private downloadQuote(quote: IListQuote): void {
        const { quoteStore } = this.props;

        quoteStore?.downloadQuote(quote.uuid, quote.quote_number);
    }

    private renderConceptQuotes(): React.ReactNode {
        const { companyStore } = this.props;

        return (
            <div className="mb-4">
                <h2>Conceptoffertes</h2>
                <Card>
                    {this.quoteList && !this.quoteList.concept_quotes.length ? (
                        <Notification
                            message="Geen conceptoffertes gevonden"
                            variant="info"
                            id={generateGuid()}
                        />
                    ) : (
                        <table className="table table-clickable" id="concept-quotes-list">
                            <thead>
                                <tr>
                                    <th>Klant</th>
                                    <th>Bedrag</th>
                                </tr>
                            </thead>
                            <tbody>
                                {companyStore?.isInitialOrLoading('get-all-quotes') ? (
                                    <tr>
                                        <td colSpan={2}>Data wordt geladen...</td>
                                    </tr>
                                ) : this.quoteList?.concept_quotes.map((quote: IListConceptQuote) => (
                                    <tr
                                        key={quote.uuid}
                                        onClick={() => {
                                            history.push(`/quotes/${quote.uuid}/edit`);
                                        }}
                                    >
                                        <td>
                                            {quote.customer_firstname && quote.customer_lastname
                                                ? `${quote.customer_firstname} ${quote.customer_lastname}` : ''}
                                        </td>
                                        <td>{`€ ${quote.total}`}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </Card>
            </div>
        );
    }

    private renderQuotes(): React.ReactElement {
        const { companyStore, permissionStore } = this.props;

        return (
            <div className="mb-4">
                <div className="flex items-center">
                    <h2>Offertes</h2>
                </div>
                <Card>
                    {this.quoteList && !this.quoteList.quotes.data.length ? (
                        <Notification
                            message="Geen offertes gevonden"
                            variant="info"
                            id={generateGuid()}
                        />
                    ) : (
                        <table className="table table-clickable" id="quotes-list">
                            <thead>
                                <tr>
                                    <th>Offertenummer</th>
                                    <th>Klant</th>
                                    <th>Offertedatum</th>
                                    <th>Bedrag</th>
                                    <th>Status</th>
                                    {permissionStore?.canOneOf(['download_quote']) && (
                                        // eslint-disable-next-line jsx-a11y/control-has-associated-label
                                        <th />
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                {companyStore?.isInitialOrLoading('get-all-quotes') ? (
                                    <tr>
                                        <td colSpan={2}>Data wordt geladen...</td>
                                    </tr>
                                ) : this.quoteList?.quotes.data.map((quote: IListQuote) => (
                                    <tr
                                        key={quote.uuid}
                                        onClick={() => {
                                            history.push(`/quotes/${quote.uuid}/details`);
                                        }}
                                    >
                                        <td>{quote.quote_number}</td>
                                        <td>
                                            {`${quote.customer_firstname} ${quote.customer_lastname}`}
                                        </td>
                                        <td>
                                            {moment(quote.created_at)
                                                .format('DD-MM-YYYY')}
                                        </td>
                                        <td>{`€ ${quote.total}`}</td>
                                        <td>
                                            <Status
                                                variant={quote.status.name}
                                            >
                                                {quote.status.locale}
                                            </Status>
                                        </td>
                                        {permissionStore?.can('download_quote') && (
                                            <td>
                                                <Dropdown text="Opties">
                                                    <DropdownItem
                                                        className="download-pdf"
                                                        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                                            event.stopPropagation();
                                                            this.downloadQuote(quote);
                                                        }}
                                                        icon={<FontAwesomeIcon icon={faDownload} />}
                                                    >
                                                        PDF downloaden
                                                    </DropdownItem>
                                                </Dropdown>
                                            </td>
                                        )}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </Card>
            </div>
        );
    }

    public render(): React.ReactNode {
        const {
            paymentStore,
            match: { params },
            userStore
        } = this.props;

        return (
            <Page
                title="Offerteoverzicht"
            >
                <Container center className="w-3/4">
                    <UsesCompany
                        onCompanySelected={(): void => {
                            this.handlePageChange(getPage(params) - 1);
                        }}
                    >
                        <div className="flex">
                            {!!this.quoteList?.quotes.data.length && (
                                <Paginate
                                    pageCount={this.quoteList.quotes.meta.last_page}
                                    className="ml-auto mb-4"
                                    onPageChange={({ selected }) => {
                                        this.handlePageChange(selected);
                                    }}
                                    forcePage={getPage(params) - 1}
                                />
                            )}
                        </div>
                        {this.renderConceptQuotes()}
                        {this.renderQuotes()}
                    </UsesCompany>
                </Container>
                {paymentStore?.useCreatePaymentModal(() => {
                    if (userStore?.currentCompany) {
                        this.refreshQuote(
                            userStore.currentCompany.company.uuid,
                            getPage(params)
                        );
                    }
                })}
            </Page>
        );
    }
}

export default withRouter(QuoteListPage);
