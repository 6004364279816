import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import { RouteComponentProps, withRouter } from 'react-router';
import moment from 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan } from '@fortawesome/free-solid-svg-icons';
import { UserStore } from '../../../app/stores/user/UserStore';
import { CompanyStore } from '../../../app/stores/company/CompanyStore';
import { PaymentStore } from '../../../app/stores/payments/PaymentStore';
import Page from '../../shared/Page';
import Container from '../../shared/Container';
import UsesCompany from '../../shared/company/UsesCompany';
import getPage from '../../shared/Paginate/helpers/getPage';
import generateGuid from '../../../app/utils/generateGuid';
import Card from '../../shared/Card';
import Notification from '../../shared/Notification';
import { history } from '../../../app/utils/history';
import Paginate from '../../shared/Paginate';
import Button from '../../shared/Button';
import { PermissionStore } from '../../../app/stores/permissions/PermissionStore';
import { RecurringInvoiceStore } from '../../../app/stores/recurring-invoices/RecurringInvoiceStore';
import { IPaginated } from '../../../app/utils/interface/IPaginated';
import { IRecurringInvoice } from '../../../app/stores/recurring-invoices/interfaces/IRecurringInvoice';
import { NotificationStore } from '../../../app/stores/notifications/NotificationStore';

export interface RecurringInvoiceListPageParams {
    page?: string;
}

export interface RecurringInvoiceListPageProps extends RouteComponentProps<RecurringInvoiceListPageParams> {
    userStore?: UserStore;
    companyStore?: CompanyStore;
    paymentStore?: PaymentStore;
    permissionStore?: PermissionStore;
    recurringInvoiceStore?: RecurringInvoiceStore;
    notificationStore?: NotificationStore;
}

@inject('userStore', 'companyStore', 'paymentStore', 'permissionStore', 'recurringInvoiceStore',
    'notificationStore')
@observer
class RecurringInvoiceListPage extends React.Component<RecurringInvoiceListPageProps, unknown> {
    @observable private recurringInvoiceList?: IPaginated<IRecurringInvoice>;

    public componentDidMount() {
        const { userStore, match: { params } } = this.props;

        if (userStore?.currentCompany) {
            this.refreshRecurringInvoices(userStore.currentCompany.company.uuid, getPage(params));
        }
    }

    private handlePageChange(selected: number): void {
        const { userStore } = this.props;

        if (userStore?.currentCompany) {
            history.push(`/recurringinvoices/${selected + 1}`);
            this.refreshRecurringInvoices(userStore.currentCompany.company.uuid, selected + 1);
        }
    }

    private refreshRecurringInvoices(companyUuid: string, page: number): void {
        const { companyStore } = this.props;

        companyStore?.getRecurringInvoices(companyUuid, page)
            .then((invoices: IPaginated<IRecurringInvoice> | undefined): void => {
                if (invoices) {
                    this.recurringInvoiceList = invoices;
                }
            });
    }

    private stopRecurringInvoice(invoice: IRecurringInvoice): void {
        const {
            recurringInvoiceStore,
            notificationStore,
            userStore,
            match: { params }
        } = this.props;

        notificationStore?.clear();

        recurringInvoiceStore?.stopRecurringInvoice(invoice).then((success: boolean) => {
            if (success) {
                notificationStore?.addNotification({
                    variant: 'success',
                    message: 'Periodieke factuur succesvol gestopt'
                });

                if (userStore?.currentCompany) {
                    this.refreshRecurringInvoices(
                        userStore.currentCompany.company.uuid,
                        getPage(params)
                    );
                }
            }
        });
    }

    public render(): React.ReactNode {
        const {
            match: { params },
            companyStore,
            permissionStore,
            recurringInvoiceStore
        } = this.props;

        return (
            <Page
                title="Factuuroverzicht"
            >
                <Container center className="w-3/4">
                    <UsesCompany
                        onCompanySelected={(): void => {
                            this.handlePageChange(getPage(params) - 1);
                        }}
                    >
                        <div className="flex">
                            {!!this.recurringInvoiceList?.data.length && (
                                <Paginate
                                    pageCount={this.recurringInvoiceList.meta.last_page}
                                    className="ml-auto mb-4"
                                    onPageChange={({ selected }) => {
                                        this.handlePageChange(selected);
                                    }}
                                    forcePage={getPage(params) - 1}
                                />
                            )}
                        </div>
                        <Card>
                            {this.recurringInvoiceList && !this.recurringInvoiceList.data.length ? (
                                <Notification
                                    message="Geen periodieke facturen gevonden"
                                    variant="info"
                                    id={generateGuid()}
                                />
                            ) : (
                                <table className="table" id="recurring-invoices-list">
                                    <thead>
                                        <tr>
                                            <th>Factuurnummer</th>
                                            <th>Klant</th>
                                            <th>Startdatum</th>
                                            <th>Einddatum</th>
                                            <th>Elke</th>
                                            <th>Volgende datum</th>
                                            {permissionStore?.can('create_periodic_invoice') && (
                                                // eslint-disable-next-line jsx-a11y/control-has-associated-label
                                                <th />
                                            )}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {companyStore?.isInitialOrLoading('get-recurring-invoices') ? (
                                            <tr>
                                                <td colSpan={2}>Gegevens worden opgehaald...</td>
                                            </tr>
                                        ) : this.recurringInvoiceList?.data.map((invoice: IRecurringInvoice) => (
                                            <tr
                                                key={invoice.uuid}
                                            >
                                                <td>
                                                    <Button
                                                        variant="light"
                                                        onClick={() => {
                                                            history.push(`/invoices/${invoice.invoice_uuid}/details`);
                                                        }}
                                                    >
                                                        {invoice.invoice_number}
                                                    </Button>
                                                </td>
                                                <td>
                                                    {invoice.customer_name}
                                                </td>
                                                <td>
                                                    {moment(invoice.start_date)
                                                        .format('DD-MM-YYYY')}
                                                </td>
                                                <td>
                                                    {invoice.end_date ? moment(invoice.end_date)
                                                        .format('DD-MM-YYYY') : undefined}
                                                </td>
                                                <td>{invoice.interval.name}</td>
                                                <td>
                                                    {moment(invoice.next_date).format('DD-MM-YYYY') }
                                                </td>
                                                {permissionStore?.can('create_periodic_invoice') && (
                                                    <td>
                                                        <Button
                                                            variant="danger"
                                                            className="stop-recurring-invoice"
                                                            icon={<FontAwesomeIcon icon={faBan} />}
                                                            onClick={() => {
                                                                this.stopRecurringInvoice(invoice);
                                                            }}
                                                            loading={recurringInvoiceStore?.isLoading(`stop-recurring-invoice-${invoice.uuid}`)}
                                                        >
                                                            Stoppen
                                                        </Button>
                                                    </td>
                                                )}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            )}
                        </Card>
                    </UsesCompany>
                </Container>
            </Page>
        );
    }
}

export default withRouter(RecurringInvoiceListPage);
