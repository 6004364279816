import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import Page from '../../shared/Page';
import Card from '../../shared/Card';
import Container from '../../shared/Container';
import FormGroup from '../../shared/Forms/Group';
import FormLabel from '../../shared/Forms/Label';
import { FormErrors } from '../../../app/errors/FormErrors';
import FormInput from '../../shared/Forms/Input';
import FormRow from '../../shared/Forms/Row';
import FormSelect from '../../shared/Forms/Select';
import { CountryStore } from '../../../app/stores/country/CountryStore';
import { ICountry } from '../../../app/stores/country/interfaces/ICountry';
import Button from '../../shared/Button';
import { NotificationStore } from '../../../app/stores/notifications/NotificationStore';
import { DataInvalidError } from '../../../app/errors/DataInvalidError';
import FormMessage from '../../shared/Forms/Message';
import { INewCustomer } from '../../../app/stores/customers/interfaces/INewCustomer';
import UsesCompany from '../../shared/company/UsesCompany';
import { CustomerStore } from '../../../app/stores/customers/CustomerStore';
import { ICustomer } from '../../../app/stores/customers/interfaces/ICustomer';
import { UserStore } from '../../../app/stores/user/UserStore';
import { GenderStore } from '../../../app/stores/genders/GenderStore';
import { IGender } from '../../../app/stores/genders/interfaces/IGender';
import { IUserCompany } from '../../../app/stores/user/interfaces/IUserCompany';
import { FormSwitcher } from '../../shared/Forms/FormSwitcher';
import KvkSearcher from '../../shared/search/KvkSearcher';
import { IKvkEntry } from '../../../app/stores/kvk/interfaces/IKvkEntry';

export interface CreateCustomerPageProps {
    countryStore?: CountryStore;
    customerStore?: CustomerStore;
    notificationStore?: NotificationStore;
    userStore?: UserStore;
    genderStore?: GenderStore;
}

@inject('customerStore', 'countryStore', 'notificationStore', 'userStore', 'genderStore')
@observer
class CreateCustomerPage extends React.Component<CreateCustomerPageProps, unknown> {
    @observable private customerIsPerson = true;

    private defaultFormValues: INewCustomer = {
        address_number: '',
        address_number_addition: '',
        address_postcode: '',
        address_street: '',
        address_city: '',
        company_name: '',
        coc_number: '',
        company_uuid: '',
        country_uuid: '',
        email: '',
        firstname: '',
        lastname: '',
        gender_uuid: '',
        phone_number: '',
        vat_number: ''

    }

    @observable private formErrors: FormErrors;
    @observable private formValues: INewCustomer;
    @observable private companyCustomer = false;

    public constructor(props: CreateCustomerPageProps) {
        super(props);

        this.formValues = this.defaultFormValues;
        this.formErrors = new FormErrors();
    }

    public componentDidMount() {
        const { countryStore, genderStore, userStore } = this.props;

        genderStore?.getGenders();

        countryStore?.getCountries().then(() => {
            const defaultCountry = countryStore
                ?.countries
                .find((country: ICountry) => country.name === 'Nederland');

            /* istanbul ignore next */
            if (defaultCountry) {
                this.formValues.country_uuid = defaultCountry.uuid;
                this.defaultFormValues.country_uuid = defaultCountry.uuid;
            }
        });

        if (userStore?.currentCompany) {
            this.formValues.company_uuid = userStore.currentCompany.company.uuid;
            this.defaultFormValues.company_uuid = userStore.currentCompany.company.uuid;
        }
    }

    private onCustomerSwitch(isPerson: boolean): void {
        this.customerIsPerson = isPerson;

        if (isPerson) {
            this.formValues.vat_number = '';
            this.formValues.company_name = '';
            this.formValues.coc_number = '';
        }
    }

    private onCompanySearched(company: IKvkEntry) {
        this.formValues.company_name = company.name;
        this.formValues.coc_number = company.coc_number;
        this.formValues.address_street = company.address_street || this.formValues.address_street;
        this.formValues.address_number = company.address_number || this.formValues.address_number;
        this.formValues.address_number_addition = company.address_number_addition;
        this.formValues.address_postcode = company.address_postcode || this.formValues.address_postcode;
        this.formValues.address_city = company.address_city || this.formValues.address_city;
    }

    private async submitCompany(event: React.FormEvent): Promise<void> {
        event.preventDefault();

        const { notificationStore, customerStore } = this.props;

        this.formErrors.clear();
        notificationStore?.clear();

        customerStore?.createCustomer(this.formValues).then((customer: ICustomer | undefined): void => {
            /* istanbul ignore else */
            if (customer) {
                this.formValues = this.defaultFormValues;
                notificationStore?.addNotification({
                    variant: 'success',
                    message: `Klant succesvol gemaakt`,
                    closable: true
                });
            }
        }).catch((e: DataInvalidError) => {
            notificationStore?.addNotification({
                variant: 'danger',
                message: e.message,
                closable: true
            });
            this.formErrors.set(e.errors);
        });
    }

    public render(): React.ReactNode {
        const {
            countryStore,
            genderStore,
            customerStore
        } = this.props;

        return (
            <Page title="Klant toevoegen">
                <UsesCompany
                    onCompanySelected={(userCompany: IUserCompany) => {
                        this.formValues.company_uuid = userCompany.company.uuid;
                        this.defaultFormValues.company_uuid = userCompany.company.uuid;
                    }}
                >
                    <Container center className="w-3/4">
                        <Card>
                            <form onSubmit={(event: React.FormEvent) => this.submitCompany(event)}>
                                <div className="form-section grid grid-cols-1 pb-4">
                                    <FormGroup>
                                        <FormLabel>Deze klant is een</FormLabel>
                                    </FormGroup>
                                    <FormSwitcher
                                        name="person_or_customer"
                                        value={this.customerIsPerson}
                                        firstOptionText="Persoon"
                                        secondOptionText="Bedrijf"
                                        onSwitch={(person: boolean) => {
                                            this.onCustomerSwitch(person);
                                        }}
                                    />
                                </div>
                                {!this.customerIsPerson && (
                                    <>
                                        <div className="form-section">
                                            <KvkSearcher
                                                onCompanySelected={((company: IKvkEntry) => {
                                                    this.onCompanySearched(company);
                                                })}
                                            />
                                        </div>
                                        <div className="form-section grid grid-cols-2">
                                            <div>
                                                <h2 className="mb-4">Bedrijfsgegevens</h2>
                                                <FormGroup>
                                                    <FormLabel>Bedrijfsnaam</FormLabel>
                                                    <FormInput
                                                        name="company_name"
                                                        maxLength={20}
                                                        value={this.formValues.company_name}
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                            this.formValues.company_name = event.target.value;
                                                        }}
                                                        hasError={this.formErrors.has('company_name')}
                                                    />
                                                    {this.formErrors.has('company_name') && (
                                                        <FormMessage type="invalid">
                                                            {this.formErrors.first('company_name')}
                                                        </FormMessage>
                                                    )}
                                                </FormGroup>
                                                <FormGroup>
                                                    <FormLabel>BTW-identificatienummer</FormLabel>
                                                    <FormInput
                                                        name="vat_number"
                                                        maxLength={20}
                                                        value={this.formValues.vat_number}
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                            this.formValues.vat_number = event.target.value;
                                                        }}
                                                        hasError={this.formErrors.has('vat_number')}
                                                    />
                                                    {this.formErrors.has('vat_number') && (
                                                        <FormMessage type="invalid">
                                                            {this.formErrors.first('vat_number')}
                                                        </FormMessage>
                                                    )}
                                                </FormGroup>
                                                <FormGroup>
                                                    <FormLabel>KvK-nummer</FormLabel>
                                                    <FormInput
                                                        name="coc_number"
                                                        maxLength={8}
                                                        value={this.formValues.coc_number}
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                            this.formValues.coc_number = event.target.value;
                                                        }}
                                                        hasError={this.formErrors.has('coc_number')}
                                                    />
                                                    {this.formErrors.has('coc_number') && (
                                                        <FormMessage type="invalid">
                                                            {this.formErrors.first('coc_number')}
                                                        </FormMessage>
                                                    )}
                                                </FormGroup>
                                            </div>
                                        </div>
                                    </>
                                )}
                                <div className="form-section grid grid-cols-2">
                                    <div>
                                        <h2 className="mb-4">Klantgegevens</h2>
                                        <FormRow>
                                            <FormGroup>
                                                <FormLabel>Voornaam</FormLabel>
                                                <FormInput
                                                    name="firstname"
                                                    maxLength={50}
                                                    value={this.formValues.firstname}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                        this.formValues.firstname = event.target.value;
                                                    }}
                                                    hasError={this.formErrors.has('firstname')}
                                                />
                                                {this.formErrors.has('firstname') && (
                                                    <FormMessage type="invalid">
                                                        {this.formErrors.first('firstname')}
                                                    </FormMessage>
                                                )}
                                            </FormGroup>
                                            <FormGroup>
                                                <FormLabel>Achternaam</FormLabel>
                                                <FormInput
                                                    name="lastname"
                                                    maxLength={50}
                                                    value={this.formValues.lastname}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                        this.formValues.lastname = event.target.value;
                                                    }}
                                                    hasError={this.formErrors.has('lastname')}
                                                />
                                                {this.formErrors.has('lastname') && (
                                                    <FormMessage type="invalid">
                                                        {this.formErrors.first('lastname')}
                                                    </FormMessage>
                                                )}
                                            </FormGroup>
                                        </FormRow>
                                        <FormGroup>
                                            <FormLabel>Geslacht</FormLabel>
                                            <FormSelect
                                                loading={genderStore?.isInitialOrLoading('get-genders')}
                                                name="gender_uuid"
                                                value={this.formValues.gender_uuid}
                                                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                                                    this.formValues.gender_uuid = event.target.value;
                                                }}
                                                hasError={this.formErrors.has('gender_uuid')}
                                            >
                                                <option value="">Kiezen...</option>
                                                {genderStore?.genders.map((gender: IGender) => (
                                                    <option value={gender.uuid} key={gender.uuid}>
                                                        {gender.name}
                                                    </option>
                                                ))}
                                            </FormSelect>
                                            {this.formErrors.has('gender_uuid') && (
                                                <FormMessage type="invalid">
                                                    {this.formErrors.first('gender_uuid')}
                                                </FormMessage>
                                            )}
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="form-section grid grid-cols-2">
                                    <div>
                                        <h2 className="mb-4">Contactgegevens</h2>

                                        <FormGroup>
                                            <FormLabel>E-mail</FormLabel>
                                            <FormInput
                                                name="email"
                                                value={this.formValues.email}
                                                maxLength={255}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                    this.formValues.email = event.target.value;
                                                }}
                                                hasError={this.formErrors.has('email')}
                                            />
                                            {this.formErrors.has('email') && (
                                                <FormMessage type="invalid">
                                                    {this.formErrors.first('email')}
                                                </FormMessage>
                                            )}
                                        </FormGroup>
                                        <FormGroup>
                                            <FormLabel>Telefoonnummer</FormLabel>
                                            <FormInput
                                                name="phone_number"
                                                maxLength={20}
                                                value={this.formValues.phone_number}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                    this.formValues.phone_number = event.target.value;
                                                }}
                                                hasError={this.formErrors.has('phone_number')}
                                            />
                                            {this.formErrors.has('phone_number') && (
                                                <FormMessage type="invalid">
                                                    {this.formErrors.first('phone_number')}
                                                </FormMessage>
                                            )}
                                        </FormGroup>
                                    </div>
                                    <div />
                                </div>
                                <div className="form-section grid grid-cols-2">
                                    <div>
                                        <h2 className="mb-4">Adresgegevens</h2>

                                        <FormRow>
                                            <FormGroup className="max-w-1/2">
                                                <FormLabel>Straat</FormLabel>
                                                <FormInput
                                                    name="address_street"
                                                    maxLength={50}
                                                    value={this.formValues.address_street}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                        this.formValues.address_street = event.target.value;
                                                    }}
                                                    hasError={this.formErrors.has('address_street')}
                                                />
                                                {this.formErrors.has('address_street') && (
                                                    <FormMessage type="invalid">
                                                        {this.formErrors.first('address_street')}
                                                    </FormMessage>
                                                )}
                                            </FormGroup>
                                            <FormGroup className="max-w-1/4">
                                                <FormLabel>Nummer</FormLabel>
                                                <FormInput
                                                    name="address_number"
                                                    maxLength={10}
                                                    value={this.formValues.address_number}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                        this.formValues.address_number = event.target.value;
                                                    }}
                                                    hasError={this.formErrors.has('address_number')}
                                                />
                                                {this.formErrors.has('address_number') && (
                                                    <FormMessage type="invalid">
                                                        {this.formErrors.first('address_number')}
                                                    </FormMessage>
                                                )}
                                            </FormGroup>
                                            <FormGroup className="max-w-1/4">
                                                <FormLabel>Toev.</FormLabel>
                                                <FormInput
                                                    maxLength={10}
                                                    name="address_number_addition"
                                                    value={this.formValues.address_number_addition || ''}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                        this.formValues.address_number_addition = event.target.value;
                                                    }}
                                                    hasError={this.formErrors.has('address_number_addition')}
                                                />
                                                {this.formErrors.has('address_number_addition') && (
                                                    <FormMessage type="invalid">
                                                        {this.formErrors.first('address_number_addition')}
                                                    </FormMessage>
                                                )}
                                            </FormGroup>
                                        </FormRow>
                                        <FormRow>
                                            <FormGroup className="max-w-1/4">
                                                <FormLabel>Postcode</FormLabel>
                                                <FormInput
                                                    maxLength={7}
                                                    name="address_postcode"
                                                    value={this.formValues.address_postcode}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                        this.formValues.address_postcode = event.target.value;
                                                    }}
                                                    hasError={this.formErrors.has('address_postcode')}
                                                />
                                                {this.formErrors.has('address_postcode') && (
                                                    <FormMessage type="invalid">
                                                        {this.formErrors.first('address_postcode')}
                                                    </FormMessage>
                                                )}
                                            </FormGroup>
                                            <FormGroup>
                                                <FormLabel>Stad</FormLabel>
                                                <FormInput
                                                    name="address_city"
                                                    maxLength={50}
                                                    value={this.formValues.address_city}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                        this.formValues.address_city = event.target.value;
                                                    }}
                                                    hasError={this.formErrors.has('address_city')}
                                                />
                                                {this.formErrors.has('address_city') && (
                                                    <FormMessage type="invalid">
                                                        {this.formErrors.first('address_city')}
                                                    </FormMessage>
                                                )}
                                            </FormGroup>
                                        </FormRow>
                                        <FormGroup>
                                            <FormLabel>Land</FormLabel>
                                            <FormSelect
                                                loading={countryStore?.isInitialOrLoading('get-countries')}
                                                name="country_uuid"
                                                value={this.formValues.country_uuid}
                                                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                                                    this.formValues.country_uuid = event.target.value;
                                                }}
                                                hasError={this.formErrors.has('country_uuid')}
                                            >
                                                <option value="">Kiezen...</option>
                                                {countryStore?.countries.map((country: ICountry) => (
                                                    <option value={country.uuid} key={country.uuid}>
                                                        {country.name}
                                                    </option>
                                                ))}
                                            </FormSelect>
                                            {this.formErrors.has('country_uuid') && (
                                                <FormMessage type="invalid">
                                                    {this.formErrors.first('country_uuid')}
                                                </FormMessage>
                                            )}
                                        </FormGroup>
                                    </div>
                                </div>
                                <Button
                                    type="submit"
                                    variant="primary"
                                    loading={customerStore?.isLoading('create-customer')}
                                >
                                    Klant maken
                                </Button>
                            </form>
                        </Card>
                    </Container>
                </UsesCompany>
            </Page>
        );
    }
}

export default CreateCustomerPage;
