import React from 'react';
import { RouteConfig } from 'react-router-config';
import DashboardPage from '../../components/pages/dashboard';
import LoginPage from '../../components/pages/auth/LoginPage';
import GuestRoute from '../../components/shared/auth/GuestRoute';
import PrivateRoute from '../../components/shared/auth/PrivateRoute';
import CreateCompanyPage from '../../components/pages/company/CreateCompanyPage';
import CreateCustomerPage from '../../components/pages/customer/CreateCustomerPage';
import CreateInvoicePage from '../../components/pages/invoices/CreateInvoicePage';
import EditInvoicePage from '../../components/pages/invoices/EditInvoicePage';
import InvoiceListPage from '../../components/pages/invoices/InvoiceListPage';
import InvoiceDetailsPage from '../../components/pages/invoices/InvoiceDetailsPage';
import EditCompanyPage from '../../components/pages/company/EditCompanyPage';
import VatRatesListPage from '../../components/pages/vat-rates/VatRatesListPage';
import PaymentMethodsListPage from '../../components/pages/payment-methods/PaymentMethodsListPage';
import CustomerListPage from '../../components/pages/customers/CustomerListPage';
import EditQuotePage from '../../components/pages/quotes/EditQuotePage';
import QuoteDetailsPage from '../../components/pages/quotes/QuoteDetailsPage';
import QuoteListPage from '../../components/pages/quotes/QuoteListPage';
import CreateQuotePage from '../../components/pages/quotes/CreateQuotePage';
import RecurringInvoiceListPage from '../../components/pages/invoices/RecurringInvoiceListPage';
import PaymentsListPage from '../../components/pages/payments/PaymentsListPage';
import CreateUserPage from '../../components/pages/users/CreateUserPage';
import ResetPasswordPage from '../../components/pages/users/ResetPasswordPage';
import SettingsPage from '../../components/pages/user/SettingsPage';
import EditInvoiceConceptPage from '../../components/pages/invoices/EditInvoiceConceptPage';

const routes: RouteConfig[] = [
    {
        title: 'Inloggen',
        path: '/',
        exact: true,
        // eslint-disable-next-line react/display-name
        render: () => (
            <GuestRoute component={LoginPage} />
        )
    },
    {
        title: 'Dashboard',
        path: '/dashboard',
        exact: true,
        // eslint-disable-next-line react/display-name
        render: () => (
            <PrivateRoute component={DashboardPage} />
        )
    },
    {
        title: 'Bedrijf toevoegen',
        path: '/company/create',
        exact: true,
        // eslint-disable-next-line react/display-name
        render: () => (
            <PrivateRoute
                permissions={['create_company']}
                component={CreateCompanyPage}
            />
        )
    },
    {
        title: 'Bedrijf aanpassen',
        path: '/company/edit',
        exact: true,
        // eslint-disable-next-line react/display-name
        render: () => (
            <PrivateRoute
                permissions={['edit_company']}
                component={EditCompanyPage}
            />
        )
    },
    {
        title: 'Klant toevoegen',
        path: '/customer/create',
        exact: true,
        // eslint-disable-next-line react/display-name
        render: () => (
            <PrivateRoute
                permissions={['create_customer']}
                component={CreateCustomerPage}
            />
        )
    },
    {
        title: 'Factuur maken',
        path: '/invoices/create',
        exact: true,
        // eslint-disable-next-line react/display-name
        render: () => (
            <PrivateRoute
                permissions={['create_invoice']}
                component={CreateInvoicePage}
            />
        )
    },
    {
        title: 'Factuur aanpassen',
        path: '/invoices/:uuid/edit',
        exact: true,
        // eslint-disable-next-line react/display-name
        render: () => (
            <PrivateRoute
                permissions={['create_invoice']}
                component={EditInvoicePage}
            />
        )
    },
    {
        title: 'Concept aanpassen',
        path: '/invoices/concept/:uuid/edit',
        exact: true,
        // eslint-disable-next-line react/display-name
        render: () => (
            <PrivateRoute
                permissions={['create_invoice']}
                component={EditInvoiceConceptPage}
            />
        )
    },
    {
        title: 'Factuurdetails',
        path: '/invoices/:uuid/details',
        exact: true,
        // eslint-disable-next-line react/display-name
        render: () => (
            <PrivateRoute
                permissions={['list_invoices']}
                component={InvoiceDetailsPage}
            />
        )
    },
    {
        title: 'Factuuroverzicht',
        path: '/invoices/:page?',
        exact: true,
        // eslint-disable-next-line react/display-name
        render: () => (
            <PrivateRoute
                permissions={['list_invoices']}
                component={InvoiceListPage}
            />
        )
    },
    {
        title: 'Periodieke facturen',
        path: '/recurringinvoices/:page?',
        exact: true,
        // eslint-disable-next-line react/display-name
        render: () => (
            <PrivateRoute
                permissions={['list_invoices']}
                component={RecurringInvoiceListPage}
            />
        )
    },
    {
        title: 'Betalingen',
        path: '/payments/:page?',
        exact: true,
        // eslint-disable-next-line react/display-name
        render: () => (
            <PrivateRoute
                permissions={['list_payments']}
                component={PaymentsListPage}
            />
        )
    },
    {
        title: 'Betalingsmethodes',
        path: '/paymentmethods',
        exact: true,
        // eslint-disable-next-line react/display-name
        render: () => (
            <PrivateRoute
                permissions={['create_payment']}
                component={PaymentMethodsListPage}
            />
        )
    },
    {
        title: 'BTW-instellingen',
        path: '/vatrates',
        exact: true,
        // eslint-disable-next-line react/display-name
        render: () => (
            <PrivateRoute
                permissions={['list_vat_rates']}
                component={VatRatesListPage}
            />
        )
    },
    {
        title: 'Offerte maken',
        path: '/quotes/create',
        exact: true,
        // eslint-disable-next-line react/display-name
        render: () => (
            <PrivateRoute
                permissions={['create_quote']}
                component={CreateQuotePage}
            />
        )
    },
    {
        title: 'Offerte aanpassen',
        path: '/quotes/:uuid/edit',
        exact: true,
        // eslint-disable-next-line react/display-name
        render: () => (
            <PrivateRoute
                permissions={['create_quote']}
                component={EditQuotePage}
            />
        )
    },
    {
        title: 'Offertedetails',
        path: '/quotes/:uuid/details',
        exact: true,
        // eslint-disable-next-line react/display-name
        render: () => (
            <PrivateRoute
                permissions={['list_quotes']}
                component={QuoteDetailsPage}
            />
        )
    },
    {
        title: 'Offerteoverzicht',
        path: '/quotes/:page?',
        exact: true,
        // eslint-disable-next-line react/display-name
        render: () => (
            <PrivateRoute
                permissions={['list_quotes']}
                component={QuoteListPage}
            />
        )
    },
    {
        title: 'Klantenoverzicht',
        path: '/customers/:page?',
        exact: true,
        // eslint-disable-next-line react/display-name
        render: () => (
            <PrivateRoute
                permissions={['list_customers']}
                component={CustomerListPage}
            />
        )
    },
    {
        title: 'Gebruiker toevoegen',
        path: '/users/create',
        exact: true,
        // eslint-disable-next-line react/display-name
        render: () => (
            <PrivateRoute
                permissions={['create_user']}
                component={CreateUserPage}
            />
        )
    },
    {
        title: 'Wachtwoord resetten',
        path: '/reset-password/:token',
        exact: true,
        component: ResetPasswordPage
    },
    {
        title: 'Instellingen',
        path: '/settings',
        exact: true,
        component: SettingsPage
    },
    {
        path: '*',
        // eslint-disable-next-line react/display-name
        render: () => (
            <div>404</div>
        )
    }
];

export {
    routes
};
