import * as React from 'react';
import clsx from 'clsx';
import { inject, observer } from 'mobx-react';
import Undraw from 'react-undraw';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect } from 'react';
import { reaction } from 'mobx';
import { isEqual } from 'lodash';
import Button from '../Button';
import { PermissionStore } from '../../../app/stores/permissions/PermissionStore';
import { UserStore } from '../../../app/stores/user/UserStore';
import { IUserCompany } from '../../../app/stores/user/interfaces/IUserCompany';

interface UsesCompanyProps extends React.HTMLAttributes<HTMLDivElement> {
    onCompanySelected?: (company: IUserCompany) => void;
    userStore?: UserStore;
    permissionStore?: PermissionStore;
}

const compare = (prev: IUserCompany | undefined, next: IUserCompany | undefined): boolean => isEqual(prev, next);

const UsesCompany = React.forwardRef<HTMLDivElement, UsesCompanyProps>((
    {
        children,
        className,
        userStore,
        onCompanySelected,
        permissionStore
    },
    ref
): React.ReactElement => {
    useEffect(() => {
        if (onCompanySelected) {
            const disposer = reaction(() => userStore?.currentCompany, () => {
                if (userStore?.currentCompany) {
                    onCompanySelected(userStore.currentCompany);
                }
            }, {
                equals: compare
            });

            return () => {
                /* istanbul ignore next */
                disposer();
            };
        }

        return () => {};
    }, []);

    if (userStore?.isLoading('get-companies')) {
        return <>Loading</>;
    }

    return userStore?.currentCompany ? (<>{children}</>) : (
        <div
            ref={ref}
            className={clsx(
                'uses-company',
                className
            )}
        >
            <div className="uses-company-no-company-img mb-8">
                <Undraw name="no_data" />
            </div>
            <h2 className="mb-4">Geen bedrijf geselecteerd.</h2>
            {/* istanbul ignore next @TODO solve error with link outside router */}
            {permissionStore?.can('create_company') && (
                <Button
                    variant="primary"
                    to="/company/create"
                    icon={<FontAwesomeIcon icon={faPlus} />}
                >
                    Nu een bedrijf maken
                </Button>
            )}
        </div>
    );
});

UsesCompany.displayName = 'UsesCompany';

export default inject(
    'userStore',
    'permissionStore'
)(observer(UsesCompany));
