import * as React from 'react';
import clsx from 'clsx';
import { useState } from 'react';
import generateGuid from '../../../app/utils/generateGuid';

export interface FormInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
    hasError?: boolean;
    subType?: 'currency' | 'vat';
    append?: React.ReactNode;
}

const FormInput = React.forwardRef<HTMLInputElement, FormInputProps>((
    {
        append,
        className,
        hasError,
        subType,
        onChange,
        ...rest
    },
    ref
): React.ReactElement => {
    const [hasFocus, setFocus] = useState(false);
    const [id] = useState(generateGuid());

    const interceptOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        switch (subType) {
            case 'currency':
                if (event.target.value && !event.target.value.match(/^(-|\d|,|\.)+$/)) {
                    return;
                }
                onChange?.(event);
                break;
            default:
                onChange?.(event);
                break;
        }
    };

    return (
        <label
            htmlFor={id}
            className={clsx(
                'form-control-container',
                hasError && 'has-error',
                hasFocus && 'has-focus',
                className
            )}
        >
            <input
                id={id}
                ref={ref}
                className={clsx(
                    'form-control'
                )}
                onFocus={() => {
                    setFocus(true);
                }}
                onBlur={() => {
                    setFocus(false);
                }}
                onChange={interceptOnChange}
                {...rest}
            />
            {append && (
                <div
                    className="form-control-container-append"
                >
                    {append}
                </div>
            )}
        </label>
    );
});

FormInput.displayName = 'FormInput';

export default FormInput;
