import * as React from 'react';
import clsx from 'clsx';
import { Status as StatusTypes } from '../../../app/stores/statuses/Status';

export interface StatusProps extends React.HTMLAttributes<HTMLDivElement> {
    variant: StatusTypes | string;
    small?: boolean;
    icon?: React.ReactNode;
}

const Status = React.forwardRef<HTMLDivElement, React.PropsWithChildren<StatusProps>>((
    {
        children,
        variant,
        small,
        icon,
        className
    },
    ref
): React.ReactElement => (
    <div
        ref={ref}
        className={clsx(
            'status',
            `status-${variant}`,
            small && 'status-small',
            className
        )}
    >
        {icon && (
            <div className={small ? 'mr-2' : 'mr-4'}>
                {icon}
            </div>
        )}
        {children}
    </div>
));

Status.displayName = 'Status';

export default Status;
