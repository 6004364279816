import React from 'react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import { RouteComponentProps, withRouter } from 'react-router';
import Undraw from 'react-undraw';
import Card from '../../shared/Card';
import Container from '../../shared/Container';
import FormGroup from '../../shared/Forms/Group';
import FormInput from '../../shared/Forms/Input';
import FormLabel from '../../shared/Forms/Label';
import { AuthStore } from '../../../app/stores/auth/AuthStore';
import Button from '../../shared/Button';
import { DataInvalidError } from '../../../app/errors/DataInvalidError';
import { FormErrors } from '../../../app/errors/FormErrors';
import FormMessage from '../../shared/Forms/Message';
import { InvalidCredentialsError } from '../../../app/stores/auth/errors/InvalidCredentialsException';
import { NotificationStore } from '../../../app/stores/notifications/NotificationStore';
import { INotification } from '../../../app/stores/notifications/interfaces/INotification';
import Notification from '../../shared/Notification';

export interface LoginPageProps extends RouteComponentProps {
    authStore?: AuthStore;
    notificationStore?: NotificationStore;
}

@inject('authStore', 'notificationStore')
@observer
class LoginPage extends React.Component<LoginPageProps, unknown> {
    @observable public formValues = {
        email: '',
        password: ''
    };
    private formErrors = new FormErrors();

    private async submitLogin(event: React.FormEvent): Promise<void> {
        event.preventDefault();

        const {
            authStore,
            history,
            notificationStore
        } = this.props;

        this.formErrors.clear();
        notificationStore?.clear();

        authStore
            ?.login(this.formValues.email, this.formValues.password)
            .then(() => {
                history.push('/dashboard');
            })
            .catch((e: DataInvalidError | InvalidCredentialsError) => {
                if (e instanceof DataInvalidError) {
                    this.formErrors.set(e.errors);
                } else {
                    notificationStore?.addNotification({
                        variant: 'danger',
                        message: e.message
                    });
                }
            });
    }

    public render(): React.ReactNode {
        const { notificationStore } = this.props;

        return (
            <div className="standalone-page center">
                <Container className="w-1/2">
                    <Card>
                        <div className="grid grid-cols-2 gap-4">
                            <div>
                                <h1>Inloggen</h1>
                                <h2 className="mb-4">Login om verder te gaan</h2>

                                <div className="mb-4">
                                    {notificationStore?.all().map((notification: INotification) => (
                                        <Notification key={notification.id} {...notification} />
                                    ))}
                                </div>

                                <form onSubmit={(event: React.FormEvent) => this.submitLogin(event)}>
                                    <FormGroup>
                                        <FormLabel>E-mailadres</FormLabel>
                                        <FormInput
                                            name="username"
                                            type="text"
                                            value={this.formValues.email}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                                                this.formValues.email = event.target.value;
                                            }}
                                            hasError={this.formErrors.has('email')}
                                        />
                                        {this.formErrors.has('email') && (
                                            <FormMessage type="invalid">
                                                {this.formErrors.first('email')}
                                            </FormMessage>
                                        )}
                                    </FormGroup>
                                    <FormGroup>
                                        <FormLabel>Wachtwoord</FormLabel>
                                        <FormInput
                                            name="password"
                                            type="password"
                                            value={this.formValues.password}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                                                this.formValues.password = event.target.value;
                                            }}
                                            hasError={this.formErrors.has('password')}
                                        />
                                        {this.formErrors.has('password') && (
                                            <FormMessage type="invalid">
                                                {this.formErrors.first('password')}
                                            </FormMessage>
                                        )}
                                    </FormGroup>
                                    <Button
                                        variant="primary"
                                        type="submit"
                                    >
                                        Inloggen
                                    </Button>
                                </form>
                            </div>
                            <div className="flex items-center">
                                <Undraw name="login" />
                            </div>
                        </div>
                    </Card>
                </Container>
            </div>
        );
    }
}

export default withRouter(LoginPage);
