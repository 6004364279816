import { observable } from 'mobx';
import { IUserCompany } from './interfaces/IUserCompany';
import { LoadingStore } from '../loading/LoadingStore';
import { api } from '../../services/api';
import { IResponse } from '../../services/api/IResponse';
import { application } from '../../application';
import { ApiError } from '../../errors/ApiError';
import { SomethingHappenedError } from '../../errors/SomethingHappenedError';
import { ResponseStatus } from '../../services/api/ResponseStatus';
import { IDisposable } from '../../interfaces/IDisposable';
import { ISelectedCompany } from '../company/interfaces/ISelectedCompany';
import { INewUser } from './interfaces/INewUser';
import { IUser } from '../auth/interfaces/IUser';
import { DataInvalidError } from '../../errors/DataInvalidError';
import { IErrorResponse } from '../../services/api/IErrorResponse';
import { IEditUser } from './interfaces/IEditUser';
import { timezoneStore } from '../timezones/TimezoneStore';

export class UserStore extends LoadingStore implements IDisposable {
    @observable private _currentCompany?: IUserCompany;
    @observable private _companies: IUserCompany[] = [];

    public set currentCompany(currentCompany: IUserCompany | undefined) {
        this._currentCompany = currentCompany;
    }

    public get currentCompany(): IUserCompany | undefined {
        return this._currentCompany;
    }

    public get companies(): IUserCompany[] {
        return this._companies;
    }

    public companiesExcept(companyUuid: string): IUserCompany[] {
        return this._companies.filter((userCompany: IUserCompany) => userCompany.company.uuid !== companyUuid);
    }

    public set companies(companies: IUserCompany[]) {
        this._companies = companies;
    }

    public async getCompanies(setCurrent?: boolean): Promise<void> {
        this.setLoading('get-companies');

        try {
            const response = await api.get<IResponse<IUserCompany[]>>('/api/user/companies');

            /* istanbul ignore else */
            if (response.status === ResponseStatus.OK) {
                this._companies = (response.data as IResponse<IUserCompany[]>).data;

                if (setCurrent) {
                    this.setCurrentFromCompanies();
                }

                return;
            }
        } catch (e) {
            /* istanbul ignore next */
            application.handleError(new ApiError(e));
            return;
        } finally {
            this.setLoaded('get-companies');
        }

        /* istanbul ignore next */
        application.handleError(new SomethingHappenedError());
    }

    private setCurrentFromCompanies(): void {
        if (this._companies.length) {
            for (let i = 0; i < this._companies.length; i++) {
                if (this._companies[i].active) {
                    this._currentCompany = this._companies[i];
                    timezoneStore.refreshTimezone(this._companies[i]);
                    break;
                }
            }
        }
    }

    public async selectCompany(uuid: string): Promise<void> {
        this.setLoading(`select-compaInvoiceListPage.tsxny-${uuid}`);

        try {
            const response = await api.post<IResponse<ISelectedCompany>>('/api/user/companies/select', {
                company_uuid: uuid
            });

            this.setLoaded(`select-company-${uuid}`);

            if (response.status === ResponseStatus.OK) {
                this._currentCompany = this._companies.find(
                    (company: IUserCompany) => company.uuid === (
                        response.data as IResponse<ISelectedCompany>
                    ).data.uuid
                );
                if (this._currentCompany) {
                    timezoneStore.refreshTimezone(this._currentCompany);
                }
                return;
            }
        } catch (e) {
            /* istanbul ignore next */
            application.handleError(new ApiError(e));
            /* istanbul ignore next */
            return;
        } finally {
            this.setLoaded('select-company');
        }

        /* istanbul ignore next */
        application.handleError(new SomethingHappenedError());
    }

    public async createUser(user: INewUser): Promise<IUser | undefined> {
        this.setLoading('create-user');

        try {
            const response = await api.post<IResponse<IUser> | IErrorResponse>('/api/users', user);

            if (response.status === ResponseStatus.CREATED) {
                return (response.data as IResponse<IUser>).data;
            }
        } catch (e) {
            if (e.response?.status === ResponseStatus.INVALID) {
                throw new DataInvalidError(
                    (e.response.data as IErrorResponse).message,
                    (e.response.data as IErrorResponse).errors!
                );
            /* istanbul ignore else */
            } else {
                application.handleError(new ApiError(e));

                return undefined;
            }
        } finally {
            this.setLoaded('create-user');
        }
        /* istanbul ignore next */
        application.handleError(new SomethingHappenedError());
        /* istanbul ignore next */
        return undefined;
    }

    public async editUser(data: IEditUser): Promise<IUser | undefined> {
        this.setLoading('edit-user');

        try {
            const response = await api.put<IResponse<IUser> | IErrorResponse>('/api/user', data);

            if (response.status === ResponseStatus.OK) {
                return (response.data as IResponse<IUser>).data;
            }
        } catch (e) {
            if (e.response?.status === ResponseStatus.INVALID) {
                throw new DataInvalidError(
                    (e.response.data as IErrorResponse).message,
                    (e.response.data as IErrorResponse).errors!
                );
            } else {
                application.handleError(new ApiError(e));

                return undefined;
            }
        } finally {
            this.setLoaded('edit-user');
        }

        application.handleError(new SomethingHappenedError());

        return undefined;
    }

    public dispose(): void {
        this._currentCompany = undefined;
        this._companies = [];
    }
}

const userStore = new UserStore();

export {
    userStore
};
