import * as React from 'react';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import ReactDatePicker from 'react-datetime';
import moment, { Moment } from 'moment-timezone';
import clsx from 'clsx';
import { IListInvoice } from '../../../app/stores/invoices/interfaces/IListInvoice';
import { FormErrors } from '../../../app/errors/FormErrors';
import { DataInvalidError } from '../../../app/errors/DataInvalidError';
import { NotificationStore } from '../../../app/stores/notifications/NotificationStore';
import { INotification } from '../../../app/stores/notifications/interfaces/INotification';
import { UserStore } from '../../../app/stores/user/UserStore';
import { RecurringInvoiceStore } from '../../../app/stores/recurring-invoices/RecurringInvoiceStore';
import { IntervalTypeStore } from '../../../app/stores/interval-types/IntervalTypeStore';
import { INewRecurringInvoice } from '../../../app/stores/recurring-invoices/interfaces/INewRecurringInvoice';
import { IIntervalType } from '../../../app/stores/interval-types/interfaces/IIntervalType';
import { IRecurringInvoice } from '../../../app/stores/recurring-invoices/interfaces/IRecurringInvoice';
import Button from '../../shared/Button';
import Notification from '../../shared/Notification';
import FormGroup from '../../shared/Forms/Group';
import FormLabel from '../../shared/Forms/Label';
import FormSelect from '../../shared/Forms/Select';
import FormMessage from '../../shared/Forms/Message';
import { AuthStore } from '../../../app/stores/auth/AuthStore';

export interface AddRecurringInvoiceModalProps {
    invoice: IListInvoice;
    notificationStore?: NotificationStore;
    onRecurringInvoiceCreated: () => void;
    recurringInvoiceStore?: RecurringInvoiceStore;
    userStore?: UserStore;
    intervalTypeStore?: IntervalTypeStore;
    authStore?: AuthStore;
}

@inject('intervalTypeStore', 'userStore', 'notificationStore', 'recurringInvoiceStore', 'authStore')
@observer
class AddRecurringInvoiceModal extends React.Component<AddRecurringInvoiceModalProps, unknown> {
    public static NOTIFICATION_NAMESPACE = 'create_recurring_invoice';

    @observable private formErrors: FormErrors;
    @observable private formValues: INewRecurringInvoice = {
        interval_uuid: '',
        start_date: '',
        end_date: ''
    };

    @observable private intervalTypes: IIntervalType[] = [];

    public constructor(props: AddRecurringInvoiceModalProps) {
        super(props);

        this.formErrors = new FormErrors();
    }

    public componentDidMount() {
        const { intervalTypeStore } = this.props;

        intervalTypeStore?.getIntervalTypes().then((intervalTypes: IIntervalType[]) => {
            this.intervalTypes = intervalTypes;

            if (intervalTypes.length) {
                this.formValues.interval_uuid = intervalTypes[0].uuid;
            }
        });
    }

    private submitCreate(event: React.FormEvent): void {
        event.preventDefault();

        const {
            invoice,
            notificationStore,
            recurringInvoiceStore,
            onRecurringInvoiceCreated
        } = this.props;

        notificationStore?.clear(AddRecurringInvoiceModal.NOTIFICATION_NAMESPACE);
        this.formErrors.clear();

        recurringInvoiceStore?.createRecurringInvoice(
            invoice.uuid,
            this.formValues
        ).then((recurringInvoice: IRecurringInvoice | undefined) => {
            if (recurringInvoice) {
                onRecurringInvoiceCreated();
                notificationStore?.addNotification({
                    variant: 'success',
                    message: `Factuur succesvol periodiek gemaakt. De eerstvolgende datum is ${moment(recurringInvoice.next_date).format('DD-MM-YYYY')}`
                });
            }
        }).catch((e: DataInvalidError): void => {
            this.formErrors.set(e.errors);
            notificationStore?.addNotification({
                variant: 'danger',
                message: e.message,
                namespace: AddRecurringInvoiceModal.NOTIFICATION_NAMESPACE
            });
        });
    }

    public render(): React.ReactNode {
        const { notificationStore, userStore } = this.props;

        return (
            <>
                <div className="mb-4">
                    {notificationStore?.all(
                        AddRecurringInvoiceModal.NOTIFICATION_NAMESPACE
                    ).map((notification: INotification) => (
                        <Notification
                            key={notification.id}
                            {...notification}
                        />
                    ))}
                </div>
                <form onSubmit={(event: React.FormEvent) => this.submitCreate(event)}>
                    <FormGroup>
                        <FormLabel>Elke</FormLabel>
                        <FormSelect
                            value={this.formValues.interval_uuid}
                            name="interval_uuid"
                            hasError={this.formErrors.has('interval_uuid')}
                            onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                                this.formValues.interval_uuid = event.target.value;
                            }}
                        >
                            {this.intervalTypes.map((intervalType: IIntervalType) => (
                                <option
                                    key={intervalType.uuid}
                                    value={intervalType.uuid}
                                >
                                    {intervalType.name}
                                </option>
                            ))}
                        </FormSelect>
                        {this.formErrors.has('interval_uuid') && (
                            <FormMessage type="invalid">
                                {this.formErrors.first('interval_uuid')}
                            </FormMessage>
                        )}
                    </FormGroup>
                    <FormGroup>
                        <FormLabel>Startdatum</FormLabel>
                        <ReactDatePicker
                            className={clsx(
                                'form-control-container w-full',
                                this.formErrors.has('start_date') && 'has-error'
                            )}
                            inputProps={{
                                className: 'form-control',
                                name: 'start_date',
                                autoComplete: 'off'
                            }}
                            locale="nl"
                            closeOnSelect
                            timeFormat={false}
                            displayTimeZone={userStore?.currentCompany?.company.timezone.name.replace(' ', '_')}
                            onChange={(date: Moment | string) => {
                                if (typeof date !== 'string') {
                                    this.formValues.start_date = date.format();
                                }
                            }}
                            value={this.formValues.start_date
                                ? moment(this.formValues.start_date)
                                : undefined}
                        />
                        {this.formErrors.has('start_date') && (
                            <FormMessage type="invalid">
                                {this.formErrors.first('start_date')}
                            </FormMessage>
                        )}
                    </FormGroup>
                    <FormGroup>
                        <FormLabel>Einddatum (optioneel)</FormLabel>
                        <ReactDatePicker
                            className={clsx(
                                'form-control-container w-full',
                                this.formErrors.has('end_date') && 'has-error'
                            )}
                            inputProps={{
                                className: 'form-control',
                                name: 'end_date',
                                autoComplete: 'off'
                            }}
                            locale="nl"
                            closeOnSelect
                            timeFormat={false}
                            displayTimeZone={userStore?.currentCompany?.company.timezone.name.replace(' ', '_')}
                            onChange={(date: Moment | string) => {
                                if (typeof date !== 'string') {
                                    this.formValues.end_date = date.format();
                                }
                            }}
                            value={this.formValues.end_date
                                ? moment(this.formValues.end_date)
                                : undefined}
                        />
                        {this.formErrors.has('end_date') && (
                            <FormMessage type="invalid">
                                {this.formErrors.first('end_date')}
                            </FormMessage>
                        )}
                    </FormGroup>
                    <Button
                        variant="primary"
                        type="submit"
                    >
                        Opslaan
                    </Button>
                </form>
            </>
        );
    }
}

export default AddRecurringInvoiceModal;
