import React from 'react';
import { render } from 'react-dom';
import { Provider as MobxProvider } from 'mobx-react';
import Undraw from 'react-undraw';
import '@fortawesome/fontawesome-svg-core/styles.css';
import { config } from '@fortawesome/fontawesome-svg-core';
import Modal from 'react-modal';
import 'moment/locale/nl';
import App from './components/App';
import storesRoot from './app/stores/StoresRoot';
import 'react-datetime/css/react-datetime.css';
import 'react-dropdown/style.css';
import 'react-grid-layout/css/styles.css';
import './resources/scss/index.scss';

// config
Undraw.defaultProps.primaryColor = '#0d5892';
config.autoAddCss = false;
Modal.setAppElement('#root');

render(
    <MobxProvider {...storesRoot.stores}>
        <App />
    </MobxProvider>,
    document.getElementById('root')
);
