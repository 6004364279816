import { autorun, observable } from 'mobx';
import { INotification } from './interfaces/INotification';
import { history } from '../../utils/history';
import generateGuid from '../../utils/generateGuid';

export class NotificationStore {
    @observable private _notifications: Array<INotification> = [];

    public static AUTOCLOSE_DELAY = 5000;

    public constructor() {
        history.listen(() => this.onLocationChange());
    }

    private onLocationChange() {
        this.clear();
    }

    public all(namespace?: string): Array<INotification> {
        return this._notifications
            .filter((notification: INotification) => notification.namespace === namespace);
    }

    public addNotification(notification: Omit<INotification, 'id'>): void {
        if (notification.type && notification.once) {
            if (this._notifications.findIndex((n) => n.type === notification.type) !== -1) {
                return;
            }
        }

        const id = generateGuid();
        const notificationWithId: INotification = {
            ...notification,
            id,
            closable: notification.closable || true
        };

        this._notifications.push(notificationWithId);

        if (notification.autoClose) {
            autorun(() => {
                this.removeNotification(id);
            }, { delay: NotificationStore.AUTOCLOSE_DELAY });
        }
    }

    public removeNotification(id: string): void {
        this._notifications = this._notifications.filter((n: INotification) => n.id !== id);
    }

    public clear(namespace?: string): void {
        this._notifications = this._notifications
            .filter((notification: INotification) => notification.namespace !== namespace);
    }
}

const notificationStore = new NotificationStore();

export {
    notificationStore
};
