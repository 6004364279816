import React from 'react';
import { observable } from 'mobx';
import { LoadingStore } from '../loading/LoadingStore';
import { IListInvoice } from '../invoices/interfaces/IListInvoice';
import { INewRecurringInvoice } from './interfaces/INewRecurringInvoice';
import Modal from '../../../components/shared/Modal';
import { api } from '../../services/api';
import { ResponseStatus } from '../../services/api/ResponseStatus';
import { IResponse } from '../../services/api/IResponse';
import { IErrorResponse } from '../../services/api/IErrorResponse';
import { IRecurringInvoice } from './interfaces/IRecurringInvoice';
import { DataInvalidError } from '../../errors/DataInvalidError';
import { application } from '../../application';
import { ApiError } from '../../errors/ApiError';
import { SomethingHappenedError } from '../../errors/SomethingHappenedError';
import AddRecurringInvoiceModal from '../../../components/pages/recurring-invoices/AddRecurringInvoiceModal';
import { notificationStore } from '../notifications/NotificationStore';

export class RecurringInvoiceStore extends LoadingStore {
    @observable private _activeInvoice?: IListInvoice;

    public set activeInvoice(invoice: IListInvoice | undefined) {
        this._activeInvoice = invoice;
    }

    public get activeInvoice(): IListInvoice | undefined {
        return this._activeInvoice;
    }

    public async createRecurringInvoice(
        invoiceUuid: string,
        recurringInvoice: INewRecurringInvoice
    ): Promise<IRecurringInvoice | undefined> {
        this.setLoading('create-recurring-invoice');

        try {
            const response = await api.post<IResponse<IRecurringInvoice> | IErrorResponse>(
                '/api/recurringinvoices',
                {
                    invoice_uuid: invoiceUuid,
                    ...recurringInvoice
                }
            );

            if (response.status === ResponseStatus.CREATED) {
                return (response.data as IResponse<IRecurringInvoice>).data;
            }
        } catch (e) {
            if (e.response?.status === ResponseStatus.INVALID) {
                throw new DataInvalidError(
                    (e.response.data as IErrorResponse).message,
                    (e.response.data as IErrorResponse).errors!
                );
            }
            /* istanbul ignore next */
            application.handleError(new ApiError(e));
            /* istanbul ignore next */
            return undefined;
        }

        /* istanbul ignore next */
        application.handleError(new SomethingHappenedError());
        /* istanbul ignore next */
        return undefined;
    }

    public useModal(): React.ReactNode {
        if (this._activeInvoice) {
            return (
                <Modal
                    title={`Factuur ${this._activeInvoice.invoice_number} periodiek maken`}
                    closable
                    isOpen={!!this._activeInvoice}
                    onRequestClose={() => {
                        this._activeInvoice = undefined;
                        notificationStore.clear(AddRecurringInvoiceModal.NOTIFICATION_NAMESPACE);
                    }}
                >
                    <AddRecurringInvoiceModal
                        invoice={this._activeInvoice}
                        onRecurringInvoiceCreated={() => {
                            this._activeInvoice = undefined;
                            notificationStore.clear(AddRecurringInvoiceModal.NOTIFICATION_NAMESPACE);
                        }}
                    />
                </Modal>
            );
        }

        return undefined;
    }

    public async stopRecurringInvoice(invoice: IRecurringInvoice): Promise<boolean> {
        this.setLoading(`stop-recurring-invoice-${invoice.uuid}`);

        try {
            const response = await api.delete(`/api/recurringinvoices/${invoice.uuid}`);

            if (response.status === ResponseStatus.EMPTY) {
                return true;
            }
        } catch (e) {
            /* istanbul ignore next */
            application.handleError(new ApiError(e));
            /* istanbul ignore next */
            return false;
        } finally {
            this.setLoaded(`stop-recurring-invoice-${invoice.uuid}`);
        }
        /* istanbul ignore next */
        application.handleError(new SomethingHappenedError());
        /* istanbul ignore next */
        return false;
    }
}

const recurringInvoiceStore = new RecurringInvoiceStore();

export {
    recurringInvoiceStore
};
