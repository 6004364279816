export default <T>(arr: T[], separator: (n: number) => T): T[] => arr.reduce<T[]>(
    (acc: T[], currentElement: T, currentIndex: number) => {
        const isLast = currentIndex === arr.length - 1;
        return [
            ...acc,
            currentElement,
            ...(isLast ? [] : [separator(currentIndex)])
        ];
    }, []
);
