import { LoadingStore } from '../loading/LoadingStore';
import { api } from '../../services/api';
import { ResponseStatus } from '../../services/api/ResponseStatus';
import { NotFoundError } from '../../errors/NotFoundError';
import { application } from '../../application';
import { ApiError } from '../../errors/ApiError';
import { SomethingHappenedError } from '../../errors/SomethingHappenedError';
import { INewPassword } from './interfaces/INewPassword';
import { DataInvalidError } from '../../errors/DataInvalidError';
import { IErrorResponse } from '../../services/api/IErrorResponse';

export class PasswordResetStore extends LoadingStore {
    public async getPasswordReset(token: string): Promise<boolean> {
        this.setLoading('get-password-reset');

        try {
            const response = await api.get<unknown>(`/api/password-reset/${token}`);

            if (response.status === ResponseStatus.OK) {
                return true;
            }
        } catch (e) {
            if (e?.response.status === ResponseStatus.NOT_FOUND) {
                throw new NotFoundError('');
            } else {
                application.handleError(new ApiError(e));
                return false;
            }
        } finally {
            this.setLoaded('get-password-reset');
        }

        application.handleError(new SomethingHappenedError());
        return false;
    }

    public async resetPassword(values: INewPassword, token: string): Promise<boolean> {
        this.setLoading('reset-password');

        try {
            const response = await api.post<unknown | IErrorResponse>(`/api/password-reset/reset`, {
                ...values,
                token
            });

            if (response.status === ResponseStatus.EMPTY) {
                return true;
            }
        } catch (e) {
            if (e.response?.status === ResponseStatus.INVALID) {
                throw new DataInvalidError(
                    (e.response.data as IErrorResponse).message,
                    (e.response.data as IErrorResponse).errors!
                );
            } else {
                application.handleError(new ApiError(e));

                return false;
            }
        } finally {
            this.setLoaded('reset-password');
        }

        application.handleError(new SomethingHappenedError());

        return false;
    }
}

const passwordResetStore = new PasswordResetStore();

export {
    passwordResetStore
};
