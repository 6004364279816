import { LoadingStore } from '../loading/LoadingStore';
import { IStatus } from './interfaces/IStatus';
import { api } from '../../services/api';
import { ResponseStatus } from '../../services/api/ResponseStatus';
import { IResponse } from '../../services/api/IResponse';
import { application } from '../../application';
import { SomethingHappenedError } from '../../errors/SomethingHappenedError';

export class StatusStore extends LoadingStore {
    public async getStatuses(): Promise<IStatus[]> {
        this.setLoading('get-statuses');

        try {
            const response = await api.get<IResponse<IStatus[]>>('api/statuses');

            if (response.status === ResponseStatus.OK) {
                return response.data.data;
            }
        } catch (e) {
            //
        } finally {
            this.setLoaded('get-statuses');
        }

        /* istanbul ignore next */
        application.handleError(new SomethingHappenedError());
        /* istanbul ignore next */
        return [];
    }
}

const statusStore = new StatusStore();

export {
    statusStore
};
