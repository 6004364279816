import * as React from 'react';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import moment, { Moment } from 'moment-timezone';
import clsx from 'clsx';
import ReactDatePicker from 'react-datetime';
import FormGroup from '../Forms/Group';
import FormLabel from '../Forms/Label';
import FormInput from '../Forms/Input';
import Button from '../Button';
import { IListInvoice } from '../../../app/stores/invoices/interfaces/IListInvoice';
import { FormErrors } from '../../../app/errors/FormErrors';
import { INewPayment } from '../../../app/stores/invoices/interfaces/payments/INewPayment';
import { IResponse } from '../../../app/services/api/IResponse';
import { IPayment } from '../../../app/stores/payments/interfaces/IPayment';
import { DataInvalidError } from '../../../app/errors/DataInvalidError';
import { DetailedMessageError } from '../../../app/errors/DetailedMessageError';
import { PaymentStore } from '../../../app/stores/payments/PaymentStore';
import { NotificationStore } from '../../../app/stores/notifications/NotificationStore';
import Card from '../Card';
import { INotification } from '../../../app/stores/notifications/interfaces/INotification';
import Notification from '../Notification';
import FormMessage from '../Forms/Message';
import { IPaymentMethod } from '../../../app/stores/payment-methods/interfaces/IPaymentMethod';
import { PaymentMethodStore } from '../../../app/stores/payment-methods/PaymentMethodStore';
import { UserStore } from '../../../app/stores/user/UserStore';
import FormSelect from '../Forms/Select';
import { AuthStore } from '../../../app/stores/auth/AuthStore';

export interface AddPaymentModelProps {
    invoice: IListInvoice;
    onClose: () => void;
    paymentStore?: PaymentStore;
    notificationStore?: NotificationStore;
    onPaymentCreated: () => void;
    paymentMethodStore?: PaymentMethodStore;
    userStore?: UserStore;
    authStore?: AuthStore;
}

@inject('invoiceStore', 'userStore', 'paymentStore', 'notificationStore', 'paymentMethodStore', 'authStore')
@observer
class AddPaymentModel extends React.Component<AddPaymentModelProps, unknown> {
    public static NOTIFICATION_NAMESPACE = 'create_payment';

    @observable private formErrors: FormErrors;
    private defaultValues: INewPayment = {
        amount: '',
        paid_at: moment().format(),
        payment_method_uuid: ''
    };
    @observable private formValues: INewPayment;
    @observable private paymentMethods: IPaymentMethod[] = [];

    public constructor(props: AddPaymentModelProps) {
        super(props);

        this.formErrors = new FormErrors();
        this.formValues = this.defaultValues;
    }

    public componentDidMount() {
        const { paymentMethodStore, userStore } = this.props;

        if (userStore?.currentCompany) {
            paymentMethodStore?.getPaymentMethods(
                userStore.currentCompany.company.uuid
            ).then((paymentMethods: IPaymentMethod[]) => {
                this.paymentMethods = paymentMethods;
            });
        }
    }

    private submitPayment(event: React.FormEvent): void {
        event.preventDefault();

        const {
            paymentStore, notificationStore, invoice, onPaymentCreated
        } = this.props;

        notificationStore?.clear(AddPaymentModel.NOTIFICATION_NAMESPACE);
        this.formErrors.clear();

        paymentStore?.createPayment(
            invoice.uuid,
            this.formValues
        ).then((payment: IResponse<IPayment, { rest_amount: string }> | undefined) => {
            if (payment) {
                this.formValues = this.defaultValues;
                notificationStore?.addNotification({
                    variant: 'success',
                    message: 'Betaling succesvol',
                    // eslint-disable-next-line max-len
                    messageDetails: `Het openstaande bedrag van factuur ${payment.data.invoice_number} is nu €${payment.rest_amount}`,
                    namespace: AddPaymentModel.NOTIFICATION_NAMESPACE
                });
                onPaymentCreated();
            }
        }).catch((e: DataInvalidError | DetailedMessageError): void => {
            if (e instanceof DataInvalidError) {
                this.formErrors.set(e.errors);
                notificationStore?.addNotification({
                    variant: 'danger',
                    message: e.message,
                    namespace: AddPaymentModel.NOTIFICATION_NAMESPACE
                });
            } else {
                notificationStore?.addNotification({
                    variant: 'danger',
                    message: e.message,
                    messageDetails: e.details,
                    namespace: AddPaymentModel.NOTIFICATION_NAMESPACE
                });
            }
        });
    }

    public render(): React.ReactNode {
        const {
            invoice,
            notificationStore,
            onClose,
            userStore
        } = this.props;

        return (
            <Card>
                <div className="flex items-center mb-4">
                    <h2>{`Betaling toevoegen aan factuur ${invoice.invoice_number}`}</h2>
                    <Button
                        className="ml-auto modal-close"
                        variant="light"
                        onClick={onClose}
                    >
                        <FontAwesomeIcon icon={faTimes} />
                    </Button>
                </div>
                <div className="mb-4">
                    {notificationStore?.all(
                        AddPaymentModel.NOTIFICATION_NAMESPACE
                    ).map((notification: INotification) => (
                        <Notification key={notification.id} {...notification} />
                    ))}
                </div>
                <form onSubmit={(event: React.FormEvent) => this.submitPayment(event)}>
                    <FormGroup>
                        <FormLabel>Bedrag</FormLabel>
                        <FormInput
                            value={this.formValues.amount}
                            type="text"
                            subType="currency"
                            name="amount"
                            hasError={this.formErrors.has('amount')}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                this.formValues.amount = event.target.value;
                            }}
                        />
                        {this.formErrors.has('amount') && (
                            <FormMessage type="invalid">
                                {this.formErrors.first('amount')}
                            </FormMessage>
                        )}
                    </FormGroup>
                    <FormGroup>
                        <FormLabel>Betaald op</FormLabel>
                        <ReactDatePicker
                            className={clsx(
                                'form-control-container w-full',
                                this.formErrors.has('paid_at') && 'has-error'
                            )}
                            inputProps={{
                                className: 'form-control',
                                name: 'paid_at',
                                autoComplete: 'off'
                            }}
                            locale="nl"
                            closeOnSelect
                            timeFormat={false}
                            displayTimeZone={userStore?.currentCompany?.company.timezone.name.replace(' ', '_')}
                            onChange={(date: Moment | string) => {
                                if (typeof date !== 'string') {
                                    this.formValues.paid_at = date.format();
                                }
                            }}
                            value={this.formValues.paid_at
                                ? moment(this.formValues.paid_at)
                                : undefined}
                        />
                        {this.formErrors.has('paid_at') && (
                            <FormMessage type="invalid">
                                {this.formErrors.first('paid_at')}
                            </FormMessage>
                        )}
                    </FormGroup>
                    <FormGroup>
                        <FormLabel>Betalingsmethode</FormLabel>
                        <FormSelect
                            name="payment_method_uuid"
                            value={this.formValues.payment_method_uuid}
                            onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                                this.formValues.payment_method_uuid = event.target.value;
                            }}
                            hasError={this.formErrors.has('payment_method_uuid')}
                        >
                            <option value="">Kiezen...</option>
                            {this.paymentMethods.map((paymentMethod: IPaymentMethod) => (
                                <option
                                    key={paymentMethod.uuid}
                                    value={paymentMethod.uuid}
                                >
                                    {paymentMethod.name}
                                </option>
                            ))}
                        </FormSelect>
                        {this.formErrors.has('payment_method_uuid') && (
                            <FormMessage type="invalid">
                                {this.formErrors.first('payment_method_uuid')}
                            </FormMessage>
                        )}
                    </FormGroup>
                    <Button
                        variant="primary"
                        type="submit"
                    >
                        Betaling toevoegen
                    </Button>
                </form>
            </Card>
        );
    }
}

export default AddPaymentModel;
