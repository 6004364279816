import * as React from 'react';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';

interface PageNavigationItemProps {
    title: string;
    path?: string;
    icon?: React.ReactElement;
    isActive?: () => void;
    exact?: boolean;
}

const PageNavigationItem = React.forwardRef<HTMLAnchorElement, React.PropsWithChildren<PageNavigationItemProps>>((
    {
        title,
        path,
        icon,
        children,
        exact
    },
    ref
): React.ReactElement => {
    if (path) {
        return (
            <NavLink
                className="page-navigation-item"
                ref={ref}
                to={path}
                exact={exact}
            >
                {icon && (
                    <div className="page-navigation-icon">{icon}</div>
                )}
                {title}
            </NavLink>
        );
    }

    if (children) {
        return (
            <>
                <div className="page-navigation-divider mt-4" />
                <div className="page-navigation-group-title mt-4">
                    {title}
                </div>
                <div
                    className={clsx('page-navigation-subitems')}
                >
                    {children}
                </div>
            </>
        );
    }

    return <div />;
});

PageNavigationItem.displayName = 'PageNavigationItem';

export default PageNavigationItem;
