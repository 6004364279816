export class NotFoundError extends Error {
    private readonly _details?: string;

    public constructor(message: string, details?: string) {
        super(message);

        this._details = details;
    }

    public get details(): string | undefined {
        return this._details;
    }
}
