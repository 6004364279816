import { LoadingStore } from '../loading/LoadingStore';
import { IVatRate } from './interfaces/IVatRate';
import { api } from '../../services/api';
import { IResponse } from '../../services/api/IResponse';
import { ResponseStatus } from '../../services/api/ResponseStatus';
import { application } from '../../application';
import { ApiError } from '../../errors/ApiError';
import { SomethingHappenedError } from '../../errors/SomethingHappenedError';
import { INewVatRate } from './interfaces/INewVatRate';
import { DataInvalidError } from '../../errors/DataInvalidError';
import { IErrorResponse } from '../../services/api/IErrorResponse';
import { DetailedMessageError } from '../../errors/DetailedMessageError';

export class VatRatesStore extends LoadingStore {
    public async getVatRates(companyUuid: string): Promise<IVatRate[]> {
        this.setLoading('get-vat-rates');

        try {
            const response = await api.get<IResponse<IVatRate[]>>(`/api/companies/${companyUuid}/vatrates`);

            if (response.status === ResponseStatus.OK) {
                return response.data.data;
            }
        } catch (e) {
            /* istanbul ignore next */
            application.handleError(new ApiError(e));
            /* istanbul ignore next */
            return [];
        } finally {
            this.setLoaded('get-vat-rates');
        }
        /* istanbul ignore next */
        application.handleError(new SomethingHappenedError());
        /* istanbul ignore next */
        return [];
    }

    public async createVatRate(companyUuid: string, vatRate: INewVatRate): Promise<IVatRate | undefined> {
        this.setLoading('create-vat-rate');

        try {
            const response = await api.post<IResponse<IVatRate> | IErrorResponse>('/api/vatrates', {
                company_uuid: companyUuid,
                ...vatRate
            });

            if (response.status === ResponseStatus.CREATED) {
                return (response.data as IResponse<IVatRate>).data;
            }
        } catch (e) {
            if (e.response?.status === ResponseStatus.INVALID) {
                throw new DataInvalidError(
                    (e.response.data as IErrorResponse).message,
                    (e.response.data as IErrorResponse).errors!
                );
            } else if (e.response?.status === ResponseStatus.CONFLICT) {
                throw new DataInvalidError(
                    (e.response.data as IErrorResponse).message,
                    {}
                );
            }
            /* istanbul ignore next */
            application.handleError(new ApiError(e));
            /* istanbul ignore next */
            return undefined;
        } finally {
            this.setLoaded('create-vat-rate');
        }
        /* istanbul ignore next */
        application.handleError(new SomethingHappenedError());
        /* istanbul ignore next */
        return undefined;
    }

    public async deleteVatRate(vatRate: IVatRate): Promise<void> {
        this.setLoading(`delete-vat-rate-${vatRate.uuid}`);

        try {
            const response = await api.delete<unknown | IErrorResponse>(`/api/vatrates/${vatRate.uuid}`);

            if (response.status === ResponseStatus.EMPTY) {
                return;
            }
        } catch (e) {
            if (e.response?.status === ResponseStatus.INVALID) {
                throw new DetailedMessageError(
                    (e.response.data as IErrorResponse).message,
                    ''
                );
            } else {
                /* istanbul ignore next */
                application.handleError(new ApiError(e));
                /* istanbul ignore next */
                return;
            }
        } finally {
            this.setLoaded(`delete-vat-rate-${vatRate.uuid}`);
        }
        /* istanbul ignore next */
        application.handleError(new SomethingHappenedError());
    }

    public async editVatRate(vatRateUuid: string, vatRate: INewVatRate): Promise<IVatRate | undefined> {
        this.setLoading(`edit-vat-rate-${vatRateUuid}`);

        try {
            const response = await api.put<IResponse<IVatRate> | IErrorResponse>(
                `/api/vatrates/${vatRateUuid}`,
                vatRate
            );

            if (response.status === ResponseStatus.OK) {
                return (response.data as IResponse<IVatRate>).data;
            }
        } catch (e) {
            if (e.response?.status === ResponseStatus.INVALID || e.response?.status === ResponseStatus.CONFLICT) {
                throw new DataInvalidError(
                    (e.response.data as IErrorResponse).message,
                    (e.response.data as IErrorResponse).errors || {}
                );
            } else {
                /* istanbul ignore next */
                application.handleError(new ApiError(e));
                /* istanbul ignore next */
                return undefined;
            }
        } finally {
            this.setLoaded(`edit-vat-rate-${vatRateUuid}`);
        }
        /* istanbul ignore next */
        application.handleError(new SomethingHappenedError());
        /* istanbul ignore next */
        return undefined;
    }
}

const vatRatesStore = new VatRatesStore();

export {
    vatRatesStore
};
