import * as React from 'react';
import clsx from 'clsx';

const FormRow = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>((
    {
        children,
        className
    },
    ref
): React.ReactElement => (
    <div
        ref={ref}
        className={clsx(
            'form-row',
            className
        )}
    >
        {children}
    </div>
));

FormRow.displayName = 'FormRow';

export default FormRow;
