import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { RouteComponentProps, withRouter } from 'react-router';
import { observable } from 'mobx';
import moment from 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { InvoiceStore } from '../../../app/stores/invoices/InvoiceStore';
import { NotFoundError } from '../../../app/errors/NotFoundError';
import { NotificationStore } from '../../../app/stores/notifications/NotificationStore';
import Page from '../../shared/Page';
import UsesCompany from '../../shared/company/UsesCompany';
import Container from '../../shared/Container';
import { IInvoiceItem } from '../../../app/stores/invoices/interfaces/IInvoiceItem';
import Card from '../../shared/Card';
import { UserStore } from '../../../app/stores/user/UserStore';
import { IInvoice } from '../../../app/stores/invoices/interfaces/IInvoice';
import { IInvoiceStatus } from '../../../app/stores/invoices/interfaces/IInvoiceStatus';
import Divider from '../../shared/Divider';
import Button from '../../shared/Button';
import { history } from '../../../app/utils/history';
import { Status as StatusType } from '../../../app/stores/statuses/Status';

interface InvoiceDetailsPageParams {
    uuid: string;
}

export interface InvoiceDetailsPageProps extends RouteComponentProps<InvoiceDetailsPageParams> {
    invoiceStore?: InvoiceStore;
    userStore?: UserStore;
    notificationStore?: NotificationStore;
}

@inject('invoiceStore', 'notificationStore', 'userStore')
@observer
class InvoiceDetailsPage extends React.Component<InvoiceDetailsPageProps, unknown> {
    @observable private invoice?: IInvoice;

    public componentDidMount(): void {
        const { userStore } = this.props;

        if (userStore?.currentCompany) {
            this.refreshInvoice();
        }
    }

    public refreshInvoice(): void {
        const {
            invoiceStore,
            notificationStore,
            match: {
                params: {
                    uuid
                }
            }
        } = this.props;

        invoiceStore?.getInvoice(uuid).then((invoice: IInvoice | undefined) => {
            if (invoice) {
                this.invoice = invoice;
            }
        }).catch((e: NotFoundError) => {
            notificationStore?.addNotification({
                message: e?.message || 'Factuur niet gevonden',
                messageDetails: e?.details,
                variant: 'danger',
                closable: false
            });
            this.invoice = undefined;
        });
    }

    private changeCompany(): void {
        const { notificationStore } = this.props;

        notificationStore?.clear();

        this.refreshInvoice();
    }

    private downloadInvoice() {
        const { invoiceStore } = this.props;

        if (this.invoice) {
            invoiceStore?.downloadInvoice(this.invoice.uuid, this.invoice.invoice_number);
        }
    }

    private resendInvoice(): void {
        const {
            invoiceStore,
            notificationStore,
            match: {
                params: {
                    uuid
                }
            }
        } = this.props;

        notificationStore?.clear();

        if (this.invoice) {
            invoiceStore?.resendInvoice(
                uuid
            ).then((success: boolean): void => {
                if (success) {
                    history.push(`/invoices/${uuid}/details`);

                    notificationStore?.addNotification({
                        variant: 'success',
                        message: `Factuur succesvol verzonden`
                    });

                    this.refreshInvoice();
                }
            }).catch(() => {
                notificationStore?.addNotification({
                    variant: 'danger',
                    message: `Factuur versturen mislukt`
                });
            });
        }
    }

    public render(): React.ReactNode {
        const { invoiceStore } = this.props;

        const actions = [
            <Button
                variant="secondary"
                key="download-pdf"
                id="download-pdf"
                loading={invoiceStore?.isLoading(`download-invoice-${this.invoice?.uuid}`)}
                disabled={!this.invoice}
                onClick={() => {
                    history.push(`/invoices/${this.invoice?.uuid}/edit`);
                }}
                icon={<FontAwesomeIcon icon={faPencilAlt} />}
            >
                Factuur bewerken
            </Button>,
            <Button
                variant="secondary"
                key="download-pdf"
                id="download-pdf"
                loading={invoiceStore?.isLoading(`download-invoice-${this.invoice?.uuid}`)}
                disabled={!this.invoice}
                onClick={() => {
                    this.downloadInvoice();
                }}
                icon={<FontAwesomeIcon icon={faDownload} />}
            >
                PDF Downloaden
            </Button>
        ];

        if (this.invoice?.status.code === StatusType.CHANGED) {
            actions.push(
                <Button
                    id="send-invoice"
                    disabled={!this.invoice}
                    variant="primary"
                    onClick={() => {
                        this.resendInvoice();
                    }}
                    loading={invoiceStore?.isLoading('resend-invoice')}
                >
                    Factuur opnieuw versturen
                </Button>
            );
        }

        return (
            <Page
                title={`Factuurdetails ${this.invoice
                    ? `van factuur ${this.invoice.invoice_number}`
                    : ''}`}
                actions={actions}
            >
                <Container center className="w-3/4">
                    <UsesCompany
                        onCompanySelected={() => {
                            this.changeCompany();
                        }}
                    >
                        {this.invoice && (
                            <div className="invoice-grid">
                                <Card className="invoice-wrapper">
                                    {this.invoice.company.logo && (
                                        <div className="mb-16">
                                            <img
                                                alt="logo"
                                                src={this.invoice.company.logo}
                                                id="company-logo"
                                                className="company-logo"
                                            />
                                        </div>
                                    )}
                                    <div className="grid grid-cols-3 grid-gap-4 mb-8">
                                        <div className="customer-details">
                                            {/* eslint-disable-next-line max-len */}
                                            <div>{`${this.invoice.customer.firstname} ${this.invoice.customer.lastname}`}</div>
                                            <div>{this.invoice.customer.address}</div>
                                            {/* eslint-disable-next-line max-len */}
                                            <div>{`${this.invoice.customer.address_postcode} ${this.invoice.customer.address_city}`}</div>
                                        </div>
                                        <div />
                                        <div className="company-details">
                                            <strong>{this.invoice.company.name}</strong>
                                            <div>{this.invoice.company.address}</div>
                                            <div className="mb-4">
                                                {/* eslint-disable-next-line max-len */}
                                                {`${this.invoice.company.address_postcode} ${this.invoice.company.address_city}`}
                                            </div>
                                            <div>{`Kvk: ${this.invoice.company.coc_number}`}</div>
                                            <div>{`BTW: ${this.invoice.company.vat_number}`}</div>
                                            <div>{`Bank: ${this.invoice.company.bank_number}`}</div>
                                        </div>
                                    </div>
                                    <div className="grid grid-cols-3 grid-gap-4 mb-16">
                                        <div>
                                            <h2 className="invoice-number">{`Factuur ${this.invoice.invoice_number}`}</h2>
                                        </div>
                                        <div />
                                        <div id="invoice-dates">
                                            <div>
                                                {`Factuurdatum: ${moment(this.invoice.created_at)
                                                    .format('DD-MM-YYYY')}`}
                                            </div>
                                            <div>
                                                {`Vervaldatum: ${moment(this.invoice.due_date)
                                                    .format('DD-MM-YYYY')}`}
                                            </div>
                                        </div>
                                    </div>
                                    <section className="items-section">
                                        <table className="table items-table">
                                            <thead>
                                                <tr>
                                                    <th><strong>Omschrijving</strong></th>
                                                    <th className="text-right"><strong>Aantal</strong></th>
                                                    <th className="text-right"><strong>Prijs</strong></th>
                                                    <th className="text-right"><strong>Totaal</strong></th>
                                                    <th className="text-right"><strong>BTW</strong></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.invoice.items.map((item: IInvoiceItem) => (
                                                    <tr
                                                        key={String(item.price)
                                                        + String(item.quantity) + item.description}
                                                        className="items-table-item"
                                                    >
                                                        <td>{item.description}</td>
                                                        <td className="text-right">{item.quantity}</td>
                                                        <td className="text-right">{`€ ${item.price}`}</td>
                                                        <td className="text-right">{`€ ${item.total}`}</td>
                                                        <td className="text-right">{`${item.vat_rate.percentage}%`}</td>
                                                    </tr>
                                                ))}
                                                <tr>
                                                    <td />
                                                    <td />
                                                    <td className="text-right total">
                                                        <strong>Subtotaal</strong>
                                                    </td>
                                                    <td className="text-right total">
                                                        {`€ ${this.invoice.sub_total}`}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td />
                                                    <td />
                                                    <td className="text-right total">
                                                        <strong>Totaal</strong>
                                                    </td>
                                                    <td className="text-right total">
                                                        {`€ ${this.invoice.total}`}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </section>
                                </Card>
                                <div>
                                    <Card
                                        id="invoice-status-history"
                                        title="Statusgeschiedenis"
                                        className=""
                                    >
                                        {this.invoice.statuses?.map((invoiceStatus: IInvoiceStatus) => (
                                            <>
                                                <div
                                                    key={invoiceStatus.uuid}
                                                    className="flex mb-4 invoice-status-history-item"
                                                >
                                                    <strong>{invoiceStatus.locale}</strong>
                                                    <span className="ml-auto">
                                                        {moment(invoiceStatus.status_since).format('DD-MM-YYYY')}
                                                    </span>
                                                </div>
                                                <Divider />
                                            </>
                                        ))}
                                    </Card>
                                </div>
                            </div>
                        )}
                    </UsesCompany>
                </Container>
            </Page>
        );
    }
}

export default withRouter(InvoiceDetailsPage);
