export default (dataUrl: string): string | undefined => {
    const parts = dataUrl.split(';');
    const mimePart = parts[0].split('/');

    if (mimePart.length > 1) {
        return mimePart[1];
    }

    return undefined;
};
