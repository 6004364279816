export type Errors = Record<string, string[]>;

export class DataInvalidError extends Error {
    private readonly _errors: Errors = {};

    public constructor(message: string, errors: Errors) {
        super(message);

        this._errors = errors;
    }

    public get errors(): Errors {
        return this._errors;
    }
}
