import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import { RouteComponentProps, withRouter } from 'react-router';
import Page from '../../shared/Page';
import UsesCompany from '../../shared/company/UsesCompany';
import Button from '../../shared/Button';
import Container from '../../shared/Container';
import { UserStore } from '../../../app/stores/user/UserStore';
import { FormErrors } from '../../../app/errors/FormErrors';
import { NotFoundError } from '../../../app/errors/NotFoundError';
import { NotificationStore } from '../../../app/stores/notifications/NotificationStore';
import { DataInvalidError } from '../../../app/errors/DataInvalidError';
import CreateOrEditInvoice from '../../shared/invoices/CreateOrEditInvoice';
import { ICompanyDetails } from '../../../app/stores/company/interfaces/ICompanyDetails';
import { CompanyStore } from '../../../app/stores/company/CompanyStore';
import { IUserCompany } from '../../../app/stores/user/interfaces/IUserCompany';
import FormSelect from '../../shared/Forms/Select';
import { IStatus } from '../../../app/stores/statuses/interfaces/IStatus';
import { StatusStore } from '../../../app/stores/statuses/StatusStore';
import { history } from '../../../app/utils/history';
import { Status } from '../../../app/stores/statuses/Status';
import { QuoteStore } from '../../../app/stores/quotes/QuoteStore';
import { IConceptQuote } from '../../../app/stores/quotes/interfaces/IConceptQuote';
import { IQuote } from '../../../app/stores/quotes/interfaces/IQuote';

interface EditQuotePageParams {
    uuid: string;
}

export interface EditQuotePageProps extends RouteComponentProps<EditQuotePageParams> {
    quoteStore?: QuoteStore;
    userStore?: UserStore;
    companyStore?: CompanyStore;
    notificationStore?: NotificationStore;
    statusStore?: StatusStore;
}

@inject('quoteStore', 'userStore', 'notificationStore', 'companyStore', 'statusStore')
@observer
class EditQuotePage extends React.Component<EditQuotePageProps, unknown> {
    @observable private quote?: IConceptQuote;
    @observable private company?: ICompanyDetails;
    @observable private formErrors: FormErrors;
    @observable private statuses: IStatus[] = [];

    public constructor(props: EditQuotePageProps) {
        super(props);

        this.formErrors = new FormErrors();
    }

    public componentDidMount(): void {
        const { userStore, statusStore } = this.props;

        if (userStore?.currentCompany) {
            this.refreshQuote().then(() => {
                statusStore?.getStatuses().then((statuses: IStatus[]) => {
                    this.statuses = statuses;
                    if (this.quote) {
                        statuses.forEach((status: IStatus) => {
                            if (status.code === Status.CONCEPT) {
                                this.quote!.status_uuid = status.uuid;
                            }
                        });
                    }
                });
                this.refreshCompanyDetails(userStore.currentCompany!.company.uuid);
            });
        }
    }

    private setStatus(statuses: IStatus[]): void {
        if (this.quote) {
            statuses.forEach((status: IStatus) => {
                if (status.code === Status.CONCEPT) {
                    this.quote!.status_uuid = status.uuid;
                }
            });
        }
    }

    private refreshStatuses(): void {
        const { statusStore } = this.props;

        statusStore?.getStatuses().then((statuses: IStatus[]) => {
            this.statuses = statuses;
            this.setStatus(statuses);
        });
    }

    private refreshCompanyDetails(companyUuid: string): void {
        const { companyStore } = this.props;

        companyStore?.getCompanyDetails(companyUuid).then(
            (company: ICompanyDetails | undefined) => {
                this.company = company;
            }
        );
    }

    private async refreshQuote(): Promise<void> {
        const {
            quoteStore,
            notificationStore,
            match: {
                params: {
                    uuid
                }
            }
        } = this.props;

        notificationStore?.clear();

        quoteStore?.getConceptQuote(uuid).then((quote: IConceptQuote | undefined) => {
            this.quote = quote;

            if (quote) {
                this.setStatus(this.statuses);
            }
        }).catch((e: NotFoundError | undefined) => {
            notificationStore?.addNotification({
                message: e?.message || 'Offerte niet gevonden',
                messageDetails: e?.details,
                variant: 'danger',
                closable: false
            });
            this.quote = undefined;
        });
    }

    private saveQuote(): void {
        const {
            quoteStore,
            notificationStore,
            match: {
                params: { uuid }
            }
        } = this.props;

        this.formErrors.clear();
        notificationStore?.clear();

        if (this.quote) {
            quoteStore?.saveConceptQuote(
                uuid,
                this.quote
            ).then((quote: IConceptQuote | IQuote | undefined) => {
                if (quote) {
                    if ((quote as IQuote).quote_number) {
                        history.push(`/quotes/${quote.uuid}/details`);
                        return;
                    }

                    const statusUuid = this.quote?.status_uuid;

                    this.quote = {
                        ...quote as IConceptQuote,
                        status_uuid: statusUuid
                    };
                    notificationStore?.addNotification({
                        variant: 'success',
                        message: `Conceptofferte succesvol opgeslagen`
                    });
                }
            }).catch((e: DataInvalidError) => {
                this.formErrors.set(e.errors);
            });
        }
    }

    private sendQuote(): void {
        const {
            quoteStore,
            notificationStore,
            match: {
                params: {
                    uuid
                }
            }
        } = this.props;

        this.formErrors.clear();
        notificationStore?.clear();

        if (this.quote) {
            quoteStore?.sendConceptQuote(
                uuid,
                this.quote
            ).then((quote: IQuote | undefined): void => {
                if (quote) {
                    if ((quote as IQuote).quote_number) {
                        history.push(`/quotes/${quote.uuid}/details`);
                    }

                    notificationStore?.addNotification({
                        variant: 'success',
                        message: 'Offerte succesvol verzonden'
                    });
                }
            }).catch((e: DataInvalidError) => {
                this.formErrors.set(e.errors);
            });
        }
    }

    private changeCompany(companyUuid: string): void {
        this.refreshQuote().then(() => {
            this.refreshStatuses();
            this.refreshCompanyDetails(companyUuid);
        });
    }

    public render(): React.ReactNode {
        const { quoteStore, statusStore } = this.props;

        return (
            <Page
                title="Offerte aanpassen"
                actions={this.quote ? (
                    <>
                        <FormSelect
                            disabled={!this.quote || !this.quote.customer_uuid}
                            className="w-auto mr-4"
                            loading={statusStore?.isLoading('get-statuses')}
                            value={this.quote.status_uuid}
                            name="state_uuid"
                            onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                                this.quote!.status_uuid = event.target.value;
                            }}
                        >
                            {this.statuses.map((status: IStatus) => (
                                <option
                                    value={status.uuid}
                                    key={status.uuid}
                                >
                                    {status.locale}
                                </option>
                            ))}
                        </FormSelect>
                        <Button
                            disabled={!this.quote}
                            id="save-quote"
                            className="mr-4"
                            variant="secondary"
                            onClick={() => {
                                this.saveQuote();
                            }}
                            loading={quoteStore?.isLoading('save-concept-quote')}
                        >
                            Opslaan
                        </Button>
                        <div className="vertical-divider" />
                        <Button
                            id="send-quote"
                            disabled={!this.quote}
                            variant="primary"
                            onClick={() => {
                                this.sendQuote();
                            }}
                            loading={quoteStore?.isLoading('send-concept-quote')}
                        >
                            Offerte versturen
                        </Button>
                    </>
                ) : undefined}
            >
                <Container center className="w-3/4">
                    <UsesCompany
                        onCompanySelected={(userCompany: IUserCompany): void => {
                            this.changeCompany(userCompany.company.uuid);
                        }}
                    >
                        {this.quote && (
                            <CreateOrEditInvoice<IConceptQuote>
                                onInvoiceChanged={(quote: IConceptQuote) => {
                                    this.quote = quote;
                                }}
                                errors={this.formErrors}
                                invoice={this.quote}
                                customer={this.quote.customer}
                                company={this.company}
                            />
                        )}
                    </UsesCompany>
                </Container>
            </Page>
        );
    }
}

export default withRouter(EditQuotePage);
