import { observable } from 'mobx';
import { api } from '../../services/api';
import { ResponseStatus } from '../../services/api/ResponseStatus';
import { IResponse } from '../../services/api/IResponse';
import { ICountry } from './interfaces/ICountry';
import { LoadingStore } from '../loading/LoadingStore';
import { SomethingHappenedError } from '../../errors/SomethingHappenedError';
import { application } from '../../application';
import { ApiError } from '../../errors/ApiError';

export class CountryStore extends LoadingStore {
    @observable private _countries: ICountry[] = [];

    public get countries(): ICountry[] {
        return this._countries;
    }

    public set countries(countries: ICountry[]) {
        this._countries = countries;
    }

    public async getCountries(): Promise<ICountry[] | undefined> {
        if (this.countries.length) {
            return this.countries;
        }

        this.setLoading('get-countries');

        try {
            const response = await api.get<IResponse<ICountry[]>>('/api/countries');

            this.setLoaded('get-countries');

            if (response.status === ResponseStatus.OK) {
                this._countries = response.data.data;

                return this._countries;
            }
        } catch (e) {
            /* istanbul ignore next */
            application.handleError(new ApiError(e));
            /* istanbul ignore next */
            return undefined;
        }

        /* istanbul ignore next */
        application.handleError(new SomethingHappenedError());
        /* istanbul ignore next */
        return undefined;
    }
}

const countryStore = new CountryStore();

export {
    countryStore
};
