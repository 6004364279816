import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import moment from 'moment-timezone';
import Page from '../../shared/Page';
import UsesCompany from '../../shared/company/UsesCompany';
import { UserStore } from '../../../app/stores/user/UserStore';
import { CompanyStore } from '../../../app/stores/company/CompanyStore';
import { IDashboardStats } from '../../../app/stores/company/interfaces/IDashboardStats';
import Card from '../../shared/Card';
import Status from '../../shared/Status';
import { IUserCompany } from '../../../app/stores/user/interfaces/IUserCompany';
import { IExpiredInvoice } from '../../../app/stores/invoices/interfaces/IExpiredInvoice';
import Notification from '../../shared/Notification';
import generateGuid from '../../../app/utils/generateGuid';
import Button from '../../shared/Button';
import { InvoiceStore } from '../../../app/stores/invoices/InvoiceStore';
import { DetailedMessageError } from '../../../app/errors/DetailedMessageError';
import { NotificationStore } from '../../../app/stores/notifications/NotificationStore';
import { PermissionStore } from '../../../app/stores/permissions/PermissionStore';

export interface DashboardPageProps {
    userStore?: UserStore;
    companyStore?: CompanyStore;
    invoiceStore?: InvoiceStore;
    notificationStore?: NotificationStore;
    permissionStore?: PermissionStore;
}

@inject('userStore', 'companyStore', 'invoiceStore', 'notificationStore', 'permissionStore')
@observer
class DashboardPage extends React.Component<DashboardPageProps, unknown> {
    @observable private stats?: IDashboardStats;
    @observable private expiredInvoices: IExpiredInvoice[] = [];

    public componentDidMount() {
        const { userStore } = this.props;

        if (userStore?.currentCompany) {
            this.refreshStats(userStore.currentCompany.company.uuid);
            this.refreshExpiredInvoices(userStore.currentCompany.company.uuid);
        }
    }

    private refreshStats(companyUuid: string): void {
        const { companyStore } = this.props;

        companyStore?.getDashboardStats(
            companyUuid
        ).then((stats: IDashboardStats | undefined): void => {
            this.stats = stats;
        });
    }

    private refreshExpiredInvoices(companyUuid: string): void {
        const { companyStore } = this.props;

        companyStore?.getExpiredInvoices(
            companyUuid
        ).then((expiredInvoices: IExpiredInvoice[]): void => {
            this.expiredInvoices = expiredInvoices;
        });
    }

    private sendReminder(invoice: IExpiredInvoice): void {
        const { invoiceStore, userStore, notificationStore } = this.props;

        invoiceStore?.sendReminder(invoice).then((sent: boolean) => {
            if (sent) {
                notificationStore?.addNotification({
                    variant: 'success',
                    message: 'Herinnering succesvol verstuurd'
                });
                if (userStore?.currentCompany) {
                    this.refreshExpiredInvoices(userStore.currentCompany.company.uuid);
                }
            }
        }).catch((e: DetailedMessageError) => {
            notificationStore?.addNotification({
                variant: 'danger',
                message: e.message,
                messageDetails: e.details
            });
        });
    }

    private renderStats(): React.ReactNode {
        const { companyStore, userStore } = this.props;

        return (
            <div className="grid grid-cols-2 gap-4">
                <div className="mb-4">
                    <Card title="Facturen dit kwartaal" id="invoice-stats">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>{userStore?.currentCompany?.company.name}</th>
                                </tr>
                                <tr>
                                    <th>Status</th>
                                    <th>Bedrag</th>
                                </tr>
                            </thead>
                            <tbody>
                                {companyStore?.isLoading('get-dashboard-stats') ? (
                                    <tr>
                                        <td colSpan={2}>Gegevens worden opgehaald...</td>
                                    </tr>
                                ) : (
                                    <>
                                        {this.stats?.invoices?.status_paid && (
                                            <tr>
                                                <td>
                                                    <Status
                                                        variant="paid"
                                                    >
                                                        Betaald
                                                    </Status>
                                                </td>
                                                <td>
                                                    {`€ ${this.stats.invoices.status_paid}`}
                                                </td>
                                            </tr>
                                        )}
                                        {this.stats?.invoices?.status_sent && (
                                            <tr>
                                                <td>
                                                    <Status
                                                        variant="sent"
                                                    >
                                                        Verzonden
                                                    </Status>
                                                </td>
                                                <td>
                                                    {`€ ${this.stats.invoices.status_sent}`}
                                                </td>
                                            </tr>
                                        )}
                                        {this.stats?.invoices?.status_concept && (
                                            <tr>
                                                <td>
                                                    <Status
                                                        variant="concept"
                                                    >
                                                        Concept
                                                    </Status>
                                                </td>
                                                <td>
                                                    {`€ ${this.stats.invoices.status_concept}`}
                                                </td>
                                            </tr>
                                        )}
                                    </>
                                )}
                            </tbody>
                        </table>
                    </Card>
                </div>
                <div className="mb-4">
                    <Card title="Offertes dit kwartaal" id="quote-stats">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>{userStore?.currentCompany?.company.name}</th>
                                </tr>
                                <tr>
                                    <th>Status</th>
                                    <th>Bedrag</th>
                                </tr>
                            </thead>
                            <tbody>
                                {companyStore?.isLoading('get-dashboard-stats') ? (
                                    <tr>
                                        <td colSpan={2}>Gegevens worden opgehaald...</td>
                                    </tr>
                                ) : (
                                    <>
                                        {this.stats?.quotes?.status_sent && (
                                            <tr>
                                                <td>
                                                    <Status
                                                        variant="sent"
                                                    >
                                                        Verzonden
                                                    </Status>
                                                </td>
                                                <td>
                                                    {`€ ${this.stats.quotes.status_sent}`}
                                                </td>
                                            </tr>
                                        )}
                                        {this.stats?.quotes?.status_concept && (
                                            <tr>
                                                <td>
                                                    <Status
                                                        variant="concept"
                                                    >
                                                        Concept
                                                    </Status>
                                                </td>
                                                <td>
                                                    {`€ ${this.stats.quotes.status_concept}`}
                                                </td>
                                            </tr>
                                        )}
                                    </>
                                )}
                            </tbody>
                        </table>
                    </Card>
                </div>
            </div>
        );
    }

    private renderExpiredInvoices(): React.ReactNode {
        const {
            companyStore,
            invoiceStore,
            permissionStore,
            userStore
        } = this.props;

        if (companyStore?.isInitialOrLoading('get-expired-invoices')) {
            return 'Gegevens worden opgehaald...';
        }

        if (companyStore?.isLoaded('get-expired-invoices') && !this.expiredInvoices.length) {
            const companyName = userStore?.currentCompany?.company.name;
            return (
                <Notification
                    id={generateGuid()}
                    variant="info"
                    message={`Er zijn geen verlopen facturen voor ${companyName}`}
                />
            );
        }

        return (
            <table className="table" id="expired-invoices">
                <thead>
                    <tr>
                        <th>{userStore?.currentCompany?.company.name}</th>
                    </tr>
                    <tr>
                        <th>Factuurnummer</th>
                        <th>Verlopen op</th>
                        {permissionStore?.canOneOf(['send_invoice_reminder']) && (
                            // eslint-disable-next-line jsx-a11y/control-has-associated-label
                            <th />
                        )}
                    </tr>
                </thead>
                <tbody>
                    {this.expiredInvoices.map((expiredInvoice: IExpiredInvoice) => (
                        <tr key={expiredInvoice.uuid}>
                            <td>{expiredInvoice.invoice_number}</td>
                            <td>{moment(expiredInvoice.due_date).format('DD-MM-YYYY')}</td>
                            {permissionStore?.can('send_invoice_reminder') && (
                                <td>
                                    <Button
                                        className="send-reminder"
                                        loading={invoiceStore?.isLoading(`send-reminder-${expiredInvoice.uuid}`)}
                                        variant="danger"
                                        onClick={() => {
                                            this.sendReminder(expiredInvoice);
                                        }}
                                    >
                                        Herinnering sturen
                                    </Button>
                                </td>
                            )}
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    }

    public render(): React.ReactNode {
        const { permissionStore } = this.props;

        return (
            <Page title="Dashboard">
                <UsesCompany
                    onCompanySelected={(userCompany: IUserCompany) => {
                        this.refreshStats(userCompany.company.uuid);
                        this.refreshExpiredInvoices(userCompany.company.uuid);
                    }}
                >
                    {this.renderStats()}
                    {permissionStore?.can('overview_invoices') && (
                        <div className="grid grid-cols-2 gap-4">
                            <div className="mb-4">
                                <Card title="Verlopen facturen">
                                    {this.renderExpiredInvoices()}
                                </Card>
                            </div>
                        </div>
                    )}
                </UsesCompany>
            </Page>
        );
    }
}

export default DashboardPage;
