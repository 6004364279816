import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import config from '../../../config';
import { authStore } from '../../stores/auth/AuthStore';
import { ResponseStatus } from './ResponseStatus';
import { notificationStore } from '../../stores/notifications/NotificationStore';
import { NotificationType } from '../../stores/notifications/interfaces/INotification';

const api: AxiosInstance = axios.create({
    baseURL: config.api.url,
    withCredentials: false
});

api.interceptors.request.use((request: AxiosRequestConfig) => {
    if (authStore.token) {
        request.headers.Authorization = `Bearer ${authStore.token}`;
    }

    return request;
});

api.interceptors.response.use((response: AxiosResponse) => response, (error: any): Promise<void> => {
    if (error?.response?.status === ResponseStatus.ERROR) {
        notificationStore!.addNotification({
            variant: 'danger',
            message: 'Er is aan onze kant iets misgegaan. We proberen het zo snel mogelijk op te lossen.',
            once: true,
            type: NotificationType.BACKEND_ERROR
        });
    } else if (error?.response?.status === ResponseStatus.INVALID_PERMISSIONS) {
        notificationStore!.addNotification({
            variant: 'danger',
            message: 'U heeft niet de juiste permissies voor deze handeling.',
            once: true,
            type: NotificationType.PERMISSIONS_ERROR
        });
    }

    return Promise.reject(error);
});

export {
    api
};
