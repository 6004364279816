import * as React from 'react';
import { Router, Switch } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import { inject, observer } from 'mobx-react';
import { routes } from '../app/utils/routes';
import { history } from '../app/utils/history';
import { Application } from '../app/application';

export interface AppProps {
    application?: Application;
}

@inject('application')
@observer
class App extends React.Component<AppProps, unknown> {
    public componentDidMount(): void {
        const { application } = this.props;

        application?.initialize();
    }

    public render(): React.ReactNode {
        const { application } = this.props;

        return application?.loading ? 'Aan het laden' : (
            <Router history={history}>
                <Switch>
                    {renderRoutes(routes)}
                </Switch>
            </Router>
        );
    }
}

export default App;
