import { computed, observable } from 'mobx';
import { LoadingStore } from '../loading/LoadingStore';
import { api } from '../../services/api';
import { IResponse } from '../../services/api/IResponse';
import { IErrorResponse } from '../../services/api/IErrorResponse';
import { ILoginUserResponse } from './interfaces/ILoginUserResponse';
import { ResponseStatus } from '../../services/api/ResponseStatus';
import { DataInvalidError } from '../../errors/DataInvalidError';
import { InvalidCredentialsError } from './errors/InvalidCredentialsException';
import { permissionStore } from '../permissions/PermissionStore';
import { IUser } from './interfaces/IUser';
import { NotLoggedInError } from './errors/NotLoggedInError';
import { application } from '../../application';
import { SomethingHappenedError } from '../../errors/SomethingHappenedError';
import { IDisposable } from '../../interfaces/IDisposable';

export class AuthStore extends LoadingStore implements IDisposable {
    @observable private _user?: IUser;
    public static TOKEN_KEY = 't';

    public set user(user: IUser | undefined) {
        this._user = user;
    }

    @computed
    public get user(): IUser | undefined {
        return this._user;
    }

    public get token(): string | null {
        return localStorage.getItem(AuthStore.TOKEN_KEY);
    }

    public loggedIn(): boolean {
        return !!localStorage.getItem(AuthStore.TOKEN_KEY);
    }

    public async login(email: string, password: string): Promise<void> {
        this.setLoading('login');

        await api.get('sanctum/csrf-cookie');

        try {
            const response = await api.post<IResponse<ILoginUserResponse> | IErrorResponse>('/api/login', {
                email,
                password
            });

            this.setLoaded('login');

            /* istanbul ignore else */
            if (response.status === ResponseStatus.OK) {
                localStorage.setItem(
                    AuthStore.TOKEN_KEY, (response.data as IResponse<ILoginUserResponse>).data.token
                );
                this._user = (response.data as IResponse<ILoginUserResponse>).data.user;
                permissionStore.permissions = (response.data as IResponse<ILoginUserResponse>).data.user.abilities;
                return;
            }
        } catch (e) {
            if (e.response.status === ResponseStatus.INVALID) {
                throw new DataInvalidError(
                    (e.response.data as IErrorResponse).message,
                    (e.response.data as IErrorResponse).errors!
                );
            }

            /* istanbul ignore else */
            if (e.response.status === ResponseStatus.INVALID_CREDENTIALS) {
                throw new InvalidCredentialsError((e.response.data as IErrorResponse).message);
            }
        }

        /* istanbul ignore next */
        application.handleError(new SomethingHappenedError());
    }

    public async logout(): Promise<void> {
        try {
            await api.post('/api/logout');
        } catch (e) {
            //
        } finally {
            application.dispose();
        }
    }

    public async getAuthenticatedUser(): Promise<void> {
        if (this.token) {
            try {
                const response = await api.get<IResponse<IUser>>('/api/authenticated-user');

                this._user = response.data.data;
                permissionStore.permissions = response.data.data.abilities;
            } catch (e) {
                throw new NotLoggedInError();
            }
        }
    }

    public dispose(): void {
        this._user = undefined;
        localStorage.removeItem(AuthStore.TOKEN_KEY);
    }
}

const authStore = new AuthStore();

export {
    authStore
};
