import * as React from 'react';
import clsx from 'clsx';
import { useState } from 'react';
import generateGuid from '../../../app/utils/generateGuid';

export interface FormSelectProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
    hasError?: boolean;
    loading?: boolean;
}

const FormSelect = React.forwardRef<HTMLSelectElement, FormSelectProps>((
    {
        children,
        hasError,
        className,
        loading,
        ...rest
    },
    ref
): React.ReactElement => {
    const [id] = useState(generateGuid());
    const [hasFocus, setFocus] = useState(false);

    return (
        <label
            htmlFor={id}
            className={clsx(
                'form-control-container',
                hasError && 'has-error',
                hasFocus && 'has-focus',
                className
            )}
        >
            <select
                ref={ref}
                className={clsx(
                    'form-control',
                    hasError && 'has-error',
                    loading && 'form-control-loading',
                    className
                )}
                onFocus={() => {
                    setFocus(true);
                }}
                onBlur={() => {
                    setFocus(false);
                }}
                disabled={loading}
                {...rest}
            >
                {children}
            </select>
        </label>
    );
});

FormSelect.displayName = 'FormSelect';

export default FormSelect;
