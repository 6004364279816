import { observable } from 'mobx';

export enum LoadingState {
    INITIAL = 'INITIAL',
    LOADING = 'LOADING',
    LOADED = 'LOADED'
}

export class LoadingStore {
    @observable private _loading: Record<string, LoadingState> = {};

    public setLoadingState(key: string, state: LoadingState): void {
        this._loading[key] = state;
    }

    public setLoading(key: string): void {
        this.setLoadingState(key, LoadingState.LOADING);
    }

    public setLoaded(key: string): void {
        this.setLoadingState(key, LoadingState.LOADED);
    }

    public isInitialOrLoading(key: string): boolean {
        return !Object.prototype.hasOwnProperty.call(this._loading, key)
            || [LoadingState.INITIAL, LoadingState.LOADING].indexOf(this._loading[key]) !== -1;
    }

    public isLoading(key: string): boolean {
        return this._loading[key] === LoadingState.LOADING;
    }

    public isLoaded(key: string): boolean {
        return this._loading[key] === LoadingState.LOADED;
    }
}
