import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import Page from '../../shared/Page';
import UsesCompany from '../../shared/company/UsesCompany';
import CreateOrEditInvoice from '../../shared/invoices/CreateOrEditInvoice';
import Button from '../../shared/Button';
import Container from '../../shared/Container';
import { IUserCompany } from '../../../app/stores/user/interfaces/IUserCompany';
import { UserStore } from '../../../app/stores/user/UserStore';
import { FormErrors } from '../../../app/errors/FormErrors';
import { DataInvalidError } from '../../../app/errors/DataInvalidError';
import { history } from '../../../app/utils/history';
import { NotificationStore } from '../../../app/stores/notifications/NotificationStore';
import { ICustomer } from '../../../app/stores/customers/interfaces/ICustomer';
import { CompanyStore } from '../../../app/stores/company/CompanyStore';
import FormSelect from '../../shared/Forms/Select';
import { ICompanyDetails } from '../../../app/stores/company/interfaces/ICompanyDetails';
import { StatusStore } from '../../../app/stores/statuses/StatusStore';
import { IStatus } from '../../../app/stores/statuses/interfaces/IStatus';
import { Status } from '../../../app/stores/statuses/Status';
import { QuoteStore } from '../../../app/stores/quotes/QuoteStore';
import { INewConceptQuote } from '../../../app/stores/quotes/interfaces/INewConceptQuote';
import { IConceptQuote } from '../../../app/stores/quotes/interfaces/IConceptQuote';
import { IQuote } from '../../../app/stores/quotes/interfaces/IQuote';
import CustomerSearcher from '../../shared/search/CustomerSearcher';

export interface CreateQuotePageProps {
    quoteStore?: QuoteStore;
    userStore?: UserStore;
    companyStore?: CompanyStore;
    notificationStore?: NotificationStore;
    statusStore?: StatusStore;
}

@inject('quoteStore', 'userStore', 'notificationStore', 'companyStore', 'statusStore')
@observer
class CreateQuotePage extends React.Component<CreateQuotePageProps, unknown> {
    private defaultQuoteValues: INewConceptQuote = {
        status_uuid: '',
        company_uuid: '',
        customer_uuid: '',
        items_include_vat: false,
        items: []
    }

    @observable private quote: INewConceptQuote = this.defaultQuoteValues;
    @observable private readonly formErrors: FormErrors;
    @observable private customers?: ICustomer[];
    @observable private customer?: ICustomer;
    @observable private company?: ICompanyDetails;
    @observable private statuses: IStatus[] = [];

    public constructor(props: CreateQuotePageProps) {
        super(props);

        this.formErrors = new FormErrors();
    }

    public componentDidMount(): void {
        const { userStore, statusStore } = this.props;

        statusStore?.getStatuses().then((statuses: IStatus[]) => {
            this.statuses = statuses;

            statuses.forEach((status: IStatus) => {
                if (status.code === Status.CONCEPT) {
                    this.quote.status_uuid = status.uuid;
                }
            });
        });

        if (userStore?.currentCompany) {
            this.defaultQuoteValues.company_uuid = userStore.currentCompany.company.uuid;
            this.quote.company_uuid = userStore.currentCompany.company.uuid;

            this.refreshCompanyDetails(userStore.currentCompany.company.uuid);
        }
    }

    private refreshCompanyDetails(companyUuid: string): void {
        const { companyStore } = this.props;

        companyStore?.getCompanyDetails(companyUuid).then(
            (company: ICompanyDetails | undefined) => {
                this.company = company;
            }
        );
    }

    private saveQuote(): void {
        const { quoteStore, notificationStore } = this.props;

        this.formErrors.clear();
        notificationStore?.clear();

        quoteStore?.createQuote(this.quote).then((quote: IConceptQuote | IQuote | undefined) => {
            if (quote) {
                if ((quote as IQuote).quote_number) {
                    history.push(`/quotes/${quote.uuid}/details`);
                    return;
                }

                history.push(`/quotes/${quote.uuid}/edit`);
                notificationStore?.addNotification({
                    variant: 'success',
                    message: `Conceptofferte succesvol opgeslagen`
                });
            }
        }).catch((e: DataInvalidError) => {
            this.formErrors.set(e.errors);
        });
    }

    private sendQuote(): void {
        const {
            quoteStore,
            notificationStore
        } = this.props;

        this.formErrors.clear();
        notificationStore!.clear();

        if (this.quote) {
            quoteStore?.sendQuote(this.quote).then((quote: IQuote | undefined): void => {
                if (quote) {
                    notificationStore?.addNotification({
                        variant: 'success',
                        message: `Offerte succesvol aangemaakt`
                    });
                }
            }).catch((e: DataInvalidError) => {
                this.formErrors.set(e.errors);
            });
        }
    }

    private changeCustomer(customer: ICustomer): void {
        this.customer = customer;
        this.quote.customer_uuid = customer.uuid;
    }

    private changeCompany(companyUuid: string): void {
        this.defaultQuoteValues.company_uuid = companyUuid;
        this.quote.company_uuid = companyUuid;

        this.refreshCompanyDetails(companyUuid);
    }

    private renderCustomerSelector(): React.ReactElement {
        return (
            <CustomerSearcher
                className="mb-4 invoice-width mx-auto"
                companyUuid={this.defaultQuoteValues.company_uuid}
                onCustomerSelected={(customer: ICustomer) => {
                    this.changeCustomer(customer);
                }}
            />
        );
    }

    public render(): React.ReactNode {
        const { quoteStore, statusStore } = this.props;

        return (
            <Page
                title="Offerte maken"
                actions={(
                    <>
                        <FormSelect
                            disabled={!this.quote || !this.quote.customer_uuid}
                            className="w-auto mr-4"
                            name="state_uuid"
                            loading={statusStore?.isLoading('get-statuses')}
                            value={this.quote.status_uuid}
                            onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                                this.quote.status_uuid = event.target.value;
                            }}
                        >
                            {this.statuses.map((status: IStatus) => (
                                <option
                                    value={status.uuid}
                                    key={status.uuid}
                                >
                                    {status.locale}
                                </option>
                            ))}
                        </FormSelect>
                        <Button
                            id="save-quote"
                            disabled={!this.quote || !this.quote.customer_uuid}
                            className="mr-4"
                            variant="secondary"
                            onClick={() => {
                                this.saveQuote();
                            }}
                            loading={quoteStore?.isLoading('save-concept-quote')}
                        >
                            Opslaan
                        </Button>
                        <div className="vertical-divider" />
                        <Button
                            id="send-quote"
                            disabled={!this.quote || !this.quote.customer_uuid}
                            variant="primary"
                            onClick={() => {
                                this.sendQuote();
                            }}
                            loading={quoteStore?.isLoading('send-quote')}
                        >
                            Offerte versturen
                        </Button>
                    </>
                )}
            >
                <Container center className="w-3/4">
                    <UsesCompany
                        onCompanySelected={(userCompany: IUserCompany) => {
                            this.changeCompany(userCompany.company.uuid);
                        }}
                    >
                        {this.renderCustomerSelector()}
                        <CreateOrEditInvoice<INewConceptQuote>
                            invoice={this.quote}
                            asQuote
                            customer={this.customer}
                            company={this.company}
                            onInvoiceChanged={(quote: INewConceptQuote) => {
                                this.quote = quote;
                            }}
                            errors={this.formErrors}
                        />
                    </UsesCompany>
                </Container>
            </Page>
        );
    }
}

export default CreateQuotePage;
