export class ApiError extends Error {
    private _previous?: Error;

    constructor(previous?: Error, message?: string) {
        super(message);

        this._previous = previous;
    }

    public get previous(): Error | undefined {
        return this._previous;
    }
}
