import moment from 'moment-timezone';
import * as React from 'react';
import { LoadingStore } from '../loading/LoadingStore';
import { INewConceptInvoice } from './interfaces/INewConceptInvoice';
import { api } from '../../services/api';
import { ResponseStatus } from '../../services/api/ResponseStatus';
import { DataInvalidError } from '../../errors/DataInvalidError';
import { IResponse } from '../../services/api/IResponse';
import { IConceptInvoice } from './interfaces/IConceptInvoice';
import { IErrorResponse } from '../../services/api/IErrorResponse';
import { application } from '../../application';
import { ApiError } from '../../errors/ApiError';
import { SomethingHappenedError } from '../../errors/SomethingHappenedError';
import { NotFoundError } from '../../errors/NotFoundError';
import { ICalculation } from './interfaces/ICalculation';
import { IInvoice } from './interfaces/IInvoice';
import Button from '../../../components/shared/Button';
import intersperse from '../../utils/intersperse';
import { IConceptInvoiceItem } from './interfaces/IConceptInvoiceItem';
import { IExpiredInvoice } from './interfaces/IExpiredInvoice';
import { DetailedMessageError } from '../../errors/DetailedMessageError';

export class InvoiceStore extends LoadingStore {
    public static DOCUMENT_NUMBER_VARIABLES: Record<string, { name: string, value: string }> = {
        number: {
            name: 'Documentnummer',
            value: '1'
        },
        year: {
            name: 'Jaar (4 getallen)',
            value: moment()
                .format('YYYY')
        },
        short_year: {
            name: 'Jaar (2 getallen)',
            value: moment()
                .format('YY')
        },
        month: {
            name: 'Maand',
            value: moment()
                .format('MM')
        },
        day: {
            name: 'Dag',
            value: moment()
                .format('DD')
        }
    };

    public async createInvoice(invoice: INewConceptInvoice): Promise<IConceptInvoice | IInvoice | undefined> {
        this.setLoading('create-concept-invoice');

        try {
            const response = await api.post<IResponse<IConceptInvoice> | IErrorResponse>('/api/invoices', invoice);

            /* istanbul ignore else */
            if (response.status === ResponseStatus.CREATED) {
                return (response.data as IResponse<IConceptInvoice>).data;
            }
        } catch (e) {
            /* istanbul ignore else */
            if (e.response.status === ResponseStatus.INVALID) {
                throw new DataInvalidError(
                    (e.response.data as IErrorResponse).message,
                    (e.response.data as IErrorResponse).errors!
                );
            }

            /* istanbul ignore next */
            application.handleError(new ApiError());
            /* istanbul ignore next */
            return undefined;
        } finally {
            this.setLoaded('create-concept-invoice');
        }

        /* istanbul ignore next */
        application.handleError(new SomethingHappenedError());

        /* istanbul ignore next */
        return undefined;
    }

    public async getConceptInvoice(uuid: string): Promise<IConceptInvoice | undefined> {
        this.setLoading('get-concept-invoice');

        try {
            const response = await api.get<IResponse<IConceptInvoice> | IErrorResponse>(
                `/api/invoices/concept/${uuid}`
            );

            /* istanbul ignore else */
            if (response.status === ResponseStatus.OK) {
                return (response.data as IResponse<IConceptInvoice>).data;
            }
        } catch (e) {
            switch (e.response.status) {
                case ResponseStatus.NOT_FOUND:
                    throw new NotFoundError(
                        (e.response.data as IErrorResponse).message,
                        (e.response.data as IErrorResponse).details
                    );
                default:
                    /* istanbul ignore next */
                    application.handleError(new ApiError());
                    /* istanbul ignore next */
                    return undefined;
            }
        }

        /* istanbul ignore next */
        application.handleError(new SomethingHappenedError());
        /* istanbul ignore next */
        return undefined;
    }

    public async updateInvoice(
        uuid: string,
        values: IInvoice
    ): Promise<IConceptInvoice | IInvoice | undefined> {
        this.setLoading('update-invoice');

        console.log(values);
        try {
            const response = await api.put<IResponse<IInvoice>>(
                `/api/invoices/${uuid}`,
                values
            );

            if (response.status === ResponseStatus.OK || response.status === ResponseStatus.CREATED) {
                return (response.data as IResponse<IInvoice>).data;
            }
        } catch (e) {
            if (e.response.status === ResponseStatus.INVALID) {
                throw new DataInvalidError(
                    (e.response.data as IErrorResponse).message,
                    (e.response.data as IErrorResponse).errors!
                );
            }
        } finally {
            this.setLoaded('update-invoice');
        }

        /* istanbul ignore next */
        application.handleError(new SomethingHappenedError());
        /* istanbul ignore next */
        return undefined;
    }

    public async saveConceptInvoice(
        uuid: string,
        values: IConceptInvoice
    ): Promise<IConceptInvoice | IInvoice | undefined> {
        this.setLoading('save-concept-invoice');

        try {
            const response = await api.put<IResponse<IConceptInvoice>>(
                `/api/invoices/concept/${uuid}`,
                values
            );

            if (response.status === ResponseStatus.OK || response.status === ResponseStatus.CREATED) {
                return (response.data as IResponse<IConceptInvoice>).data;
            }
        } catch (e) {
            if (e.response.status === ResponseStatus.INVALID) {
                throw new DataInvalidError(
                    (e.response.data as IErrorResponse).message,
                    (e.response.data as IErrorResponse).errors!
                );
            }
        } finally {
            this.setLoaded('save-concept-invoice');
        }

        /* istanbul ignore next */
        application.handleError(new SomethingHappenedError());
        /* istanbul ignore next */
        return undefined;
    }

    public async calculateTotals(items: IConceptInvoiceItem[], itemsIncludeVat: boolean): Promise<ICalculation> {
        this.setLoading('calculate-totals');

        try {
            const response = await api.post<IResponse<ICalculation> | IErrorResponse>(
                '/api/invoices/calculate',
                {
                    items,
                    items_include_vat: itemsIncludeVat
                }
            );

            if (response.status === ResponseStatus.OK) {
                return (response.data as IResponse<ICalculation>).data;
            }
        } catch (e) {
            //
        } finally {
            this.setLoaded('calculate-totals');
        }

        return {
            sub_total: '0.00',
            total: '0.00'
        };
    }

    public async sendInvoice(values: INewConceptInvoice): Promise<IInvoice | undefined> {
        this.setLoading('send-invoice');

        try {
            const response = await api.post<IResponse<IInvoice> | IErrorResponse>(
                '/api/invoices/send',
                values
            );

            /* istanbul ignore else */
            if (response.status === ResponseStatus.CREATED) {
                return (response.data as IResponse<IInvoice>).data;
            }
        } catch (e) {
            /* istanbul ignore else */
            if (e.response.status === ResponseStatus.INVALID) {
                throw new DataInvalidError(
                    (e.response.data as IErrorResponse).message,
                    (e.response.data as IErrorResponse).errors!
                );
            }
        } finally {
            this.setLoaded('send-invoice');
        }

        /* istanbul ignore next */
        application.handleError(new SomethingHappenedError());

        /* istanbul ignore next */
        return undefined;
    }

    public async resendInvoice(invoiceUuid: string): Promise<boolean> {
        this.setLoading('resend-invoice');

        try {
            const response = await api.get<IResponse<IInvoice> | IErrorResponse>(
                `/api/invoices/${invoiceUuid}/resend`,
            );

            /* istanbul ignore else */
            if (response.status === ResponseStatus.OK) {
                this.setLoaded('resend-invoice');
                return true;
            }
        } catch (e) {
            /* istanbul ignore else */
            this.setLoaded('resend-invoice');
            return false;
        }

        return false;
    }

    public async sendConceptInvoice(
        conceptInvoiceUuid: string,
        values: IConceptInvoice
    ): Promise<IInvoice | undefined> {
        this.setLoading('send-concept-invoice');

        try {
            const response = await api.post<IResponse<IInvoice> | IErrorResponse>(
                `/api/invoices/concept/${conceptInvoiceUuid}/send`,
                values
            );

            /* istanbul ignore else */
            if (response.status === ResponseStatus.CREATED) {
                return (response.data as IResponse<IInvoice>).data;
            }
        } catch (e) {
            /* istanbul ignore else */
            if (e.response.status === ResponseStatus.INVALID) {
                throw new DataInvalidError(
                    (e.response.data as IErrorResponse).message,
                    (e.response.data as IErrorResponse).errors!
                );
            }
        } finally {
            this.setLoaded('send-concept-invoice');
        }

        /* istanbul ignore next */
        application.handleError(new SomethingHappenedError());

        /* istanbul ignore next */
        return undefined;
    }

    public async getInvoice(invoiceUuid: string): Promise<IInvoice | undefined> {
        this.setLoading('get-invoice-details');

        try {
            const response = await api.get<IResponse<IInvoice>>(`api/invoices/${invoiceUuid}`);

            if (response.status === ResponseStatus.OK) {
                return response.data.data;
            }
        } catch (e) {
            if (e.response.status === ResponseStatus.NOT_FOUND) {
                switch (e.response.status) {
                    case ResponseStatus.NOT_FOUND:
                        throw new NotFoundError(
                            (e.response.data as IErrorResponse).message,
                            (e.response.data as IErrorResponse).details
                        );
                    default:
                        /* istanbul ignore next */
                        application.handleError(new ApiError());
                        /* istanbul ignore next */
                        return undefined;
                }
            }
        } finally {
            this.setLoaded('get-invoice-details');
        }

        /* istanbul ignore next */
        application.handleError(new SomethingHappenedError());
        /* istanbul ignore next */
        return undefined;
    }

    public getInvoiceNumberExample(format: string): string {
        Object.keys(InvoiceStore.DOCUMENT_NUMBER_VARIABLES)
            .forEach((key: string) => {
                if (format.indexOf(`{${key}}`) !== -1) {
                    format = format.replace(`{${key}}`, InvoiceStore.DOCUMENT_NUMBER_VARIABLES[key].value);
                }
            });

        return format;
    }

    public renderDocumentNumberVariables(callback: (clickedKey: string) => void): React.ReactNode {
        const elements = Object.keys(InvoiceStore.DOCUMENT_NUMBER_VARIABLES).map((key: string) => (
            <Button
                key={key}
                variant="link"
                href="#"
                className={`document-number-variable-${key}`}
                onClick={() => {
                    callback(`{${key}}`);
                }}
            >
                {InvoiceStore.DOCUMENT_NUMBER_VARIABLES[key].name}
            </Button>
        ));

        return intersperse<React.ReactNode>(elements, () => ', ');
    }

    public async sendReminder(invoice: IExpiredInvoice): Promise<boolean> {
        this.setLoading(`send-reminder-${invoice.uuid}`);

        try {
            const response = await api.post<unknown | IErrorResponse>(`/api/invoices/${invoice.uuid}/remind`);

            if (response.status === ResponseStatus.EMPTY) {
                return true;
            }
        } catch (e) {
            if (e.response?.status === ResponseStatus.INVALID) {
                throw new DetailedMessageError(
                    (e.response.data as IErrorResponse).message,
                    (e.response.data as IErrorResponse).details!
                );
            }

            if (e.response?.status === ResponseStatus.NOT_FOUND) {
                throw new NotFoundError(
                    'Factuur niet gevonden'
                );
            }
            /* istanbul ignore next */
            application.handleError(new ApiError(e));
            /* istanbul ignore next */
            return false;
        } finally {
            this.setLoaded(`send-reminder-${invoice.uuid}`);
        }

        /* istanbul ignore next */
        application.handleError(new SomethingHappenedError());
        /* istanbul ignore next */
        return false;
    }

    public async downloadInvoice(invoiceUuid: string, name: string): Promise<void> {
        this.setLoading(`download-invoice-${invoiceUuid}`);

        try {
            const response = await api.get(`api/invoices/${invoiceUuid}/download`, {
                responseType: 'blob'
            });

            if (response.status === ResponseStatus.OK) {
                const path = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = path;
                link.download = `${name}.pdf`;
                link.click();
                return;
            }
        } catch (e) {
            application.handleError(new ApiError(e));
            return;
        } finally {
            this.setLoaded(`download-invoice-${invoiceUuid}`);
        }

        application.handleError(new SomethingHappenedError());
    }

    public async createCreditInvoice(invoiceUuid: string): Promise<IConceptInvoice | undefined> {
        this.setLoading(`create-credit-invoice-${invoiceUuid}`);

        try {
            const response = await api.post<IResponse<IConceptInvoice>>(`/api/invoices/${invoiceUuid}/credit`);

            if (response.status === ResponseStatus.CREATED) {
                return response.data.data;
            }
        } catch (e) {
            application.handleError(new ApiError(e));

            return undefined;
        } finally {
            this.setLoaded(`create-credit-invoice-${invoiceUuid}`);
        }

        application.handleError(new SomethingHappenedError());

        return undefined;
    }

    public async copyInvoice(invoiceUuid: string): Promise<IConceptInvoice | undefined> {
        this.setLoading(`copy-invoice-${invoiceUuid}`);

        try {
            const response = await api.post<IResponse<IConceptInvoice>>(`/api/invoices/${invoiceUuid}/copy`);

            if (response.status === ResponseStatus.CREATED) {
                return response.data.data;
            }
        } catch (e) {
            application.handleError(new ApiError(e));

            return undefined;
        } finally {
            this.setLoaded(`copy-invoice-${invoiceUuid}`);
        }

        application.handleError(new SomethingHappenedError());

        return undefined;
    }
}

const invoiceStore = new InvoiceStore();

export {
    invoiceStore
};
