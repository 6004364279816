import * as React from 'react';
import clsx from 'clsx';

export interface ContainerProps extends React.HTMLAttributes<HTMLDivElement> {
    center?: boolean;
    sm?: boolean;
}

const Container = React.forwardRef<HTMLDivElement, ContainerProps>((
    {
        center,
        children,
        className,
        sm
    },
    ref
): React.ReactElement => (
    <div
        className={clsx(
            center && 'mx-auto',
            sm && 'sm:container',
            className
        )}
        ref={ref}
    >
        {children}
    </div>
));

Container.displayName = 'Container';

export default Container;
