import * as React from 'react';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faBuilding, faCog,
    faCreditCard,
    faFileInvoice,
    faHome, faMoneyBillWave,
    faPercent,
    faPlus,
    faSignOutAlt, faSync, faUser,
    faUserFriends
} from '@fortawesome/free-solid-svg-icons';
import { inject, observer } from 'mobx-react';
import PageNavigationItem from './PageNavigationItem';
import Button from '../Button';
import { AuthStore } from '../../../app/stores/auth/AuthStore';
import { permissionStore, PermissionStore } from '../../../app/stores/permissions/PermissionStore';
import CompanySwitcher from '../company/CompanySelector';
import { UserStore } from '../../../app/stores/user/UserStore';
import { history } from '../../../app/utils/history';

export interface PageNavigationProps extends React.HTMLAttributes<HTMLDivElement> {
    authStore?: AuthStore;
    permissionStore?: PermissionStore;
    userStore?: UserStore;
}

@inject('authStore', 'permissionStore', 'userStore')
@observer
export default class PageNavigation extends React.Component<PageNavigationProps, unknown> {
    public render(): React.ReactNode {
        const { authStore, userStore } = this.props;

        return (
            <div className={clsx('page-navigation')}>
                <div className="mb-4">
                    {userStore?.currentCompany?.company.logo && (
                        <div className="flex justify-center">
                            <img
                                src={userStore.currentCompany.company.logo}
                                alt="Company Logo"
                                id="main-logo"
                                className="company-logo"
                            />
                        </div>
                    )}
                </div>
                <CompanySwitcher />
                <div className="page-navigation-divider" />
                <div className="page-navigation-list">
                    <PageNavigationItem
                        title="Dashboard"
                        path="/dashboard"
                        icon={<FontAwesomeIcon icon={faHome} />}
                    />
                    {permissionStore.canOneOf(['create_company']) && (
                        <PageNavigationItem title="Bedrijven">
                            <PageNavigationItem
                                title="Bedrijf toevoegen"
                                path="/company/create"
                                icon={(
                                    <div className="fa-layers fa-fw">
                                        <FontAwesomeIcon icon={faPlus} transform="shrink-4 right-12" />
                                        <FontAwesomeIcon icon={faBuilding} />
                                    </div>
                                )}
                            />
                        </PageNavigationItem>
                    )}
                    {permissionStore.canOneOf(['list_customers', 'create_customer']) && (
                        <PageNavigationItem title="Klanten">
                            <PageNavigationItem
                                title="Overzicht"
                                path="/customers"
                                icon={<FontAwesomeIcon icon={faUserFriends} />}
                            />
                            <PageNavigationItem
                                title="Klant toevoegen"
                                path="/customer/create"
                                icon={(
                                    <div className="fa-layers fa-fw">
                                        <FontAwesomeIcon icon={faPlus} transform="shrink-4 right-16" />
                                        <FontAwesomeIcon icon={faUserFriends} />
                                    </div>
                                )}
                            />
                        </PageNavigationItem>
                    )}
                    {permissionStore.canOneOf(['create_invoice', 'list_invoices']) && (
                        <PageNavigationItem title="Facturen">
                            <PageNavigationItem
                                exact
                                title="Overzicht"
                                path="/invoices"
                                icon={(
                                    <FontAwesomeIcon icon={faFileInvoice} />
                                )}
                            />
                            <PageNavigationItem
                                exact
                                title="Factuur maken"
                                path="/invoices/create"
                                icon={(
                                    <div className="fa-layers fa-fw">
                                        <FontAwesomeIcon icon={faPlus} transform="shrink-4 right-12" />
                                        <FontAwesomeIcon icon={faFileInvoice} />
                                    </div>
                                )}
                            />
                            <PageNavigationItem
                                exact
                                title="Periodieke facturen"
                                path="/recurringinvoices"
                                icon={(
                                    <div className="fa-layers fa-fw">
                                        <FontAwesomeIcon icon={faSync} transform="shrink-4 right-12" />
                                        <FontAwesomeIcon icon={faFileInvoice} />
                                    </div>
                                )}
                            />
                        </PageNavigationItem>
                    )}
                    {permissionStore.canOneOf(['list_payments']) && (
                        <PageNavigationItem title="Betalingen">
                            <PageNavigationItem
                                exact
                                title="Overzicht"
                                path="/payments"
                                icon={(
                                    <FontAwesomeIcon icon={faMoneyBillWave} />
                                )}
                            />
                        </PageNavigationItem>
                    )}
                    {permissionStore.canOneOf(['create_quote', 'list_quotes']) && (
                        <PageNavigationItem title="Offertes">
                            <PageNavigationItem
                                exact
                                title="Overzicht"
                                path="/quotes"
                                icon={(
                                    <FontAwesomeIcon icon={faFileInvoice} />
                                )}
                            />
                            <PageNavigationItem
                                exact
                                title="Offerte maken"
                                path="/quotes/create"
                                icon={(
                                    <div className="fa-layers fa-fw">
                                        <FontAwesomeIcon icon={faPlus} transform="shrink-4 right-12" />
                                        <FontAwesomeIcon icon={faFileInvoice} />
                                    </div>
                                )}
                            />
                        </PageNavigationItem>
                    )}
                    {permissionStore.canOneOf(['edit_company', 'list_vat_rates', 'create_payment']) && (
                        <PageNavigationItem title="Bedrijf">
                            <PageNavigationItem
                                exact
                                title="Instellingen"
                                path="/company/edit"
                                icon={(
                                    <div className="fa-layers fa-fw">
                                        <FontAwesomeIcon icon={faCog} transform="shrink-4 right-12" />
                                        <FontAwesomeIcon icon={faBuilding} />
                                    </div>
                                )}
                            />
                            {permissionStore.can('list_vat_rates') && (
                                <PageNavigationItem
                                    exact
                                    title="BTW-tarieven"
                                    path="/vatrates"
                                    icon={<FontAwesomeIcon icon={faPercent} />}
                                />
                            )}
                            {permissionStore.can('create_payment') && (
                                <PageNavigationItem
                                    exact
                                    title="Betalingsmethodes"
                                    path="/paymentmethods"
                                    icon={(
                                        <FontAwesomeIcon icon={faCreditCard} />
                                    )}
                                />
                            )}
                        </PageNavigationItem>
                    )}
                    {permissionStore.canOneOf(['create_user']) && (
                        <PageNavigationItem title="Systeem">
                            <PageNavigationItem
                                exact
                                title="Gebruiker toevoegen"
                                path="/users/create"
                                icon={(
                                    <div className="fa-layers fa-fw">
                                        <FontAwesomeIcon icon={faPlus} transform="shrink-4 right-12" />
                                        <FontAwesomeIcon icon={faUser} />
                                    </div>
                                )}
                            />
                        </PageNavigationItem>
                    )}
                </div>
                <div className="page-navigation-user-area">
                    {authStore?.user?.name
                    && authStore.user.surname
                    && `${authStore.user.name} ${authStore.user.surname}`}
                    <div>
                        <Button
                            id="settings"
                            variant="light"
                            onClick={() => {
                                history.push('/settings');
                            }}
                        >
                            <FontAwesomeIcon icon={faCog} />
                        </Button>
                        <Button
                            id="logout"
                            variant="light"
                            onClick={() => authStore?.logout()}
                        >
                            <FontAwesomeIcon icon={faSignOutAlt} />
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}
