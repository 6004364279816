import * as React from 'react';
import { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faPlus, faSort, faBuilding, faCheck
} from '@fortawesome/free-solid-svg-icons';
import clsx from 'clsx';
import Button from '../Button';
import List from '../List';
import ListItem from '../List/Item';
import { Application } from '../../../app/application';
import { PermissionStore } from '../../../app/stores/permissions/PermissionStore';
import { UserStore } from '../../../app/stores/user/UserStore';
import { IUserCompany } from '../../../app/stores/user/interfaces/IUserCompany';

export interface CompanySelectorProps {
    userStore?: UserStore;
    application?: Application;
    permissionStore?: PermissionStore;
}

const CompanySelector = React.forwardRef<HTMLButtonElement, CompanySelectorProps>((
    {
        userStore,
        permissionStore
    },
    ref
): React.ReactElement => {
    const [open, setOpen] = useState(false);

    useEffect((): void => {
        if (!userStore?.companies.length) {
            userStore?.getCompanies(true);
        }
    }, []);

    const selectCompany = (uuid: string): void => {
        userStore?.selectCompany(uuid)
            .then(() => {
                setOpen(false);
            });
    };

    const renderCompaniesList = () => {
        if (userStore?.isLoaded('get-companies')) {
            if (!userStore?.companies.length) {
                if (permissionStore?.can('create_company')) {
                    return (
                        <Button
                            variant="secondary"
                            icon={<FontAwesomeIcon icon={faPlus} />}
                            to="/company/create"
                        >
                            U heeft nog geen bedrijf. Klik om er een te maken
                        </Button>
                    );
                }

                return 'U heeft geen bedrijven om mee te werken.';
            }

            return (
                <List className="user-company-list">
                    {userStore?.companies.map((userCompany: IUserCompany) => (
                        <ListItem
                            className={clsx(
                                'user-company-list-item',
                                userStore?.currentCompany?.uuid === userCompany.uuid && 'selected',
                                userStore?.isLoading(`select-company-${userCompany.uuid}`) && 'loading',
                            )}
                            key={userCompany.uuid}
                            onClick={() => {
                                selectCompany(userCompany.company.uuid);
                            }}
                        >
                            <div>
                                {userCompany.company.parent && (
                                    <div className="parent-company">
                                        {`Holding: ${userCompany.company.parent.name}`}
                                    </div>
                                )}
                                <strong>{userCompany.company.name}</strong>

                            </div>
                            {userStore?.currentCompany?.uuid === userCompany.uuid && (
                                <span className="ml-auto text-primary">
                                    <FontAwesomeIcon icon={faCheck} />
                                </span>
                            )}
                        </ListItem>
                    ))}
                </List>
            );
        }

        return 'Bedrijven worden geladen...';
    };

    return (
        <div
            className={clsx(
                'company-selector',
                userStore?.isLoading('get-companies') && 'loading'
            )}
        >
            <Button
                ref={ref}
                className={clsx(
                    'company-selector-button',
                    open && 'active'
                )}
                variant="white"
                onClick={() => {
                    setOpen(!open);
                }}
            >
                <div className="company-avatar">
                    <FontAwesomeIcon icon={faBuilding} />
                </div>
                <h3 className="company-selector-name">
                    {userStore?.currentCompany ? userStore.currentCompany.company.name : 'Geen bedrijf geselecteerd'}
                </h3>
                <FontAwesomeIcon className="icon" icon={faSort} />
            </Button>
            {open && (
                <div className="floating-result">
                    {renderCompaniesList()}
                </div>
            )}
        </div>
    );
});

CompanySelector.displayName = 'CompanySwitcher';

export default inject('userStore', 'application', 'permissionStore')(
    observer(CompanySelector)
);
