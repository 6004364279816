import { LoadingStore } from '../loading/LoadingStore';
import { INewConceptQuote } from './interfaces/INewConceptQuote';
import { api } from '../../services/api';
import { ResponseStatus } from '../../services/api/ResponseStatus';
import { DataInvalidError } from '../../errors/DataInvalidError';
import { IResponse } from '../../services/api/IResponse';
import { IConceptQuote } from './interfaces/IConceptQuote';
import { IErrorResponse } from '../../services/api/IErrorResponse';
import { application } from '../../application';
import { ApiError } from '../../errors/ApiError';
import { SomethingHappenedError } from '../../errors/SomethingHappenedError';
import { NotFoundError } from '../../errors/NotFoundError';
import { ICalculation } from './interfaces/ICalculation';
import { IQuote } from './interfaces/IQuote';
import { IConceptQuoteItem } from './interfaces/IConceptQuoteItem';

export class QuoteStore extends LoadingStore {
    public async createQuote(quote: INewConceptQuote): Promise<IConceptQuote | IQuote | undefined> {
        this.setLoading('create-concept-quote');

        try {
            const response = await api.post<IResponse<IConceptQuote> | IErrorResponse>('/api/quotes', quote);

            /* istanbul ignore else */
            if (response.status === ResponseStatus.CREATED) {
                return (response.data as IResponse<IConceptQuote>).data;
            }
        } catch (e) {
            /* istanbul ignore else */
            if (e.response.status === ResponseStatus.INVALID) {
                throw new DataInvalidError(
                    (e.response.data as IErrorResponse).message,
                    (e.response.data as IErrorResponse).errors!
                );
            }

            /* istanbul ignore next */
            application.handleError(new ApiError());
            /* istanbul ignore next */
            return undefined;
        } finally {
            this.setLoaded('create-concept-quote');
        }

        /* istanbul ignore next */
        application.handleError(new SomethingHappenedError());

        /* istanbul ignore next */
        return undefined;
    }

    public async getConceptQuote(uuid: string): Promise<IConceptQuote | undefined> {
        this.setLoading('get-concept-quote');

        try {
            const response = await api.get<IResponse<IConceptQuote> | IErrorResponse>(
                `/api/quotes/concept/${uuid}`
            );

            /* istanbul ignore else */
            if (response.status === ResponseStatus.OK) {
                return (response.data as IResponse<IConceptQuote>).data;
            }
        } catch (e) {
            switch (e.response.status) {
                case ResponseStatus.NOT_FOUND:
                    throw new NotFoundError(
                        (e.response.data as IErrorResponse).message,
                        (e.response.data as IErrorResponse).details
                    );
                default:
                    /* istanbul ignore next */
                    application.handleError(new ApiError());
                    /* istanbul ignore next */
                    return undefined;
            }
        }

        /* istanbul ignore next */
        application.handleError(new SomethingHappenedError());
        /* istanbul ignore next */
        return undefined;
    }

    public async saveConceptQuote(
        uuid: string,
        values: IConceptQuote
    ): Promise<IConceptQuote | IQuote | undefined> {
        this.setLoading('save-concept-quote');

        try {
            const response = await api.put<IResponse<IConceptQuote>>(
                `/api/quotes/${uuid}`,
                values
            );

            if (response.status === ResponseStatus.OK || response.status === ResponseStatus.CREATED) {
                return (response.data as IResponse<IConceptQuote>).data;
            }
        } catch (e) {
            if (e.response.status === ResponseStatus.INVALID) {
                throw new DataInvalidError(
                    (e.response.data as IErrorResponse).message,
                    (e.response.data as IErrorResponse).errors!
                );
            }
        } finally {
            this.setLoaded('save-concept-quote');
        }

        /* istanbul ignore next */
        application.handleError(new SomethingHappenedError());
        /* istanbul ignore next */
        return undefined;
    }

    public async calculateTotals(items: IConceptQuoteItem[]): Promise<ICalculation> {
        this.setLoading('calculate-totals');

        try {
            const response = await api.post<IResponse<ICalculation> | IErrorResponse>(
                '/api/quotes/calculate',
                {
                    items
                }
            );

            if (response.status === ResponseStatus.OK) {
                return (response.data as IResponse<ICalculation>).data;
            }
        } catch (e) {
            //
        } finally {
            this.setLoaded('calculate-totals');
        }

        return {
            sub_total: '0.00',
            total: '0.00'
        };
    }

    public async sendQuote(values: INewConceptQuote): Promise<IQuote | undefined> {
        this.setLoading('send-quote');

        try {
            const response = await api.post<IResponse<IQuote> | IErrorResponse>(
                '/api/quotes/send',
                values
            );

            /* istanbul ignore else */
            if (response.status === ResponseStatus.CREATED) {
                return (response.data as IResponse<IQuote>).data;
            }
        } catch (e) {
            /* istanbul ignore else */
            if (e.response.status === ResponseStatus.INVALID) {
                throw new DataInvalidError(
                    (e.response.data as IErrorResponse).message,
                    (e.response.data as IErrorResponse).errors!
                );
            }
        } finally {
            this.setLoaded('send-quote');
        }

        /* istanbul ignore next */
        application.handleError(new SomethingHappenedError());

        /* istanbul ignore next */
        return undefined;
    }

    public async sendConceptQuote(
        conceptQuoteUuid: string,
        values: IConceptQuote
    ): Promise<IQuote | undefined> {
        this.setLoading('send-concept-quote');

        try {
            const response = await api.post<IResponse<IQuote> | IErrorResponse>(
                `/api/quotes/concept/${conceptQuoteUuid}/send`,
                values
            );

            /* istanbul ignore else */
            if (response.status === ResponseStatus.CREATED) {
                return (response.data as IResponse<IQuote>).data;
            }
        } catch (e) {
            /* istanbul ignore else */
            if (e.response.status === ResponseStatus.INVALID) {
                throw new DataInvalidError(
                    (e.response.data as IErrorResponse).message,
                    (e.response.data as IErrorResponse).errors!
                );
            }
        } finally {
            this.setLoaded('send-concept-quote');
        }

        /* istanbul ignore next */
        application.handleError(new SomethingHappenedError());

        /* istanbul ignore next */
        return undefined;
    }

    public async getQuote(quoteUuid: string): Promise<IQuote | undefined> {
        this.setLoading('get-quote-details');

        try {
            const response = await api.get<IResponse<IQuote>>(`api/quotes/${quoteUuid}`);

            if (response.status === ResponseStatus.OK) {
                return response.data.data;
            }
        } catch (e) {
            if (e.response.status === ResponseStatus.NOT_FOUND) {
                switch (e.response.status) {
                    case ResponseStatus.NOT_FOUND:
                        throw new NotFoundError(
                            (e.response.data as IErrorResponse).message,
                            (e.response.data as IErrorResponse).details
                        );
                    default:
                        /* istanbul ignore next */
                        application.handleError(new ApiError());
                        /* istanbul ignore next */
                        return undefined;
                }
            }
        } finally {
            this.setLoaded('get-quote-details');
        }

        /* istanbul ignore next */
        application.handleError(new SomethingHappenedError());
        /* istanbul ignore next */
        return undefined;
    }

    public async downloadQuote(quoteUuid: string, name: string): Promise<void> {
        this.setLoading(`download-quote-${quoteUuid}`);

        try {
            const response = await api.get(`api/quotes/${quoteUuid}/download`, {
                responseType: 'blob'
            });

            if (response.status === ResponseStatus.OK) {
                const path = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = path;
                link.download = `${name}.pdf`;
                link.click();
                return;
            }
        } catch (e) {
            application.handleError(new ApiError(e));
            return;
        } finally {
            this.setLoaded(`download-quote-${quoteUuid}`);
        }

        application.handleError(new SomethingHappenedError());
    }
}

const quoteStore = new QuoteStore();

export {
    quoteStore
};
