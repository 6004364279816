import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import { RouteComponentProps, withRouter } from 'react-router';
import Page from '../../shared/Page';
import UsesCompany from '../../shared/company/UsesCompany';
import { InvoiceStore } from '../../../app/stores/invoices/InvoiceStore';
import Button from '../../shared/Button';
import Container from '../../shared/Container';
import { UserStore } from '../../../app/stores/user/UserStore';
import { IConceptInvoice } from '../../../app/stores/invoices/interfaces/IConceptInvoice';
import { FormErrors } from '../../../app/errors/FormErrors';
import { NotFoundError } from '../../../app/errors/NotFoundError';
import { NotificationStore } from '../../../app/stores/notifications/NotificationStore';
import { DataInvalidError } from '../../../app/errors/DataInvalidError';
import { IInvoice } from '../../../app/stores/invoices/interfaces/IInvoice';
import CreateOrEditInvoice from '../../shared/invoices/CreateOrEditInvoice';
import { ICompanyDetails } from '../../../app/stores/company/interfaces/ICompanyDetails';
import { CompanyStore } from '../../../app/stores/company/CompanyStore';
import { IUserCompany } from '../../../app/stores/user/interfaces/IUserCompany';
import FormSelect from '../../shared/Forms/Select';
import { IStatus } from '../../../app/stores/statuses/interfaces/IStatus';
import { StatusStore } from '../../../app/stores/statuses/StatusStore';
import { history } from '../../../app/utils/history';
import { Status } from '../../../app/stores/statuses/Status';

interface EditInvoicePageParams {
    uuid: string;
}

export interface EditInvoicePageProps extends RouteComponentProps<EditInvoicePageParams> {
    invoiceStore?: InvoiceStore;
    userStore?: UserStore;
    companyStore?: CompanyStore;
    notificationStore?: NotificationStore;
    statusStore?: StatusStore;
}

@inject('invoiceStore', 'userStore', 'notificationStore', 'companyStore', 'statusStore')
@observer
class EditInvoiceConceptPage extends React.Component<EditInvoicePageProps, unknown> {
    @observable private invoice?: IConceptInvoice;
    @observable private company?: ICompanyDetails;
    @observable private formErrors: FormErrors;
    @observable private statuses: IStatus[] = [];

    public constructor(props: EditInvoicePageProps) {
        super(props);

        this.formErrors = new FormErrors();
    }

    public componentDidMount(): void {
        const { userStore } = this.props;

        if (userStore?.currentCompany) {
            this.refreshInvoice().then(() => {
                this.refreshStatuses();
                this.refreshCompanyDetails(userStore.currentCompany!.company.uuid);
            });
        }
    }

    private setStatus(statuses: IStatus[]): void {
        if (this.invoice) {
            statuses.forEach((status: IStatus) => {
                if (status.code === Status.CONCEPT) {
                    this.invoice!.status_uuid = status.uuid;
                }
            });
        }
    }

    private refreshStatuses(): void {
        const { statusStore } = this.props;

        statusStore?.getStatuses().then((statuses: IStatus[]) => {
            this.statuses = statuses;
            this.setStatus(statuses);
        });
    }

    private refreshCompanyDetails(companyUuid: string): void {
        const { companyStore } = this.props;

        companyStore?.getCompanyDetails(companyUuid).then(
            (company: ICompanyDetails | undefined) => {
                this.company = company;
            }
        );
    }

    private async refreshInvoice(): Promise<void> {
        const {
            invoiceStore,
            notificationStore,
            match: {
                params: {
                    uuid
                }
            }
        } = this.props;

        notificationStore?.clear();

        invoiceStore?.getConceptInvoice(uuid).then((invoice: IConceptInvoice | undefined) => {
            this.invoice = invoice;

            if (invoice) {
                this.setStatus(this.statuses);
            }
        }).catch((e: NotFoundError | undefined) => {
            notificationStore?.addNotification({
                message: e?.message || 'Factuur niet gevonden',
                messageDetails: e?.details,
                variant: 'danger',
                closable: false
            });
            this.invoice = undefined;
        });
    }

    private saveInvoice(): void {
        const {
            invoiceStore,
            notificationStore,
            match: {
                params: { uuid }
            }
        } = this.props;

        this.formErrors.clear();
        notificationStore?.clear();

        if (this.invoice) {
            invoiceStore?.saveConceptInvoice(
                uuid,
                this.invoice
            ).then((invoice: IConceptInvoice | IInvoice | undefined) => {
                if (invoice) {
                    if ((invoice as IInvoice).invoice_number) {
                        history.push(`/invoices/${invoice.uuid}/details`);
                        return;
                    }

                    const statusUuid = this.invoice?.status_uuid;

                    this.invoice = {
                        ...invoice as IConceptInvoice,
                        status_uuid: statusUuid
                    };
                    notificationStore?.addNotification({
                        variant: 'success',
                        message: `Conceptfactuur succesvol opgeslagen`
                    });
                }
            }).catch((e: DataInvalidError) => {
                this.formErrors.set(e.errors);
            });
        }
    }

    private sendInvoice(): void {
        const {
            invoiceStore,
            notificationStore,
            match: {
                params: {
                    uuid
                }
            }
        } = this.props;

        this.formErrors.clear();
        notificationStore?.clear();

        if (this.invoice) {
            invoiceStore?.sendConceptInvoice(
                uuid,
                this.invoice
            ).then((invoice: IInvoice | undefined): void => {
                if (invoice) {
                    if ((invoice as IInvoice).invoice_number) {
                        history.push(`/invoices/${invoice.uuid}/details`);
                    }

                    notificationStore?.addNotification({
                        variant: 'success',
                        message: `Factuur succesvol verzonden`
                    });
                }
            }).catch((e: DataInvalidError) => {
                this.formErrors.set(e.errors);
            });
        }
    }

    private changeCompany(companyUuid: string): void {
        this.refreshInvoice().then(() => {
            this.refreshStatuses();
            this.refreshCompanyDetails(companyUuid);
        });
    }

    public render(): React.ReactNode {
        const { invoiceStore, statusStore } = this.props;

        return (
            <Page
                title="Factuur aanpassen"
                actions={this.invoice ? (
                    <>
                        <FormSelect
                            disabled={!this.invoice || !this.invoice.customer_uuid}
                            className="w-auto mr-4"
                            loading={statusStore?.isLoading('get-statuses')}
                            value={this.invoice.status_uuid}
                            name="state_uuid"
                            onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                                this.invoice!.status_uuid = event.target.value;
                            }}
                        >
                            {this.statuses.map((status: IStatus) => (
                                <option
                                    value={status.uuid}
                                    key={status.uuid}
                                >
                                    {status.locale}
                                </option>
                            ))}
                        </FormSelect>
                        <Button
                            disabled={!this.invoice}
                            id="save-invoice"
                            className="mr-4"
                            variant="secondary"
                            onClick={() => {
                                this.saveInvoice();
                            }}
                            loading={invoiceStore?.isLoading('save-concept-invoice')}
                        >
                            Opslaan
                        </Button>
                        <div className="vertical-divider" />
                        <Button
                            id="send-invoice"
                            disabled={!this.invoice}
                            variant="primary"
                            onClick={() => {
                                this.sendInvoice();
                            }}
                            loading={invoiceStore?.isLoading('send-concept-invoice')}
                        >
                            Factuur versturen
                        </Button>
                    </>
                ) : undefined}
            >
                <Container center className="w-3/4">
                    <UsesCompany
                        onCompanySelected={(userCompany: IUserCompany): void => {
                            this.changeCompany(userCompany.company.uuid);
                        }}
                    >
                        {this.invoice && (
                            <CreateOrEditInvoice<IConceptInvoice>
                                onInvoiceChanged={(invoice: IConceptInvoice) => {
                                    this.invoice = invoice;
                                }}
                                errors={this.formErrors}
                                invoice={this.invoice}
                                customer={this.invoice.customer}
                                company={this.company}
                            />
                        )}
                    </UsesCompany>
                </Container>
            </Page>
        );
    }
}

export default withRouter(EditInvoiceConceptPage);
