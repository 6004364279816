import { observable } from 'mobx';
import { Errors } from './DataInvalidError';

export class FormErrors {
    @observable private _errors: Errors = {};

    public constructor(errors: Errors = {}) {
        this._errors = errors;
    }

    public set(errors: Errors | undefined): void {
        if (errors) {
            this._errors = errors;
        } else {
            this._errors = {};
        }
    }

    public get errors(): Errors {
        return this._errors;
    }

    public has(key: string): boolean {
        return Object.prototype.hasOwnProperty.call(this._errors, key) && this._errors[key].length > 0;
    }

    public first(key: string): string | undefined {
        if (this.has(key)) {
            return this._errors[key][0];
        }

        return undefined;
    }

    public clear(): void {
        this._errors = {};
    }

    public filterPrefix(prefix: string): FormErrors {
        const errorsWithPrefix: Errors = {};

        Object.keys(this._errors).forEach((key: string) => {
            if (key.startsWith(prefix)) {
                errorsWithPrefix[key.substr(prefix.length, key.length)] = this._errors[key];
            }
        });

        return new FormErrors(errorsWithPrefix);
    }
}
