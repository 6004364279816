import * as React from 'react';
import clsx from 'clsx';
import { inject, observer } from 'mobx-react';
import PageNavigation from '../Navigation/PageNavigation';
import { PermissionStore } from '../../../app/stores/permissions/PermissionStore';
import { INotification } from '../../../app/stores/notifications/interfaces/INotification';
import Notification from '../Notification';
import { NotificationStore } from '../../../app/stores/notifications/NotificationStore';

interface PageProps extends React.HTMLAttributes<HTMLDivElement> {
    title?: string;
    permissionStore?: PermissionStore;
    notificationStore?: NotificationStore;
    actions?: React.ReactNode;
}

const Page = React.forwardRef<HTMLDivElement, PageProps>((
    {
        children,
        title,
        notificationStore,
        actions
    },
    ref
): React.ReactElement => (
    <div
        ref={ref}
        className={clsx('page')}
    >
        <PageNavigation />
        <div className="page-content">
            <div className="page-header">
                <h1 className="page-title">{title}</h1>
                {!!actions && (
                    <div className="page-header-actions">
                        {actions}
                    </div>
                )}
            </div>
            <div className="page-body">
                <div className="mb-4">
                    {notificationStore?.all().map((notification: INotification) => (
                        <Notification key={notification.id} {...notification} />
                    ))}
                </div>
                {children}
            </div>
        </div>
    </div>
));

Page.displayName = 'Page';

export default inject('permissionStore', 'notificationStore')(
    observer(Page)
);
