import { observable } from 'mobx';
import { LoadingStore } from '../loading/LoadingStore';
import { IGender } from './interfaces/IGender';
import { api } from '../../services/api';
import { application } from '../../application';
import { ApiError } from '../../errors/ApiError';
import { ResponseStatus } from '../../services/api/ResponseStatus';
import { IResponse } from '../../services/api/IResponse';
import { SomethingHappenedError } from '../../errors/SomethingHappenedError';

export class GenderStore extends LoadingStore {
    @observable private _genders: IGender[] = [];

    public get genders(): IGender[] {
        return this._genders;
    }

    public async getGenders(): Promise<void> {
        this.setLoading('get-genders');

        try {
            const response = await api.get<IResponse<IGender[]>>('/api/genders');

            if (response.status === ResponseStatus.OK) {
                this._genders = (response.data as IResponse<IGender[]>).data;
                return;
            }
        } catch (e) {
            /* istanbul ignore next */
            application.handleError(new ApiError(e));
            /* istanbul ignore next */
            return;
        } finally {
            this.setLoaded('get-genders');
        }

        /* istanbul ignore next */
        application.handleError(new SomethingHappenedError());
    }
}

const genderStore = new GenderStore();

export {
    genderStore
};
