import { authStore } from './auth/AuthStore';
import { notificationStore } from './notifications/NotificationStore';
import { permissionStore } from './permissions/PermissionStore';
import { application } from '../application';
import { companyStore } from './company/CompanyStore';
import { countryStore } from './country/CountryStore';
import { userStore } from './user/UserStore';
import { customerStore } from './customers/CustomerStore';
import { genderStore } from './genders/GenderStore';
import { invoiceStore } from './invoices/InvoiceStore';
import { paymentStore } from './payments/PaymentStore';
import { statusStore } from './statuses/StatusStore';
import { vatRatesStore } from './vat-rates/VatRatesStore';
import { paymentMethodStore } from './payment-methods/PaymentMethodStore';
import { recurringInvoiceStore } from './recurring-invoices/RecurringInvoiceStore';
import { intervalTypeStore } from './interval-types/IntervalTypeStore';
import { quoteStore } from './quotes/QuoteStore';
import { passwordResetStore } from './password-resets/PasswordResetStore';
import { kvkStore } from './kvk/KvkStore';
import { timezoneStore } from './timezones/TimezoneStore';

export interface StoresRoot {
    stores: Record<string, unknown>;
    get: <T>(store: string) => T | undefined;
}

const storesRoot: StoresRoot = {
    stores: {
        authStore,
        notificationStore,
        permissionStore,
        application,
        companyStore,
        countryStore,
        userStore,
        customerStore,
        genderStore,
        invoiceStore,
        paymentStore,
        statusStore,
        paymentMethodStore,
        vatRatesStore,
        recurringInvoiceStore,
        intervalTypeStore,
        quoteStore,
        passwordResetStore,
        kvkStore,
        timezoneStore
    },
    get<T>(store: string): T | undefined {
        /* istanbul ignore else  */
        if (Object.prototype.hasOwnProperty.call(this.stores, store)) {
            return this.stores[store] as T;
        }

        /* istanbul ignore next */
        return undefined;
    }
};

export default storesRoot;
