import * as React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import * as H from 'history';

export interface ButtonProps {
    variant: string;
    loading?: boolean;
    icon?: React.ReactElement;
    disabled?: boolean;
}

export interface ButtonAsButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, ButtonProps {
    type?: 'submit' | 'button';
    to?: undefined;
}

export interface ButtonAsAnchorProps extends React.AnchorHTMLAttributes<HTMLAnchorElement>, ButtonProps {
    to: H.LocationDescriptor<unknown> | ((location: H.Location<unknown>) => H.LocationDescriptor<unknown>);
}

const Button = React.forwardRef<HTMLButtonElement | HTMLAnchorElement, ButtonAsButtonProps | ButtonAsAnchorProps>((
    {
        children,
        className,
        loading,
        type = 'button',
        variant,
        icon,
        to,
        disabled,
        ...rest
    },
    ref
): React.ReactElement => {
    const renderContent = (): React.ReactElement => (
        <>
            {icon && (
                <div className="icon-container">{icon}</div>
            )}
            {children}
        </>
    );

    if (to) {
        return (
            <Link
                ref={ref as React.ForwardedRef<HTMLAnchorElement>}
                to={to}
                className={clsx(
                    'btn',
                    loading && 'btn-loading',
                    `btn-${variant}`,
                    className
                )}
                {...(rest as Partial<ButtonAsAnchorProps>)}
            >
                {renderContent()}
            </Link>
        );
    }

    return (
        <button
            // eslint-disable-next-line react/button-has-type
            type={type as 'submit' | 'button'}
            ref={ref as React.ForwardedRef<HTMLButtonElement>}
            disabled={disabled || loading}
            className={clsx(
                'btn',
                loading && 'btn-loading',
                `btn-${variant}`,
                className
            )}
            {...(rest as Partial<ButtonAsButtonProps>)}
        >
            {renderContent()}
        </button>
    );
});

Button.displayName = 'Button';

export default Button;
