import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import { RouteComponentProps, withRouter } from 'react-router';
import Container from '../../shared/Container';
import Card from '../../shared/Card';
import { NotificationStore } from '../../../app/stores/notifications/NotificationStore';
import { PasswordResetStore } from '../../../app/stores/password-resets/PasswordResetStore';
import { INotification } from '../../../app/stores/notifications/interfaces/INotification';
import Notification from '../../shared/Notification';
import FormGroup from '../../shared/Forms/Group';
import FormLabel from '../../shared/Forms/Label';
import FormInput from '../../shared/Forms/Input';
import { FormErrors } from '../../../app/errors/FormErrors';
import FormMessage from '../../shared/Forms/Message';
import Button from '../../shared/Button';
import { INewPassword } from '../../../app/stores/password-resets/interfaces/INewPassword';
import { DataInvalidError } from '../../../app/errors/DataInvalidError';
import { application } from '../../../app/application';

interface ResetPasswordPageParams {
    token: string;
}

export interface ResetPasswordPageProps extends RouteComponentProps<ResetPasswordPageParams> {
    notificationStore?: NotificationStore;
    passwordResetStore?: PasswordResetStore;
}

@inject('passwordResetStore', 'notificationStore')
@observer
class ResetPasswordPage extends React.Component<ResetPasswordPageProps, unknown> {
    @observable private valid = false;
    @observable private formValues: INewPassword = {
        new_password: '',
        new_password_confirmation: ''
    }
    @observable private formErrors: FormErrors;

    public constructor(props: ResetPasswordPageProps) {
        super(props);

        this.formErrors = new FormErrors();
    }

    public componentDidMount() {
        const {
            passwordResetStore,
            notificationStore,
            match: { params: { token } }
        } = this.props;

        passwordResetStore?.getPasswordReset(token).then((valid: boolean) => {
            this.valid = valid;
        }).catch(() => {
            notificationStore?.addNotification({
                variant: 'danger',
                message: 'Token is ongeldig'
            });
        });
    }

    private submitResetPassword(event: React.FormEvent): void {
        event.preventDefault();

        const {
            passwordResetStore,
            notificationStore,
            match: { params: { token } }
        } = this.props;

        notificationStore?.clear();
        this.formErrors.clear();

        passwordResetStore?.resetPassword(this.formValues, token).then((success: boolean) => {
            if (success) {
                application.dispose();
                notificationStore?.addNotification({
                    variant: 'success',
                    message: 'Wachtwoord succesvol ingesteld. U kunt hiermee nu inloggen'
                });
            }
        }).catch((e: DataInvalidError) => {
            notificationStore?.addNotification({
                variant: 'danger',
                message: e.message
            });
            this.formErrors.set(e.errors);
        });
    }

    private renderForm(): React.ReactNode {
        const { passwordResetStore } = this.props;

        if (passwordResetStore?.isLoading('get-password-reset')) {
            return 'Gegevens worden opgehaald...';
        }

        if (passwordResetStore?.isLoaded('get-password-reset') && this.valid) {
            return (
                <form onSubmit={(event: React.FormEvent) => this.submitResetPassword(event)}>
                    <FormGroup>
                        <FormLabel>Nieuw wachtwoord</FormLabel>
                        <FormInput
                            name="new_password"
                            type="password"
                            value={this.formValues.new_password}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                                this.formValues.new_password = event.target.value;
                            }}
                            hasError={this.formErrors.has('new_password')}
                        />
                        {this.formErrors.has('new_password') && (
                            <FormMessage type="invalid">
                                {this.formErrors.first('new_password')}
                            </FormMessage>
                        )}
                    </FormGroup>
                    <FormGroup>
                        <FormLabel>Herhaling nieuw wachtwoord</FormLabel>
                        <FormInput
                            name="new_password_confirmation"
                            type="password"
                            value={this.formValues.new_password_confirmation}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                                this.formValues.new_password_confirmation = event.target.value;
                            }}
                            hasError={this.formErrors.has('new_password_confirmation')}
                        />
                        {this.formErrors.has('new_password_confirmation') && (
                            <FormMessage type="invalid">
                                {this.formErrors.first('new_password_confirmation')}
                            </FormMessage>
                        )}
                    </FormGroup>
                    <Button
                        variant="primary"
                        type="submit"
                    >
                        Wachtwoord instellen
                    </Button>
                </form>
            );
        }

        return undefined;
    }

    public render(): React.ReactNode {
        const { notificationStore } = this.props;

        return (
            <div className="standalone-page center">
                <Container className="w-1/4">
                    <Card>
                        <h1>Wachtwoord instellen</h1>

                        <div className="mb-4">
                            {notificationStore?.all().map((notification: INotification) => (
                                <Notification key={notification.id} {...notification} />
                            ))}
                        </div>
                        {this.renderForm()}
                    </Card>
                </Container>
            </div>
        );
    }
}

export default withRouter(ResetPasswordPage);
