import * as React from 'react';
import ReactPaginate, { ReactPaginateProps } from 'react-paginate';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

export interface PaginateProps extends Omit<ReactPaginateProps, 'pageRangeDisplayed' | 'marginPagesDisplayed'> {
    className?: string;
    pageRangeDisplayed?: number;
    marginPagesDisplayed?: number;
}

const Paginate = React.forwardRef<React.Component<ReactPaginateProps>, PaginateProps>((
    {
        className,
        pageRangeDisplayed,
        marginPagesDisplayed,
        ...props
    },
    ref
): React.ReactElement => (
    <div
        className={clsx(
            'paginate',
            className
        )}
    >
        <ReactPaginate
            ref={ref}
            pageRangeDisplayed={pageRangeDisplayed || 4}
            marginPagesDisplayed={marginPagesDisplayed || 2}
            activeClassName="active"
            activeLinkClassName="active"
            breakClassName="break"
            disabledClassName="disabled"
            nextLinkClassName="next-link"
            containerClassName="paginate-items"
            nextClassName="btn-next"
            pageClassName="paginate-item"
            previousClassName="btn-previous"
            previousLinkClassName="previous-link"
            breakLinkClassName="break-link"
            pageLinkClassName="paginate-item-link"
            previousLabel={<FontAwesomeIcon icon={faChevronLeft} />}
            nextLabel={<FontAwesomeIcon icon={faChevronRight} />}
            disableInitialCallback
            {...props}
        />
    </div>
));

Paginate.displayName = 'Paginate';

export default Paginate;
