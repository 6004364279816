export default (dataUrl: string, fileName: string = ''): File | undefined => {
    const dataArr = dataUrl.split(',');
    const mimePart = dataArr[0].match(/:(.*?);/);

    if (!mimePart || mimePart.length < 2) {
        return undefined;
    }

    const mime = mimePart[1];
    const bstr = atob(dataArr[1]);
    let { length } = bstr;
    const u8Arr = new Uint8Array(length);

    while (length--) {
        u8Arr[length] = bstr.charCodeAt(length);
    }

    return new File([u8Arr], fileName, { type: mime });
};
