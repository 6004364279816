import { INewCompany } from './interfaces/INewCompany';
import { LoadingStore } from '../loading/LoadingStore';
import { api } from '../../services/api';
import { ResponseStatus } from '../../services/api/ResponseStatus';
import { ICompany } from './interfaces/ICompany';
import { IResponse } from '../../services/api/IResponse';
import { IErrorResponse } from '../../services/api/IErrorResponse';
import { DataInvalidError } from '../../errors/DataInvalidError';
import { SomethingHappenedError } from '../../errors/SomethingHappenedError';
import { application } from '../../application';
import { ICustomer } from '../customers/interfaces/ICustomer';
import { ApiError } from '../../errors/ApiError';
import { ICompanyDetails } from './interfaces/ICompanyDetails';
import { IListAllInvoices } from '../invoices/interfaces/IListAllInvoices';
import { IListCustomer } from '../customers/interfaces/IListCustomer';
import { IPaginated } from '../../utils/interface/IPaginated';
import { IListAllQuotes } from '../quotes/interfaces/IListAllQuotes';
import { IDashboardStats } from './interfaces/IDashboardStats';
import { IExpiredInvoice } from '../invoices/interfaces/IExpiredInvoice';
import { IRecurringInvoice } from '../recurring-invoices/interfaces/IRecurringInvoice';
import { IListPayment } from '../payments/interfaces/IListPayment';

export class CompanyStore extends LoadingStore {
    public async createCompany(data: INewCompany & Record<string, any>): Promise<ICompany | undefined> {
        this.setLoading('create-company');

        try {
            const formData = new FormData();
            Object.keys(data).forEach((key: string) => {
                formData.append(key, data[key] === null ? '' : data[key]);
            });
            const response = await api.post<IResponse<ICompany> | IErrorResponse>(
                '/api/companies/create',
                formData
            );

            /* istanbul ignore else */
            if (response.status === ResponseStatus.CREATED) {
                return (response.data as IResponse<ICompany>).data;
            }
        } catch (e) {
            /* istanbul ignore else */
            if (e.response.status === ResponseStatus.INVALID || e.response.status === ResponseStatus.CONFLICT) {
                throw new DataInvalidError(
                    (e.response.data as IErrorResponse).message,
                    (e.response.data as IErrorResponse).errors!
                );
            }

            /* istanbul ignore next */
            application.handleError(new ApiError(e));
        } finally {
            this.setLoaded('create-company');
        }

        /* istanbul ignore next */
        application.handleError(new SomethingHappenedError());
        /* istanbul ignore next */
        return undefined;
    }

    public async getCustomers(companyUuid: string, searchString: string | null = null): Promise<ICustomer[]> {
        this.setLoading('get-customers');

        try {
            const response = await api.get<IResponse<ICustomer[]>>(
                `/api/companies/${companyUuid}/customers/${searchString || ''}`
            );

            if (response.status === ResponseStatus.OK) {
                return (response.data as IResponse<ICustomer[]>).data;
            }
        } catch (e) {
            console.log(e);
            /* istanbul ignore next */
            application.handleError(new ApiError());
            /* istanbul ignore next */
            return [];
        } finally {
            this.setLoaded('get-customers');
        }

        /* istanbul ignore next */
        application.handleError(new SomethingHappenedError());

        /* istanbul ignore next */
        return [];
    }

    public async getCustomerList(companyUuid: string, page: number): Promise<IPaginated<IListCustomer> | undefined> {
        this.setLoading('get-customer-list');

        try {
            const response = await api.get<IPaginated<IListCustomer>>(
                `/api/companies/${companyUuid}/customerlist?page=${page}`
            );

            if (response.status === ResponseStatus.OK) {
                return response.data;
            }
        } catch (e) {
            /* istanbul ignore next */
            application.handleError(new ApiError());
            /* istanbul ignore next */
            return undefined;
        } finally {
            this.setLoaded('get-customer-list');
        }

        /* istanbul ignore next */
        application.handleError(new SomethingHappenedError());

        /* istanbul ignore next */
        return undefined;
    }

    public async getCompanyDetails(companyUuid: string): Promise<ICompanyDetails | undefined> {
        this.setLoading('get-company-details');

        try {
            const response = await api.get<IResponse<ICompanyDetails>>(`/api/companies/${companyUuid}/details`);

            if (response.status === ResponseStatus.OK) {
                return (response.data as IResponse<ICompanyDetails>).data;
            }
        } catch (e) {
            //
        } finally {
            this.setLoaded('get-company-details');
        }

        return undefined;
    }

    public async getAllInvoices(companyUuid: string, page: number = 1): Promise<IListAllInvoices | undefined> {
        this.setLoading('get-all-invoices');

        try {
            const response = await api.get<IResponse<IListAllInvoices>>(
                `/api/companies/${companyUuid}/invoices/all?page=${page}`
            );

            if (response.status === ResponseStatus.OK) {
                return response.data.data;
            }
        } catch (e) {
            /* istanbul ignore next */
            application.handleError(new ApiError(e));
            /* istanbul ignore next */
            return undefined;
        } finally {
            this.setLoaded('get-all-invoices');
        }

        /* istanbul ignore next */
        application.handleError(new SomethingHappenedError());

        /* istanbul ignore next */
        return undefined;
    }

    public async getAllQuotes(companyUuid: string, page: number = 1): Promise<IListAllQuotes | undefined> {
        this.setLoading('get-all-quotes');

        try {
            const response = await api.get<IResponse<IListAllQuotes>>(
                `/api/companies/${companyUuid}/quotes/all?page=${page}`
            );

            if (response.status === ResponseStatus.OK) {
                return response.data.data;
            }
        } catch (e) {
            /* istanbul ignore next */
            application.handleError(new ApiError(e));
            /* istanbul ignore next */
            return undefined;
        } finally {
            this.setLoaded('get-all-quotes');
        }

        /* istanbul ignore next */
        application.handleError(new SomethingHappenedError());

        /* istanbul ignore next */
        return undefined;
    }

    public async editCompany(
        companyUuid: string,
        data: Partial<ICompanyDetails> & Record<string, any>,
        logo: File | undefined
    ): Promise<ICompanyDetails | undefined> {
        this.setLoading('edit-company');

        try {
            const formData = new FormData();

            Object.keys(data).forEach((key: string): void => {
                formData.append(key, data[key] === null ? '' : data[key]);
            });
            formData.append('_method', 'PUT');

            formData.set('logo', logo || '');

            const response = await api.post<IResponse<ICompanyDetails> | IErrorResponse>(
                `/api/companies/${companyUuid}`,
                formData
            );

            if (response.status === ResponseStatus.CREATED) {
                return (response.data as IResponse<ICompanyDetails>).data;
            }
        } catch (e) {
            if (e.response?.status === ResponseStatus.INVALID) {
                throw new DataInvalidError(
                    (e.response.data as IErrorResponse).message,
                    (e.response.data as IErrorResponse).errors!
                );
            }

            /* istanbul ignore next */
            application.handleError(new ApiError(e));
            /* istanbul ignore next */
            return undefined;
        } finally {
            this.setLoaded('edit-company');
        }
        /* istanbul ignore next */
        application.handleError(new SomethingHappenedError());
        /* istanbul ignore next */
        return undefined;
    }

    public async getDashboardStats(companyUuid: string): Promise<IDashboardStats | undefined> {
        this.setLoading('get-dashboard-stats');

        try {
            const response = await api.get<IResponse<IDashboardStats>>(`/api/companies/${companyUuid}/dashboard/stats`);

            if (response.status === ResponseStatus.OK) {
                return (response.data as IResponse<IDashboardStats>).data;
            }
        } catch (e) {
            /* istanbul ignore next */
            application.handleError(new ApiError(e));
            /* istanbul ignore next */
            return undefined;
        } finally {
            this.setLoaded('get-dashboard-stats');
        }

        /* istanbul ignore next */
        application.handleError(new SomethingHappenedError());
        return undefined;
    }

    public async getExpiredInvoices(companyUuid: string): Promise<IExpiredInvoice[]> {
        this.setLoading('get-expired-invoices');

        try {
            const response = await api.get<IResponse<IExpiredInvoice[]>>(
                `/api/companies/${companyUuid}/invoices/expired`
            );

            if (response.status === ResponseStatus.OK) {
                return response.data.data;
            }
        } catch (e) {
            /* istanbul ignore next */
            application.handleError(new ApiError(e));
            /* istanbul ignore next */
            return [];
        } finally {
            this.setLoaded('get-expired-invoices');
        }
        /* istanbul ignore next */
        application.handleError(new SomethingHappenedError());
        /* istanbul ignore next */
        return [];
    }

    public async getRecurringInvoices(
        companyUuid: string, page: number
    ): Promise<IPaginated<IRecurringInvoice> | undefined> {
        this.setLoading('get-recurring-invoices');

        try {
            const response = await api.get<IPaginated<IRecurringInvoice>>(
                `/api/companies/${companyUuid}/invoices/recurring?page=${page}`
            );

            if (response.status === ResponseStatus.OK) {
                return response.data;
            }
        } catch (e) {
            /* istanbul ignore next */
            application.handleError(new ApiError(e));
            /* istanbul ignore next */
            return undefined;
        } finally {
            this.setLoaded('get-recurring-invoices');
        }

        /* istanbul ignore next */
        application.handleError(new SomethingHappenedError());

        /* istanbul ignore next */
        return undefined;
    }

    public async getPayments(
        companyUuid: string,
        page: number
    ): Promise<IPaginated<IListPayment> | undefined> {
        this.setLoading('get-payments');

        try {
            const response = await api.get<IPaginated<IListPayment>>(`/api/companies/${companyUuid}/payments?page=${page}`);

            if (response.status === ResponseStatus.OK) {
                return response.data;
            }
        } catch (e) {
            /* istanbul ignore next */
            application.handleError(new ApiError(e));
            /* istanbul ignore next */
            return undefined;
        } finally {
            this.setLoaded('get-payments');
        }
        /* istanbul ignore next */
        application.handleError(new SomethingHappenedError());
        /* istanbul ignore next */
        return undefined;
    }
}

const companyStore = new CompanyStore();

export {
    companyStore
};
