import moment from 'moment-timezone';
import { LoadingStore } from '../loading/LoadingStore';
import { ITimezone } from './interfaces/ITimezone';
import { api } from '../../services/api';
import { ResponseStatus } from '../../services/api/ResponseStatus';
import { IResponse } from '../../services/api/IResponse';
import { application } from '../../application';
import { ApiError } from '../../errors/ApiError';
import { SomethingHappenedError } from '../../errors/SomethingHappenedError';
import { IUserCompany } from '../user/interfaces/IUserCompany';

export class TimezoneStore extends LoadingStore {
    public async getTimezones(): Promise<ITimezone[]> {
        this.setLoading('get-timezones');

        try {
            const response = await api.get<IResponse<ITimezone[]>>('api/timezones');

            if (response.status === ResponseStatus.OK) {
                return response.data.data;
            }
        } catch (e) {
            /* istanbul ignore next */
            application.handleError(new ApiError(e));
            /* istanbul ignore next */
            return [];
        } finally {
            this.setLoaded('get-timezones');
        }

        /* istanbul ignore next */
        application.handleError(new SomethingHappenedError());
        /* istanbul ignore next */
        return [];
    }

    public refreshTimezone(userCompany: IUserCompany): void {
        moment.tz.setDefault(userCompany.company.timezone.name.replace(' ', '_'));
    }
}

const timezoneStore = new TimezoneStore();

export {
    timezoneStore
};
