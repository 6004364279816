import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import Page from '../../shared/Page';
import UsesCompany from '../../shared/company/UsesCompany';
import { UserStore } from '../../../app/stores/user/UserStore';
import { VatRatesStore } from '../../../app/stores/vat-rates/VatRatesStore';
import { IVatRate } from '../../../app/stores/vat-rates/interfaces/IVatRate';
import Card from '../../shared/Card';
import Container from '../../shared/Container';
import Button from '../../shared/Button';
import CreateVatRateModal from './CreateVatRateModal';
import Modal from '../../shared/Modal';
import { NotificationStore } from '../../../app/stores/notifications/NotificationStore';
import Notification from '../../shared/Notification';
import generateGuid from '../../../app/utils/generateGuid';
import { DetailedMessageError } from '../../../app/errors/DetailedMessageError';
import EditVatRateModal from './EditVatRateModal';

export interface VatRatesListPageProps {
    userStore?: UserStore;
    vatRatesStore?: VatRatesStore;
    notificationStore?: NotificationStore;
}

@inject('userStore', 'vatRatesStore', 'notificationStore')
@observer
class VatRatesListPage extends React.Component<VatRatesListPageProps, unknown> {
    @observable private vatRates: IVatRate[] = [];
    @observable private createVatRateModalOpen = false;
    @observable private editVatRateModalOpen = false;
    @observable private editVatRate?: IVatRate;

    public componentDidMount() {
        const { userStore } = this.props;

        if (userStore?.currentCompany) {
            this.refreshVatRates();
        }
    }

    private onVatRateCreated(): void {
        const { userStore, notificationStore } = this.props;

        this.createVatRateModalOpen = false;

        notificationStore?.addNotification({
            message: 'BTW-tarief succesvol toegevoegd',
            variant: 'success',
            autoClose: true
        });

        if (userStore?.currentCompany) {
            this.refreshVatRates();
        }
    }

    private onVatRateEdited(): void {
        const { userStore, notificationStore } = this.props;

        this.editVatRateModalOpen = false;

        notificationStore?.addNotification({
            message: 'BTW-tarief succesvol aangepast',
            variant: 'success',
            autoClose: true
        });

        if (userStore?.currentCompany) {
            this.refreshVatRates();
        }
    }

    private refreshVatRates(): void {
        const { userStore, vatRatesStore } = this.props;

        if (userStore?.currentCompany) {
            vatRatesStore?.getVatRates(
                userStore.currentCompany.company.uuid
            ).then((vatRates: IVatRate[]) => {
                this.vatRates = vatRates;
            });
        }
    }

    private deleteVatRate(vatRate: IVatRate): void {
        const { vatRatesStore, notificationStore } = this.props;

        notificationStore?.clear();

        vatRatesStore?.deleteVatRate(vatRate).then(() => {
            notificationStore?.addNotification({
                message: 'BTW-tarief succesvol verwijderd',
                variant: 'success'
            });
            this.refreshVatRates();
        }).catch((e: DetailedMessageError) => {
            notificationStore?.addNotification({
                message: e.message,
                variant: 'danger'
            });
        });
    }

    private renderVatRatesList(): React.ReactElement {
        const { vatRatesStore } = this.props;

        if (!this.vatRates.length) {
            return (
                <Notification
                    variant="info"
                    message="Er zijn nog geen BTW-tarieven toegevoegd"
                    id={generateGuid()}
                />
            );
        }

        return (
            <table className="table" id="vat-rates-list">
                <thead>
                    <tr>
                        <th>Naam</th>
                        <th>Tarief</th>
                        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                        <th />
                    </tr>
                </thead>
                <tbody>
                    {this.vatRates.map((vatRate: IVatRate) => (
                        <tr key={vatRate.uuid}>
                            <td>{vatRate.name}</td>
                            <td>{`${vatRate.percentage}%`}</td>
                            <td>
                                <Button
                                    variant="secondary"
                                    className="edit-vat-rate"
                                    type="button"
                                    loading={vatRatesStore?.isLoading(`edit-vat-rate-${vatRate.uuid}`)}
                                    onClick={() => {
                                        this.editVatRateModalOpen = true;
                                        this.editVatRate = vatRate;
                                    }}
                                    icon={<FontAwesomeIcon icon={faPen} />}
                                >
                                    Aanpassen
                                </Button>
                                <Button
                                    variant="danger"
                                    className="delete-vat-rate"
                                    type="button"
                                    loading={vatRatesStore?.isLoading(`delete-vat-rate-${vatRate.uuid}`)}
                                    onClick={() => {
                                        this.deleteVatRate(vatRate);
                                    }}
                                    icon={<FontAwesomeIcon icon={faTrash} />}
                                >
                                    Verwijderen
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    }

    public render(): React.ReactNode {
        const { vatRatesStore, notificationStore, userStore } = this.props;

        return (
            <Page
                title="BTW-tarievenoverzicht"
                actions={(
                    <Button
                        variant="primary"
                        id="create-vat"
                        disabled={!userStore?.currentCompany}
                        onClick={() => {
                            this.createVatRateModalOpen = true;
                        }}
                    >
                        Toevoegen
                    </Button>
                )}
            >
                <Container center className="w-3/4">
                    <UsesCompany
                        onCompanySelected={() => {
                            this.refreshVatRates();
                        }}
                    >
                        <Card>
                            {vatRatesStore?.isLoading('get-vat-rates') ? 'Loading...' : this.renderVatRatesList()}
                        </Card>
                    </UsesCompany>
                </Container>
                <Modal
                    isOpen={this.createVatRateModalOpen}
                    onRequestClose={() => {
                        notificationStore?.clear(CreateVatRateModal.NOTIFICATION_NAMESPACE);
                        this.createVatRateModalOpen = false;
                    }}
                    title="BTW-tarief toevoegen"
                    closable
                >
                    <CreateVatRateModal
                        onVatRateCreated={() => this.onVatRateCreated()}
                    />
                </Modal>
                <Modal
                    isOpen={this.editVatRateModalOpen && !!this.editVatRate}
                    onRequestClose={() => {
                        notificationStore?.clear(EditVatRateModal.NOTIFICATION_NAMESPACE);
                        this.editVatRateModalOpen = false;
                        this.editVatRate = undefined;
                    }}
                    title="BTW-tarief aanpassen"
                    closable
                >
                    <EditVatRateModal
                        vatRate={this.editVatRate!}
                        onVatRateEdited={() => this.onVatRateEdited()}
                    />
                </Modal>
            </Page>
        );
    }
}

export default VatRatesListPage;
