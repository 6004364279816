import * as React from 'react';
import clsx from 'clsx';

type ListProps = React.HTMLAttributes<HTMLDivElement>;

const List = React.forwardRef<HTMLDivElement, ListProps>((
    {
        children,
        className
    },
    ref
): React.ReactElement => (
    <div
        ref={ref}
        className={clsx(
            'list',
            className
        )}
    >
        {children}
    </div>
));

List.displayName = 'List';

export default List;
