import { computed, observable } from 'mobx';
import { AxiosError } from 'axios';
import { authStore } from './stores/auth/AuthStore';
import { NotLoggedInError } from './stores/auth/errors/NotLoggedInError';
import { ApiError } from './errors/ApiError';
import { SomethingHappenedError } from './errors/SomethingHappenedError';
import { notificationStore } from './stores/notifications/NotificationStore';
import { NotificationType } from './stores/notifications/interfaces/INotification';
import { userStore } from './stores/user/UserStore';
import { ResponseStatus } from './services/api/ResponseStatus';
import { IDisposable } from './interfaces/IDisposable';
import { permissionStore } from './stores/permissions/PermissionStore';
import { history } from './utils/history';

export class Application implements IDisposable {
    @observable private _loaded = false;

    public async initialize(): Promise<void> {
        try {
            await authStore.getAuthenticatedUser();
        } catch (e) {
            this.handleError(e);
        } finally {
            this._loaded = true;
        }
    }

    @computed
    public get loading(): boolean {
        return !this._loaded;
    }

    public handleError(e: AxiosError | Error): void {
        if (
            e instanceof NotLoggedInError
            || (e instanceof ApiError && (e.previous as AxiosError | undefined)?.isAxiosError
                && (e.previous as AxiosError).response?.status === ResponseStatus.INVALID_CREDENTIALS)
        ) {
            this.dispose();
        } else if (e instanceof ApiError || e instanceof SomethingHappenedError) {
            if (e instanceof ApiError && (e.previous as AxiosError | undefined)?.isAxiosError
                && (e.previous as AxiosError).response?.status === ResponseStatus.FILE_TOO_LAGE) {
                notificationStore.addNotification({
                    variant: 'danger',
                    message: 'Het bestand dat wordt geüpload is te groot!',
                    once: true,
                    type: NotificationType.BACKEND_ERROR
                });
            } else {
                notificationStore.addNotification({
                    variant: 'danger',
                    message: 'Er is iets misgegaan aan onze kant. We proberen het zo sel mogelijk op te lossen!',
                    once: true,
                    type: NotificationType.BACKEND_ERROR
                });
            }
        }
    }

    public dispose(): void {
        permissionStore.dispose();
        userStore.dispose();
        authStore.dispose();
        history.push('/');
    }
}

const application = new Application();

export {
    application
};
