import * as React from 'react';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { INotification } from '../../../app/stores/notifications/interfaces/INotification';
import Button from '../Button';
import { notificationStore } from '../../../app/stores/notifications/NotificationStore';

type NotificationProps = React.HTMLAttributes<HTMLDivElement> & INotification;

const Notification = React.forwardRef<HTMLDivElement, NotificationProps>((
    {
        className,
        variant,
        message,
        messageDetails,
        closable,
        id
    },
    ref
): React.ReactElement => (
    <div
        ref={ref}
        className={clsx(
            'notification',
            variant,
            className
        )}
    >
        <div>
            <strong className="block">{message}</strong>
            {/* eslint-disable-next-line max-len */}
            {!!messageDetails && (
                <div className="mt-4 notification-message-details">{messageDetails}</div>
            )}
        </div>
        {closable && (
            <Button
                className="close"
                variant="light"
                onClick={() => {
                    notificationStore.removeNotification(id);
                }}
            >
                <FontAwesomeIcon icon={faTimes} />
            </Button>
        )}
    </div>
));

Notification.displayName = 'Notification';

export default Notification;
