import { observable } from 'mobx';
import { IDisposable } from '../../interfaces/IDisposable';

export class PermissionStore implements IDisposable {
    @observable private _permissions: string[] = [];

    public set permissions(permissions: string[]) {
        this._permissions = permissions;
    }

    public get permissions(): string[] {
        return this._permissions;
    }

    public can(permission: string): boolean {
        return this._permissions.indexOf(permission) !== -1;
    }

    public canOneOf(permissions: string[]): boolean {
        return permissions.some((permission: string) => this.can(permission));
    }

    public canAccessRoute(permissions: string[] | undefined): boolean {
        if (!permissions) {
            return true;
        }

        return permissions.every((permission) => this.can(permission));
    }

    public dispose(): void {
        this._permissions = [];
    }
}

const permissionStore = new PermissionStore();

export {
    permissionStore
};
