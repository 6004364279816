import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import Page from '../../shared/Page';
import UsesCompany from '../../shared/company/UsesCompany';
import { InvoiceStore } from '../../../app/stores/invoices/InvoiceStore';
import CreateOrEditInvoice from '../../shared/invoices/CreateOrEditInvoice';
import Button from '../../shared/Button';
import Container from '../../shared/Container';
import { IUserCompany } from '../../../app/stores/user/interfaces/IUserCompany';
import { UserStore } from '../../../app/stores/user/UserStore';
import { INewConceptInvoice } from '../../../app/stores/invoices/interfaces/INewConceptInvoice';
import { IConceptInvoice } from '../../../app/stores/invoices/interfaces/IConceptInvoice';
import { FormErrors } from '../../../app/errors/FormErrors';
import { DataInvalidError } from '../../../app/errors/DataInvalidError';
import { history } from '../../../app/utils/history';
import { IInvoice } from '../../../app/stores/invoices/interfaces/IInvoice';
import { NotificationStore } from '../../../app/stores/notifications/NotificationStore';
import { ICustomer } from '../../../app/stores/customers/interfaces/ICustomer';
import { CompanyStore } from '../../../app/stores/company/CompanyStore';
import FormSelect from '../../shared/Forms/Select';
import { ICompanyDetails } from '../../../app/stores/company/interfaces/ICompanyDetails';
import { StatusStore } from '../../../app/stores/statuses/StatusStore';
import { IStatus } from '../../../app/stores/statuses/interfaces/IStatus';
import { Status } from '../../../app/stores/statuses/Status';
import CustomerSearcher from '../../shared/search/CustomerSearcher';

export interface CreateInvoicePageProps {
    invoiceStore?: InvoiceStore;
    userStore?: UserStore;
    companyStore?: CompanyStore;
    notificationStore?: NotificationStore;
    statusStore?: StatusStore;
}

@inject('invoiceStore', 'userStore', 'notificationStore', 'companyStore', 'statusStore')
@observer
class CreateInvoicePage extends React.Component<CreateInvoicePageProps, unknown> {
    private defaultInvoiceValues: INewConceptInvoice = {
        status_uuid: '',
        company_uuid: '',
        customer_uuid: '',
        items_include_vat: false,
        items: []
    }

    @observable private invoice: INewConceptInvoice = this.defaultInvoiceValues;
    @observable private readonly formErrors: FormErrors;
    @observable private customers?: ICustomer[];
    @observable private customer?: ICustomer;
    @observable private company?: ICompanyDetails;
    @observable private statuses: IStatus[] = [];

    public constructor(props: CreateInvoicePageProps) {
        super(props);

        this.formErrors = new FormErrors();
    }

    public componentDidMount() {
        const { userStore, statusStore } = this.props;

        statusStore?.getStatuses().then((statuses: IStatus[]) => {
            this.statuses = statuses;

            statuses.forEach((status: IStatus) => {
                if (status.code === Status.CONCEPT) {
                    this.invoice.status_uuid = status.uuid;
                }
            });
        });

        if (userStore?.currentCompany) {
            this.defaultInvoiceValues.company_uuid = userStore.currentCompany.company.uuid;
            this.invoice.company_uuid = userStore.currentCompany.company.uuid;

            this.refreshCompanyDetails(userStore.currentCompany.company.uuid);
        }
    }

    private refreshCompanyDetails(companyUuid: string): void {
        const { companyStore } = this.props;

        companyStore?.getCompanyDetails(companyUuid).then(
            (company: ICompanyDetails | undefined) => {
                this.company = company;
            }
        );
    }

    private saveInvoice(): void {
        const { invoiceStore, notificationStore } = this.props;

        this.formErrors.clear();
        notificationStore?.clear();

        if (this.invoice) {
            invoiceStore?.createInvoice(this.invoice).then((invoice: IConceptInvoice | IInvoice | undefined) => {
                if (invoice) {
                    if ((invoice as IInvoice).invoice_number) {
                        history.push(`/invoices/${invoice.uuid}/details`);
                        return;
                    }

                    history.push(`/invoices/concept/${invoice.uuid}/edit`);
                    notificationStore?.addNotification({
                        variant: 'success',
                        message: `Conceptfactuur succesvol opgeslagen`
                    });
                }
            }).catch((e: DataInvalidError) => {
                this.formErrors.set(e.errors);
            });
        }
    }

    private sendInvoice(): void {
        const {
            invoiceStore,
            notificationStore
        } = this.props;

        this.formErrors.clear();
        notificationStore!.clear();

        if (this.invoice) {
            invoiceStore?.sendInvoice(this.invoice).then((invoice: IInvoice | undefined): void => {
                if (invoice) {
                    notificationStore?.addNotification({
                        variant: 'success',
                        message: `Factuur succesvol aangemaakt`
                    });
                }
            }).catch((e: DataInvalidError) => {
                this.formErrors.set(e.errors);
            });
        }
    }

    private changeCustomer(customer: ICustomer): void {
        if (this.invoice) {
            this.customer = customer;
            this.invoice.customer_uuid = customer.uuid;
        }
    }

    private changeCompany(companyUuid: string): void {
        this.defaultInvoiceValues.company_uuid = companyUuid;
        this.invoice.company_uuid = companyUuid;

        this.refreshCompanyDetails(companyUuid);
    }

    private renderCustomerSelector(): React.ReactElement {
        return (
            <CustomerSearcher
                className="mb-4 invoice-width mx-auto"
                companyUuid={this.defaultInvoiceValues.company_uuid}
                onCustomerSelected={(customer: ICustomer) => {
                    this.changeCustomer(customer);
                }}
            />
        );
    }

    public render(): React.ReactNode {
        const { invoiceStore, statusStore } = this.props;

        return (
            <Page
                title="Factuur maken"
                actions={(
                    <>
                        <FormSelect
                            disabled={!this.invoice || !this.invoice.customer_uuid}
                            className="w-auto mr-4"
                            name="state_uuid"
                            loading={statusStore?.isLoading('get-statuses')}
                            value={this.invoice.status_uuid}
                            onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                                this.invoice.status_uuid = event.target.value;
                            }}
                        >
                            {this.statuses.map((status: IStatus) => (
                                <option
                                    value={status.uuid}
                                    key={status.uuid}
                                >
                                    {status.locale}
                                </option>
                            ))}
                        </FormSelect>
                        <Button
                            id="save-invoice"
                            disabled={!this.invoice || !this.invoice.customer_uuid}
                            className="mr-4"
                            variant="secondary"
                            onClick={() => {
                                this.saveInvoice();
                            }}
                            loading={invoiceStore?.isLoading('save-concept-invoice')}
                        >
                            Opslaan
                        </Button>
                        <div className="vertical-divider" />
                        <Button
                            id="send-invoice"
                            disabled={!this.invoice || !this.invoice.customer_uuid}
                            variant="primary"
                            onClick={() => {
                                this.sendInvoice();
                            }}
                            loading={invoiceStore?.isLoading('send-invoice')}
                        >
                            Factuur versturen
                        </Button>
                    </>
                )}
            >
                <Container center className="w-3/4">
                    <UsesCompany
                        onCompanySelected={(userCompany: IUserCompany) => {
                            this.changeCompany(userCompany.company.uuid);
                        }}
                    >
                        {this.renderCustomerSelector()}
                        <CreateOrEditInvoice<INewConceptInvoice>
                            invoice={this.invoice}
                            customer={this.customer}
                            company={this.company}
                            onInvoiceChanged={(invoice: INewConceptInvoice) => {
                                this.invoice = invoice;
                            }}
                            errors={this.formErrors}
                        />
                    </UsesCompany>
                </Container>
            </Page>
        );
    }
}

export default CreateInvoicePage;
