interface Params {
    page?: string;
}

export default (params: Params) => {
    if (Number(params.page) > 0) {
        return Number(params.page);
    }

    return 1;
};
