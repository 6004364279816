import * as React from 'react';
import clsx from 'clsx';

type CardProps = React.HTMLAttributes<HTMLDivElement>;

const Card = React.forwardRef<HTMLDivElement, CardProps>((
    {
        children,
        className,
        title
    },
    ref
): React.ReactElement => (
    <>
        {title && (
            <div className="flex items-center mb-2">
                <h2>{title}</h2>
            </div>
        )}
        <div
            ref={ref}
            className={clsx(
                'card',
                className
            )}
        >
            {children}
        </div>
    </>
));

Card.displayName = 'Card';

export default Card;
