import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import Page from '../../shared/Page';
import Card from '../../shared/Card';
import { CompanyStore } from '../../../app/stores/company/CompanyStore';
import Container from '../../shared/Container';
import FormGroup from '../../shared/Forms/Group';
import FormLabel from '../../shared/Forms/Label';
import { FormErrors } from '../../../app/errors/FormErrors';
import FormInput from '../../shared/Forms/Input';
import { INewCompany } from '../../../app/stores/company/interfaces/INewCompany';
import FormRow from '../../shared/Forms/Row';
import FormSelect from '../../shared/Forms/Select';
import { CountryStore } from '../../../app/stores/country/CountryStore';
import { ICountry } from '../../../app/stores/country/interfaces/ICountry';
import Button from '../../shared/Button';
import { NotificationStore } from '../../../app/stores/notifications/NotificationStore';
import { DataInvalidError } from '../../../app/errors/DataInvalidError';
import { ICompany } from '../../../app/stores/company/interfaces/ICompany';
import FormMessage from '../../shared/Forms/Message';
import { UserStore } from '../../../app/stores/user/UserStore';
import FormHelper from '../../shared/Forms/FormHelper';
import { InvoiceStore } from '../../../app/stores/invoices/InvoiceStore';
import FormFile from '../../shared/Forms/File';
import KvkSearcher from '../../shared/search/KvkSearcher';
import { IKvkEntry } from '../../../app/stores/kvk/interfaces/IKvkEntry';
import { IUserCompany } from '../../../app/stores/user/interfaces/IUserCompany';
import { TimezoneStore } from '../../../app/stores/timezones/TimezoneStore';
import { ITimezone } from '../../../app/stores/timezones/interfaces/ITimezone';

export interface CreateCompanyPageProps {
    companyStore?: CompanyStore;
    userStore?: UserStore;
    countryStore?: CountryStore;
    notificationStore?: NotificationStore;
    invoiceStore?: InvoiceStore;
    timezoneStore?: TimezoneStore;
}

@inject('companyStore', 'countryStore', 'notificationStore', 'userStore', 'invoiceStore', 'timezoneStore')
@observer
class CreateCompanyPage extends React.Component<CreateCompanyPageProps, unknown> {
    @observable private timezones: ITimezone[] = [];
    private defaultFormValues: INewCompany = {
        name: '',
        coc_number: '',
        address_street: '',
        address_number: '',
        address_number_addition: '',
        address_postcode: '',
        address_city: '',
        vat_number: '',
        country_uuid: '',
        bank_number: '',
        bank_holder_name: '',
        invoice_number_format: '',
        quote_number_format: '',
        logo: null,
        parent_uuid: null,
        timezone_uuid: ''
    }
    @observable private logo?: File;
    @observable private formErrors: FormErrors;
    @observable private formValues: INewCompany;

    public constructor(props: CreateCompanyPageProps) {
        super(props);

        this.formValues = this.defaultFormValues;
        this.formErrors = new FormErrors();
    }

    public componentDidMount() {
        const { countryStore, timezoneStore } = this.props;

        timezoneStore?.getTimezones().then((timezones: ITimezone[]) => {
            this.timezones = timezones;
        });

        countryStore?.getCountries().then(() => {
            const defaultCountry = countryStore
                ?.countries
                .find((country: ICountry) => country.name === 'Nederland');

            /* istanbul ignore next */
            if (defaultCountry) {
                this.formValues.country_uuid = defaultCountry.uuid;
                this.defaultFormValues.country_uuid = defaultCountry.uuid;
            }
        });
    }

    private onCompanySearched(company: IKvkEntry): void {
        this.formValues = {
            ...this.formValues,
            address_street: company.address_street || '',
            address_city: company.address_city || '',
            address_number: company.address_number || '',
            address_postcode: company.address_postcode || '',
            address_number_addition: company.address_number_addition,
            coc_number: company.coc_number,
            name: company.name
        };
    }

    private async submitCompany(event: React.FormEvent): Promise<void> {
        event.preventDefault();

        const { notificationStore, companyStore, userStore } = this.props;

        this.formErrors.clear();
        notificationStore?.clear();

        companyStore?.createCompany(this.formValues).then((company: ICompany | undefined): void => {
            /* istanbul ignore else */
            if (company) {
                userStore?.getCompanies();
                this.formValues = this.defaultFormValues;
                notificationStore?.addNotification({
                    variant: 'success',
                    message: `Bedrijf ${company.name} succesvol gemaakt`,
                    closable: true
                });
            }
        }).catch((e: DataInvalidError) => {
            notificationStore?.addNotification({
                variant: 'danger',
                message: e.message,
                closable: true
            });
            this.formErrors.set(e.errors);
        });
    }

    public render(): React.ReactNode {
        const {
            countryStore, companyStore, invoiceStore, userStore, timezoneStore
        } = this.props;

        return (
            <Page title="Bedrijf toevoegen">
                <Container center className="w-3/4">
                    <Card>
                        <form
                            id="create-company-form"
                            onSubmit={(event: React.FormEvent) => this.submitCompany(event)}
                        >
                            <div className="form-section">
                                <KvkSearcher
                                    onCompanySelected={(company: IKvkEntry) => {
                                        this.onCompanySearched(company);
                                    }}
                                />
                            </div>
                            <div className="form-section grid grid-cols-2">
                                <div>
                                    <h2 className="mb-4">Bedrijfsgegevens</h2>

                                    <FormGroup>
                                        <FormLabel>Naam van het bedrijf</FormLabel>
                                        <FormInput
                                            name="name"
                                            maxLength={50}
                                            value={this.formValues.name}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                this.formValues.name = event.target.value;
                                            }}
                                            hasError={this.formErrors.has('name')}
                                        />
                                        {this.formErrors.has('name') && (
                                            <FormMessage type="invalid">
                                                {this.formErrors.first('name')}
                                            </FormMessage>
                                        )}
                                    </FormGroup>
                                    <FormGroup>
                                        <FormLabel>Dochterbedrijf van</FormLabel>
                                        <FormSelect
                                            name="parent_uuid"
                                            value={this.formValues.name}
                                            onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                                                this.formValues.parent_uuid = event.target.value;
                                            }}
                                            hasError={this.formErrors.has('parent_uuid')}
                                        >
                                            <option value="">Kiezen...</option>
                                            {userStore?.companies.map((userCompany: IUserCompany) => (
                                                <option
                                                    key={userCompany.uuid}
                                                    value={userCompany.company.uuid}
                                                >
                                                    {userCompany.company.name}
                                                </option>
                                            ))}
                                        </FormSelect>
                                        {this.formErrors.has('parent_uuid') && (
                                            <FormMessage type="invalid">
                                                {this.formErrors.first('parent_uuid')}
                                            </FormMessage>
                                        )}
                                    </FormGroup>
                                    <FormGroup>
                                        <FormLabel>KvK-nummer</FormLabel>
                                        <FormInput
                                            name="coc_number"
                                            maxLength={8}
                                            value={this.formValues.coc_number}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                this.formValues.coc_number = event.target.value;
                                            }}
                                            hasError={this.formErrors.has('coc_number')}
                                        />
                                        {this.formErrors.has('coc_number') && (
                                            <FormMessage type="invalid">
                                                {this.formErrors.first('coc_number')}
                                            </FormMessage>
                                        )}
                                    </FormGroup>
                                </div>
                            </div>
                            <div className="form-section grid grid-cols-2">
                                <FormGroup>
                                    <FormLabel>Logo</FormLabel>
                                    <FormFile
                                        name="logo"
                                        type="file"
                                        accept=".jpeg,.jpg,.png,.svg,.gif"
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            if (event.target.files && event.target.files.length) {
                                                // eslint-disable-next-line prefer-destructuring
                                                this.formValues.logo = event.target.files[0];
                                            } else {
                                                this.formValues.logo = undefined;
                                            }
                                        }}
                                        // hasError={this.formErrors.has('coc_number')}
                                    />
                                    {this.formErrors.has('logo') && (
                                        <FormMessage type="invalid">
                                            {this.formErrors.first('logo')}
                                        </FormMessage>
                                    )}
                                </FormGroup>
                                <div className="ml-4 mb-4 flex items-center">
                                    {this.formValues.logo && (
                                        <img
                                            alt="Company logo"
                                            src={URL.createObjectURL(this.formValues.logo)}
                                            className="company-logo"
                                        />
                                    )}
                                </div>
                            </div>
                            <div className="form-section grid grid-cols-2">
                                <div>
                                    <h2 className="mb-4">Adresgegevens</h2>

                                    <FormRow>
                                        <FormGroup className="max-w-1/2">
                                            <FormLabel>Straat</FormLabel>
                                            <FormInput
                                                maxLength={50}
                                                name="address_street"
                                                value={this.formValues.address_street}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                    this.formValues.address_street = event.target.value;
                                                }}
                                                hasError={this.formErrors.has('address_street')}
                                            />
                                            {this.formErrors.has('address_street') && (
                                                <FormMessage type="invalid">
                                                    {this.formErrors.first('address_street')}
                                                </FormMessage>
                                            )}
                                        </FormGroup>
                                        <FormGroup className="max-w-1/4">
                                            <FormLabel>Nummer</FormLabel>
                                            <FormInput
                                                maxLength={10}
                                                name="address_number"
                                                value={this.formValues.address_number}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                    this.formValues.address_number = event.target.value;
                                                }}
                                                hasError={this.formErrors.has('address_number')}
                                            />
                                            {this.formErrors.has('address_number') && (
                                                <FormMessage type="invalid">
                                                    {this.formErrors.first('address_number')}
                                                </FormMessage>
                                            )}
                                        </FormGroup>
                                        <FormGroup className="max-w-1/4">
                                            <FormLabel>Toev.</FormLabel>
                                            <FormInput
                                                maxLength={10}
                                                name="address_number_addition"
                                                value={this.formValues.address_number_addition || ''}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                    this.formValues.address_number_addition = event.target.value;
                                                }}
                                                hasError={this.formErrors.has('address_number_addition')}
                                            />
                                            {this.formErrors.has('address_number_addition') && (
                                                <FormMessage type="invalid">
                                                    {this.formErrors.first('address_number_addition')}
                                                </FormMessage>
                                            )}
                                        </FormGroup>
                                    </FormRow>
                                    <FormRow>
                                        <FormGroup className="max-w-1/4">
                                            <FormLabel>Postcode</FormLabel>
                                            <FormInput
                                                maxLength={7}
                                                name="address_postcode"
                                                value={this.formValues.address_postcode}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                    this.formValues.address_postcode = event.target.value;
                                                }}
                                                hasError={this.formErrors.has('address_postcode')}
                                            />
                                            {this.formErrors.has('address_postcode') && (
                                                <FormMessage type="invalid">
                                                    {this.formErrors.first('address_postcode')}
                                                </FormMessage>
                                            )}
                                        </FormGroup>
                                        <FormGroup>
                                            <FormLabel>Stad</FormLabel>
                                            <FormInput
                                                name="address_city"
                                                maxLength={50}
                                                value={this.formValues.address_city}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                    this.formValues.address_city = event.target.value;
                                                }}
                                                hasError={this.formErrors.has('address_city')}
                                            />
                                            {this.formErrors.has('address_city') && (
                                                <FormMessage type="invalid">
                                                    {this.formErrors.first('address_city')}
                                                </FormMessage>
                                            )}
                                        </FormGroup>
                                    </FormRow>
                                    <FormGroup>
                                        <FormLabel>Land</FormLabel>
                                        <FormSelect
                                            loading={countryStore?.isInitialOrLoading('get-countries')}
                                            name="country_uuid"
                                            value={this.formValues.country_uuid}
                                            onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                                                this.formValues.country_uuid = event.target.value;
                                            }}
                                            hasError={this.formErrors.has('country_uuid')}
                                        >
                                            <option value="">Kiezen...</option>
                                            {countryStore?.countries.map((country: ICountry) => (
                                                <option value={country.uuid} key={country.uuid}>
                                                    {country.name}
                                                </option>
                                            ))}
                                        </FormSelect>
                                        {this.formErrors.has('country_uuid') && (
                                            <FormMessage type="invalid">
                                                {this.formErrors.first('country_uuid')}
                                            </FormMessage>
                                        )}
                                    </FormGroup>
                                    <FormGroup>
                                        <FormLabel>Tijdzone</FormLabel>
                                        <FormSelect
                                            name="timezone_uuid"
                                            value={this.formValues.timezone_uuid}
                                            loading={timezoneStore?.isLoading('get-timezones')}
                                            onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                                                this.formValues.timezone_uuid = event.target.value;
                                            }}
                                            hasError={this.formErrors.has('timezone_uuid')}
                                        >
                                            <option value="">Kiezen...</option>
                                            {this.timezones.map((timezone: ITimezone) => (
                                                <option
                                                    key={timezone.uuid}
                                                    value={timezone.uuid}
                                                >
                                                    {timezone.name}
                                                </option>
                                            ))}
                                        </FormSelect>
                                        {this.formErrors.has('timezone_uuid') && (
                                            <FormMessage type="invalid">
                                                {this.formErrors.first('timezone_uuid')}
                                            </FormMessage>
                                        )}
                                    </FormGroup>
                                </div>
                            </div>
                            <div className="form-section grid grid-cols-2">
                                <div>
                                    <h2 className="mb-4">Financiële gegevens</h2>
                                    <FormGroup>
                                        <FormLabel>BTW-identificatienummer</FormLabel>
                                        <FormInput
                                            maxLength={20}
                                            name="vat_number"
                                            value={this.formValues.vat_number}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                this.formValues.vat_number = event.target.value;
                                            }}
                                            hasError={this.formErrors.has('vat_number')}
                                        />
                                        {this.formErrors.has('vat_number') && (
                                            <FormMessage type="invalid">
                                                {this.formErrors.first('vat_number')}
                                            </FormMessage>
                                        )}
                                    </FormGroup>
                                    <FormGroup>
                                        <FormLabel>Rekeningnummer</FormLabel>
                                        <FormInput
                                            maxLength={20}
                                            name="bank_number"
                                            value={this.formValues.bank_number}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                this.formValues.bank_number = event.target.value;
                                            }}
                                            hasError={this.formErrors.has('bank_number')}
                                        />
                                        {this.formErrors.has('bank_number') && (
                                            <FormMessage type="invalid">
                                                {this.formErrors.first('bank_number')}
                                            </FormMessage>
                                        )}
                                    </FormGroup>
                                    <FormGroup>
                                        <FormLabel>Rekeningnummer t.n.v.</FormLabel>
                                        <FormInput
                                            maxLength={100}
                                            name="bank_holder_name"
                                            value={this.formValues.bank_holder_name}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                this.formValues.bank_holder_name = event.target.value;
                                            }}
                                            hasError={this.formErrors.has('bank_holder_name')}
                                        />
                                        {this.formErrors.has('bank_holder_name') && (
                                            <FormMessage type="invalid">
                                                {this.formErrors.first('bank_holder_name')}
                                            </FormMessage>
                                        )}
                                    </FormGroup>
                                </div>
                            </div>
                            <div className="form-section grid grid-cols-2">
                                <div>
                                    <h2 className="mb-4">Factuurinstellingen</h2>
                                    <FormGroup>
                                        <FormLabel>Factuurnummer</FormLabel>
                                        <FormInput
                                            maxLength={50}
                                            name="invoice_number_format"
                                            value={this.formValues.invoice_number_format}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                this.formValues.invoice_number_format = event.target.value;
                                            }}
                                            hasError={this.formErrors.has('invoice_number_format')}
                                        />
                                        {this.formErrors.has('invoice_number_format') && (
                                            <FormMessage type="invalid">
                                                {this.formErrors.first('invoice_number_format')}
                                            </FormMessage>
                                        )}
                                        {!!this.formValues.invoice_number_format && (
                                            <FormHelper id="invoice-number-example">
                                                {`Voorbeeld: ${invoiceStore?.getInvoiceNumberExample(
                                                    this.formValues.invoice_number_format
                                                )}`}
                                            </FormHelper>
                                        )}
                                    </FormGroup>
                                    Te gebruiken variabelen:
                                    <div className="mb-4">
                                        {invoiceStore?.renderDocumentNumberVariables((key: string) => {
                                            // eslint-disable-next-line max-len
                                            this.formValues.invoice_number_format = this.formValues.invoice_number_format
                                                ? this.formValues.invoice_number_format + key : key;
                                        })}
                                    </div>
                                </div>
                            </div>
                            <div className="form-section grid grid-cols-2">
                                <div>
                                    <h2 className="mb-4">Offerteïnstellingen</h2>
                                    <FormGroup>
                                        <FormLabel>Offertenummer</FormLabel>
                                        <FormInput
                                            maxLength={50}
                                            name="quote_number_format"
                                            value={this.formValues.quote_number_format}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                this.formValues.quote_number_format = event.target.value;
                                            }}
                                            hasError={this.formErrors.has('quote_number_format')}
                                        />
                                        {this.formErrors.has('quote_number_format') && (
                                            <FormMessage type="invalid">
                                                {this.formErrors.first('quote_number_format')}
                                            </FormMessage>
                                        )}
                                        {!!this.formValues.quote_number_format && (
                                            <FormHelper id="quote-number-example">
                                                {`Voorbeeld: ${invoiceStore?.getInvoiceNumberExample(
                                                    this.formValues.quote_number_format
                                                )}`}
                                            </FormHelper>
                                        )}
                                    </FormGroup>
                                    Te gebruiken variabelen:
                                    <div className="mb-4">
                                        {invoiceStore?.renderDocumentNumberVariables((key: string) => {
                                            // eslint-disable-next-line max-len
                                            this.formValues.quote_number_format = this.formValues.quote_number_format
                                                ? this.formValues.quote_number_format + key : key;
                                        })}
                                    </div>
                                </div>
                            </div>
                            <Button
                                type="submit"
                                variant="primary"
                                loading={companyStore?.isLoading('create-company')}
                            >
                                Bedrijf maken
                            </Button>
                        </form>
                    </Card>
                </Container>
            </Page>
        );
    }
}

export default CreateCompanyPage;
