import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import FormGroup from '../Forms/Group';
import FormInput from '../Forms/Input';
import Button from '../Button';
import { FormErrors } from '../../../app/errors/FormErrors';
import { IVatRate } from '../../../app/stores/vat-rates/interfaces/IVatRate';
import FormSelect from '../Forms/Select';

export interface InvoiceItemProps extends React.HTMLAttributes<HTMLTableRowElement> {
    onItemRemoved: () => void;
    onDescriptionChange: (value: string) => void;
    description: string;
    onPriceChange: (value: string) => void;
    price: string;
    onQuantityChange: (value: string) => void;
    quantity: string;
    onVatRateChanged: (value: string) => void;
    vatRateUuid: string;
    errors: FormErrors;
    vatRates: IVatRate[];
}

const InvoiceItem = React.forwardRef<HTMLTableRowElement, InvoiceItemProps>((
    {
        onDescriptionChange,
        description,
        onQuantityChange,
        quantity,
        onPriceChange,
        price,
        onItemRemoved,
        errors,
        onVatRateChanged,
        vatRates,
        vatRateUuid
    },
    _ref
): React.ReactElement => {
    const calculateTotal = (): string => {
        // @TODO let the backend calculate item total
        // quantity = quantity.replace(/\./g, '');
        price = price.replace(/\./g, '');

        if (
            Number.isNaN(Number(quantity.replace(',', '.')))
            || Number.isNaN(Number(price.replace(',', '.')))
        ) {
            return new Intl.NumberFormat('nl-NL', { minimumFractionDigits: 2 }).format(0);
        }

        return new Intl.NumberFormat('nl-NL', { minimumFractionDigits: 2 }).format(parseFloat(String(
            Number(quantity.replace(',', '.'))
            * Number(price.replace(',', '.'))
        )));
    };

    return (
        <>
            <FormGroup>
                <FormInput
                    name="description"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                        onDescriptionChange(event.target.value);
                    }}
                    value={description}
                    hasError={errors.has('description')}
                />
            </FormGroup>
            <FormGroup>
                <FormInput
                    name="quantity"
                    type="text"
                    maxLength={10}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                        onQuantityChange(event.target.value);
                    }}
                    value={quantity}
                    hasError={errors.has('quantity')}
                />
            </FormGroup>
            <FormGroup>
                <FormInput
                    name="price"
                    type="text"
                    subType="currency"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                        onPriceChange(event.target.value);
                    }}
                    value={price}
                    hasError={errors.has('price')}
                />
            </FormGroup>
            <FormGroup>
                <FormInput
                    disabled
                    value={calculateTotal()}
                    name="item_total"
                />
            </FormGroup>
            <FormGroup>
                <FormSelect
                    name="vat_rate_uuid"
                    onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                        onVatRateChanged(event.target.value);
                    }}
                    value={vatRateUuid || ''}
                    hasError={errors.has('vat_rate_uuid')}
                >
                    <option value="">BTW-tarief</option>
                    {vatRates.map((v: IVatRate) => (
                        <option
                            key={v.uuid}
                            value={v.uuid}
                        >
                            {`${v.name} - ${v.percentage}%`}
                        </option>
                    ))}
                </FormSelect>
            </FormGroup>
            <div
                className="invoice-item-actions"
            >
                <Button
                    className="mt-auto remove-invoice-item"
                    variant="secondary"
                    onClick={onItemRemoved}
                >
                    <FontAwesomeIcon icon={faTrash} />
                </Button>
            </div>
        </>
    );
});

InvoiceItem.displayName = 'InvoiceItem';

export default InvoiceItem;
