import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import { RouteComponentProps, withRouter } from 'react-router';
import moment from 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCopy,
    faDownload, faMoneyBillWave, faRedo, faSync
} from '@fortawesome/free-solid-svg-icons';
import { DropdownItem } from 'reactstrap';
import { UserStore } from '../../../app/stores/user/UserStore';
import { CompanyStore } from '../../../app/stores/company/CompanyStore';
import { PaymentStore } from '../../../app/stores/payments/PaymentStore';
import Page from '../../shared/Page';
import Container from '../../shared/Container';
import UsesCompany from '../../shared/company/UsesCompany';
import getPage from '../../shared/Paginate/helpers/getPage';
import { IListAllInvoices } from '../../../app/stores/invoices/interfaces/IListAllInvoices';
import generateGuid from '../../../app/utils/generateGuid';
import Card from '../../shared/Card';
import Notification from '../../shared/Notification';
import { IListConceptInvoice } from '../../../app/stores/invoices/interfaces/IListConceptInvoice';
import { history } from '../../../app/utils/history';
import Paginate from '../../shared/Paginate';
import { IListInvoice } from '../../../app/stores/invoices/interfaces/IListInvoice';
import Status from '../../shared/Status';
import { PermissionStore } from '../../../app/stores/permissions/PermissionStore';
import { Status as StatusType } from '../../../app/stores/statuses/Status';
import { RecurringInvoiceStore } from '../../../app/stores/recurring-invoices/RecurringInvoiceStore';
import { InvoiceStore } from '../../../app/stores/invoices/InvoiceStore';
import Dropdown from '../../shared/Dropdown';
import { IConceptInvoice } from '../../../app/stores/invoices/interfaces/IConceptInvoice';
import { NotificationStore } from '../../../app/stores/notifications/NotificationStore';

export interface InvoiceListPageParams {
    page?: string;
}

export interface InvoiceListPageProps extends RouteComponentProps<InvoiceListPageParams> {
    userStore?: UserStore;
    companyStore?: CompanyStore;
    paymentStore?: PaymentStore;
    permissionStore?: PermissionStore;
    recurringInvoiceStore?: RecurringInvoiceStore;
    invoiceStore?: InvoiceStore;
    notificationStore?: NotificationStore;
}

@inject('userStore', 'companyStore', 'paymentStore', 'permissionStore', 'recurringInvoiceStore', 'invoiceStore',
    'notificationStore')
@observer
class InvoiceListPage extends React.Component<InvoiceListPageProps, unknown> {
    @observable private invoiceList?: IListAllInvoices;

    public componentDidMount() {
        const { userStore, match: { params } } = this.props;

        if (userStore?.currentCompany) {
            this.refreshInvoices(userStore.currentCompany.company.uuid, getPage(params));
        }
    }

    private handlePageChange(selected: number): void {
        const { userStore } = this.props;

        if (userStore?.currentCompany) {
            history.push(`/invoices/${selected + 1}`);
            this.refreshInvoices(userStore.currentCompany.company.uuid, selected + 1);
        }
    }

    private onAddPayment(event: React.MouseEvent, invoice: IListInvoice): void {
        event.stopPropagation();

        const { paymentStore } = this.props;

        if (paymentStore) {
            paymentStore.activeInvoice = invoice;
        }
    }

    private onMakeInvoiceRecurring(event: React.MouseEvent, invoice: IListInvoice): void {
        event.stopPropagation();

        const { recurringInvoiceStore } = this.props;

        if (recurringInvoiceStore) {
            recurringInvoiceStore.activeInvoice = invoice;
        }
    }

    private refreshInvoices(companyUuid: string, page: number): void {
        const { companyStore } = this.props;

        companyStore?.getAllInvoices(companyUuid, page)
            .then((invoices: IListAllInvoices | undefined): void => {
                if (invoices) {
                    this.invoiceList = invoices;
                }
            });
    }

    private downloadInvoice(invoice: IListInvoice): void {
        const { invoiceStore } = this.props;

        invoiceStore?.downloadInvoice(invoice.uuid, invoice.invoice_number);
    }

    private createCreditInvoice(invoice: IListInvoice): void {
        const { invoiceStore, notificationStore } = this.props;

        invoiceStore?.createCreditInvoice(invoice.uuid)
            .then((conceptInvoice: IConceptInvoice | undefined) => {
                if (conceptInvoice) {
                    history.push(`/invoices/${conceptInvoice.uuid}/edit`);
                    notificationStore?.addNotification({
                        message: 'Credietfactuur succesvol gemaakt',
                        variant: 'success'
                    });
                }
            });
    }

    private copyInvoice(invoice: IListInvoice): void {
        const { invoiceStore, notificationStore } = this.props;

        invoiceStore?.copyInvoice(invoice.uuid)
            .then((conceptInvoice: IConceptInvoice | undefined) => {
                if (conceptInvoice) {
                    history.push(`/invoices/${conceptInvoice.uuid}/edit`);
                    notificationStore?.addNotification({
                        message: 'Factuur succesvol gekopiëerd',
                        variant: 'success'
                    });
                }
            });
    }

    private renderConceptInvoices(): React.ReactNode {
        const { companyStore } = this.props;

        return (
            <div className="mb-4">
                <h2>Conceptfacturen</h2>
                <Card>
                    {this.invoiceList && !this.invoiceList.concept_invoices.length ? (
                        <Notification
                            message="Geen conceptfacturen gevonden"
                            variant="info"
                            id={generateGuid()}
                        />
                    ) : (
                        <table className="table table-clickable" id="concept-invoices-list">
                            <thead>
                                <tr>
                                    <th>Klant</th>
                                    <th>Bedrag</th>
                                </tr>
                            </thead>
                            <tbody>
                                {companyStore?.isInitialOrLoading('get-all-invoices') ? (
                                    <tr>
                                        <td colSpan={2}>Data wordt geladen...</td>
                                    </tr>
                                ) : this.invoiceList?.concept_invoices.map((invoice: IListConceptInvoice) => (
                                    <tr
                                        key={invoice.uuid}
                                        onClick={() => {
                                            history.push(`/invoices/concept/${invoice.uuid}/edit`);
                                        }}
                                    >
                                        <td>
                                            {invoice.customer_firstname && invoice.customer_lastname
                                                ? `${invoice.customer_firstname} ${invoice.customer_lastname}` : ''}
                                        </td>
                                        <td>{`€ ${invoice.total}`}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </Card>
            </div>
        );
    }

    private renderInvoices(): React.ReactElement {
        const { companyStore, permissionStore, invoiceStore } = this.props;

        return (
            <div className="mb-4">
                <div className="flex items-center">
                    <h2>Facturen</h2>
                </div>
                <Card>
                    {this.invoiceList && !this.invoiceList.invoices.data.length ? (
                        <Notification
                            message="Geen facturen gevonden"
                            variant="info"
                            id={generateGuid()}
                        />
                    ) : (
                        <table className="table table-clickable" id="invoices-list">
                            <thead>
                                <tr>
                                    <th>Factuurnummer</th>
                                    <th>Klant</th>
                                    <th>Factuurdatum</th>
                                    <th>Verloopt op</th>
                                    <th>Bedrag</th>
                                    <th>Openstaand</th>
                                    <th>Status</th>
                                    {permissionStore?.canOneOf(['create_payment', 'create_invoice', 'create_credit_invoice']) && (
                                        // eslint-disable-next-line jsx-a11y/control-has-associated-label
                                        <th />
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                {companyStore?.isInitialOrLoading('get-all-invoices') ? (
                                    <tr>
                                        <td colSpan={2}>Data wordt geladen...</td>
                                    </tr>
                                ) : this.invoiceList?.invoices.data.map((invoice: IListInvoice) => (
                                    <tr
                                        key={invoice.uuid}
                                        onClick={() => {
                                            history.push(`/invoices/${invoice.uuid}/details`);
                                        }}
                                    >
                                        <td>
                                            {invoice.invoice_number}
                                            {invoice.is_recurring && (
                                                <Status
                                                    className="ml-4"
                                                    small
                                                    variant="recurring"
                                                    icon={<FontAwesomeIcon icon={faSync} />}
                                                >
                                                    Periodiek
                                                </Status>
                                            )}
                                        </td>
                                        <td>
                                            {`${invoice.customer_firstname} ${invoice.customer_lastname}`}
                                        </td>
                                        <td>
                                            {moment(invoice.created_at)
                                                .format('DD-MM-YYYY')}
                                        </td>
                                        <td>
                                            {moment(invoice.due_date)
                                                .format('DD-MM-YYYY')}
                                        </td>
                                        <td>{`€ ${invoice.total}`}</td>
                                        <td>{`€ ${invoice.unpaid}`}</td>
                                        <td>
                                            <Status
                                                variant={invoice.status.name}
                                            >
                                                {invoice.status.locale}
                                            </Status>
                                        </td>
                                        {permissionStore?.canOneOf(['create_payment', 'create_invoice', 'download_invoice']) && (
                                            <td>
                                                <Dropdown text="Opties">
                                                    {permissionStore?.can('create_payment')
                                                    && invoice.status.code !== StatusType.PAID && (
                                                        <DropdownItem
                                                            className="add-payment-button"
                                                            onClick={(event: React.MouseEvent) => {
                                                                this.onAddPayment(event, invoice);
                                                            }}
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={faMoneyBillWave}
                                                                className="mr-4"
                                                            />
                                                            Betaling toevoegen
                                                        </DropdownItem>
                                                    )}
                                                    {permissionStore?.can('create_invoice') && (
                                                        <DropdownItem
                                                            className="make-invoice-recurring"
                                                            onClick={(event: React.MouseEvent) => {
                                                                this.onMakeInvoiceRecurring(event, invoice);
                                                            }}
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={faRedo}
                                                                className="mr-4"
                                                            />
                                                            Periodiek maken
                                                        </DropdownItem>
                                                    )}
                                                    {permissionStore?.can('download_invoice') && (
                                                        <DropdownItem
                                                            className="make-invoice-recurring"
                                                            loading={invoiceStore?.isLoading(`download-invoice-${invoice.uuid}`)}
                                                            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                                                event.stopPropagation();
                                                                this.downloadInvoice(invoice);
                                                            }}
                                                            icon={<FontAwesomeIcon icon={faDownload} />}
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={faDownload}
                                                                className="mr-4"
                                                            />
                                                            PDF downloaden
                                                        </DropdownItem>
                                                    )}
                                                    {permissionStore?.can('create_credit_invoice') && (
                                                        <DropdownItem
                                                            className="make-credit-invoice"
                                                            loading={invoiceStore?.isLoading(`create-credit-invoice-${invoice.uuid}`)}
                                                            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                                                event.stopPropagation();
                                                                this.createCreditInvoice(invoice);
                                                            }}
                                                            icon={<FontAwesomeIcon icon={faDownload} />}
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={faDownload}
                                                                className="mr-4"
                                                            />
                                                            Credietfactuur maken
                                                        </DropdownItem>
                                                    )}
                                                    {permissionStore?.can('copy_invoice') && (
                                                        <DropdownItem
                                                            className="copy-invoice"
                                                            loading={invoiceStore?.isLoading(`copy-invoice-${invoice.uuid}`)}
                                                            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                                                event.stopPropagation();
                                                                this.copyInvoice(invoice);
                                                            }}
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={faCopy}
                                                                className="mr-4"
                                                            />
                                                            Factuur kopiëren
                                                        </DropdownItem>
                                                    )}
                                                </Dropdown>
                                            </td>
                                        )}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </Card>
            </div>
        );
    }

    public render(): React.ReactNode {
        const {
            paymentStore,
            match: { params },
            userStore,
            recurringInvoiceStore
        } = this.props;

        return (
            <Page
                title="Factuuroverzicht"
            >
                <Container center className="w-3/4">
                    <UsesCompany
                        onCompanySelected={(): void => {
                            this.handlePageChange(getPage(params) - 1);
                        }}
                    >
                        <div className="flex">
                            {!!this.invoiceList?.invoices.data.length && (
                                <Paginate
                                    pageCount={this.invoiceList.invoices.meta.last_page}
                                    className="ml-auto mb-4"
                                    onPageChange={({ selected }) => {
                                        this.handlePageChange(selected);
                                    }}
                                    forcePage={getPage(params) - 1}
                                />
                            )}
                        </div>
                        {this.renderConceptInvoices()}
                        {this.renderInvoices()}
                    </UsesCompany>
                </Container>
                {paymentStore?.useCreatePaymentModal(() => {
                    if (userStore?.currentCompany) {
                        this.refreshInvoices(
                            userStore.currentCompany.company.uuid,
                            getPage(params)
                        );
                    }
                })}
                {recurringInvoiceStore?.useModal()}
            </Page>
        );
    }
}

export default withRouter(InvoiceListPage);
