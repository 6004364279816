import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import { ICompanyDetails } from '../../../app/stores/company/interfaces/ICompanyDetails';
import { CompanyStore } from '../../../app/stores/company/CompanyStore';
import { UserStore } from '../../../app/stores/user/UserStore';
import Page from '../../shared/Page';
import UsesCompany from '../../shared/company/UsesCompany';
import Card from '../../shared/Card';
import FormGroup from '../../shared/Forms/Group';
import FormLabel from '../../shared/Forms/Label';
import FormInput from '../../shared/Forms/Input';
import FormMessage from '../../shared/Forms/Message';
import FormRow from '../../shared/Forms/Row';
import FormSelect from '../../shared/Forms/Select';
import { ICountry } from '../../../app/stores/country/interfaces/ICountry';
import Button from '../../shared/Button';
import Container from '../../shared/Container';
import { FormErrors } from '../../../app/errors/FormErrors';
import { CountryStore } from '../../../app/stores/country/CountryStore';
import { DataInvalidError } from '../../../app/errors/DataInvalidError';
import FormHelper from '../../shared/Forms/FormHelper';
import { InvoiceStore } from '../../../app/stores/invoices/InvoiceStore';
import { NotificationStore } from '../../../app/stores/notifications/NotificationStore';
import FormFile from '../../shared/Forms/File';
import base64ToFile from '../../../app/utils/base64ToFile';
import base64ToExtension from '../../../app/utils/base64ToExtension';
import { IUserCompany } from '../../../app/stores/user/interfaces/IUserCompany';
import { ITimezone } from '../../../app/stores/timezones/interfaces/ITimezone';
import { TimezoneStore } from '../../../app/stores/timezones/TimezoneStore';

export interface EditCompanyPageProps {
    companyStore?: CompanyStore;
    userStore?: UserStore;
    countryStore?: CountryStore;
    invoiceStore?: InvoiceStore;
    notificationStore?: NotificationStore;
    timezoneStore?: TimezoneStore;
}

@inject('companyStore', 'userStore', 'countryStore', 'invoiceStore', 'notificationStore', 'timezoneStore')
@observer
class EditCompanyPage extends React.Component<EditCompanyPageProps, unknown> {
    @observable private timezones: ITimezone[] = [];
    @observable private company?: ICompanyDetails;
    @observable private logo?: File;
    @observable private formErrors: FormErrors;

    public constructor(props: EditCompanyPageProps) {
        super(props);

        this.formErrors = new FormErrors();
    }

    public componentDidMount(): void {
        const { countryStore, timezoneStore } = this.props;

        this.refreshCompany();

        timezoneStore?.getTimezones().then((timezones: ITimezone[]) => {
            this.timezones = timezones;
        });

        countryStore?.getCountries();
    }

    private refreshCompany(): void {
        const { companyStore, userStore } = this.props;

        if (userStore?.currentCompany) {
            companyStore?.getCompanyDetails(
                userStore.currentCompany.company.uuid
            ).then((company: ICompanyDetails | undefined) => {
                if (company) {
                    this.company = company;
                    this.logo = company.logo
                        ? base64ToFile(company.logo!, `logo.${base64ToExtension(company.logo)}`)
                        : undefined;
                }
            }).catch(() => {
                this.company = undefined;
            });
        }
    }

    private editCompany(event: React.FormEvent): void {
        const { companyStore, userStore, notificationStore } = this.props;

        event.preventDefault();

        this.formErrors.clear();
        notificationStore?.clear();

        if (userStore?.currentCompany && this.company) {
            companyStore?.editCompany(
                userStore.currentCompany.company.uuid,
                this.company,
                this.logo
            ).then((companyDetails: ICompanyDetails | undefined) => {
                if (companyDetails) {
                    this.company = companyDetails;

                    this.logo = companyDetails.logo
                        ? base64ToFile(companyDetails.logo, 'logo')
                        : undefined;

                    if (userStore.currentCompany
                        && userStore.currentCompany.company.uuid === companyDetails.company_uuid
                    ) {
                        userStore.currentCompany.company = {
                            ...userStore.currentCompany.company,
                            logo: companyDetails.logo,
                            name: companyDetails.name,
                            parent: companyDetails.parent
                        };
                    }
                    notificationStore?.addNotification({
                        message: 'Bedrijf succesvol aangepast',
                        variant: 'success',
                        closable: true
                    });
                }
            }).catch((e: DataInvalidError) => {
                this.formErrors.set(e.errors);
            });
        }
    }

    public render(): React.ReactNode {
        const {
            companyStore, countryStore, invoiceStore, userStore, timezoneStore
        } = this.props;

        return (
            <Page title="Bedrijf aanpassen">
                <UsesCompany
                    onCompanySelected={() => {
                        this.refreshCompany();
                    }}
                >
                    <Container center className="w-3/4">
                        {this.company && (
                            <Card>
                                <form onSubmit={(event: React.FormEvent) => this.editCompany(event)}>
                                    <div className="form-section grid grid-cols-2">
                                        <div>
                                            <h2 className="mb-4">Bedrijfsgegevens</h2>

                                            <FormGroup>
                                                <FormLabel>Naam van het bedrijf</FormLabel>
                                                <FormInput
                                                    name="name"
                                                    maxLength={50}
                                                    value={this.company.name}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                                    this.company!.name = event.target.value;
                                                    }}
                                                    hasError={this.formErrors.has('name')}
                                                />
                                                {this.formErrors.has('name') && (
                                                    <FormMessage type="invalid">
                                                        {this.formErrors.first('name')}
                                                    </FormMessage>
                                                )}
                                            </FormGroup>
                                            <FormGroup>
                                                <FormLabel>Dochterbedrijf van</FormLabel>
                                                <FormSelect
                                                    name="parent_uuid"
                                                    value={this.company.parent_uuid || ''}
                                                    onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                                                        this.company!.parent_uuid = event.target.value;
                                                    }}
                                                    hasError={this.formErrors.has('parent_uuid')}
                                                >
                                                    <option value="">Kiezen...</option>
                                                    {userStore?.companiesExcept(this.company.company_uuid)
                                                        .map((userCompany: IUserCompany) => (
                                                            <option
                                                                key={userCompany.uuid}
                                                                value={userCompany.company.uuid}
                                                            >
                                                                {userCompany.company.name}
                                                            </option>
                                                        ))}
                                                </FormSelect>
                                                {this.formErrors.has('parent_uuid') && (
                                                    <FormMessage type="invalid">
                                                        {this.formErrors.first('parent_uuid')}
                                                    </FormMessage>
                                                )}
                                            </FormGroup>
                                        </div>
                                    </div>
                                    <div className="form-section grid grid-cols-2">
                                        <FormGroup>
                                            <FormLabel>Logo</FormLabel>
                                            <FormFile
                                                name="logo"
                                                type="file"
                                                accept=".jpeg,.jpg,.png,.svg,.gif"
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                    if (event.target.files && event.target.files.length) {
                                                        // eslint-disable-next-line prefer-destructuring
                                                        this.logo = event.target.files[0];
                                                    } else {
                                                        this.logo = undefined;
                                                    }
                                                }}
                                                hasError={this.formErrors.has('coc_number')}
                                            />
                                            {this.formErrors.has('logo') && (
                                                <FormMessage type="invalid">
                                                    {this.formErrors.first('logo')}
                                                </FormMessage>
                                            )}
                                        </FormGroup>
                                        <div className="ml-4 mb-4 flex items-center">
                                            {this.logo && (
                                                <img
                                                    id="logo-preview"
                                                    alt="Company logo"
                                                    src={URL.createObjectURL(this.logo)}
                                                    className="company-logo"
                                                />
                                            )}
                                        </div>
                                    </div>
                                    <div className="form-section grid grid-cols-2">
                                        <div>
                                            <h2 className="mb-4">Adresgegevens</h2>

                                            <FormRow>
                                                <FormGroup className="max-w-1/2">
                                                    <FormLabel>Straat</FormLabel>
                                                    <FormInput
                                                        maxLength={50}
                                                        name="address_street"
                                                        value={this.company.address_street}
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                            this.company!.address_street = event.target.value;
                                                        }}
                                                        hasError={this.formErrors.has('address_street')}
                                                    />
                                                    {this.formErrors.has('address_street') && (
                                                        <FormMessage type="invalid">
                                                            {this.formErrors.first('address_street')}
                                                        </FormMessage>
                                                    )}
                                                </FormGroup>
                                                <FormGroup className="max-w-1/4">
                                                    <FormLabel>Nummer</FormLabel>
                                                    <FormInput
                                                        maxLength={10}
                                                        name="address_number"
                                                        value={this.company.address_number}
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                            this.company!.address_number = event.target.value;
                                                        }}
                                                        hasError={this.formErrors.has('address_number')}
                                                    />
                                                    {this.formErrors.has('address_number') && (
                                                        <FormMessage type="invalid">
                                                            {this.formErrors.first('address_number')}
                                                        </FormMessage>
                                                    )}
                                                </FormGroup>
                                                <FormGroup className="max-w-1/4">
                                                    <FormLabel>Toev.</FormLabel>
                                                    <FormInput
                                                        maxLength={10}
                                                        name="address_number_addition"
                                                        value={this.company.address_number_addition || ''}
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                            this.company!.address_number_addition = event.target.value;
                                                        }}
                                                        hasError={this.formErrors.has('address_number_addition')}
                                                    />
                                                    {this.formErrors.has('address_number_addition') && (
                                                        <FormMessage type="invalid">
                                                            {this.formErrors.first('address_number_addition')}
                                                        </FormMessage>
                                                    )}
                                                </FormGroup>
                                            </FormRow>
                                            <FormRow>
                                                <FormGroup className="max-w-1/4">
                                                    <FormLabel>Postcode</FormLabel>
                                                    <FormInput
                                                        maxLength={7}
                                                        name="address_postcode"
                                                        value={this.company.address_postcode}
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                            this.company!.address_postcode = event.target.value;
                                                        }}
                                                        hasError={this.formErrors.has('address_postcode')}
                                                    />
                                                    {this.formErrors.has('address_postcode') && (
                                                        <FormMessage type="invalid">
                                                            {this.formErrors.first('address_postcode')}
                                                        </FormMessage>
                                                    )}
                                                </FormGroup>
                                                <FormGroup>
                                                    <FormLabel>Stad</FormLabel>
                                                    <FormInput
                                                        name="address_city"
                                                        maxLength={50}
                                                        value={this.company.address_city}
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                                        this.company!.address_city = event.target.value;
                                                        }}
                                                        hasError={this.formErrors.has('address_city')}
                                                    />
                                                    {this.formErrors.has('address_city') && (
                                                        <FormMessage type="invalid">
                                                            {this.formErrors.first('address_city')}
                                                        </FormMessage>
                                                    )}
                                                </FormGroup>
                                            </FormRow>
                                            <FormGroup>
                                                <FormLabel>Land</FormLabel>
                                                <FormSelect
                                                    loading={countryStore?.isInitialOrLoading('get-countries')}
                                                    name="country_uuid"
                                                    value={this.company.country_uuid}
                                                    onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                                                                    this.company!.country_uuid = event.target.value;
                                                    }}
                                                    hasError={this.formErrors.has('country_uuid')}
                                                >
                                                    <option value="">Kiezen...</option>
                                                    {countryStore?.countries.map((country: ICountry) => (
                                                        <option value={country.uuid} key={country.uuid}>
                                                            {country.name}
                                                        </option>
                                                    ))}
                                                </FormSelect>
                                                {this.formErrors.has('country_uuid') && (
                                                    <FormMessage type="invalid">
                                                        {this.formErrors.first('country_uuid')}
                                                    </FormMessage>
                                                )}
                                            </FormGroup>
                                            <FormGroup>
                                                <FormLabel>Tijdzone</FormLabel>
                                                <FormSelect
                                                    name="timezone_uuid"
                                                    value={this.company.timezone_uuid}
                                                    loading={timezoneStore?.isLoading('get-timezones')}
                                                    onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                                                        this.company!.timezone_uuid = event.target.value;
                                                    }}
                                                    hasError={this.formErrors.has('timezone_uuid')}
                                                >
                                                    <option value="">Kiezen...</option>
                                                    {this.timezones.map((timezone: ITimezone) => (
                                                        <option
                                                            key={timezone.uuid}
                                                            value={timezone.uuid}
                                                        >
                                                            {timezone.name}
                                                        </option>
                                                    ))}
                                                </FormSelect>
                                                {this.formErrors.has('timezone_uuid') && (
                                                    <FormMessage type="invalid">
                                                        {this.formErrors.first('timezone_uuid')}
                                                    </FormMessage>
                                                )}
                                            </FormGroup>
                                        </div>
                                    </div>
                                    <div className="form-section grid grid-cols-2">
                                        <div>
                                            <h2 className="mb-4">Financiële gegevens</h2>
                                            <FormGroup>
                                                <FormLabel>BTW-identificatienummer</FormLabel>
                                                <FormInput
                                                    maxLength={20}
                                                    name="vat_number"
                                                    value={this.company.vat_number}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                                    this.company!.vat_number = event.target.value;
                                                    }}
                                                    hasError={this.formErrors.has('vat_number')}
                                                />
                                                {this.formErrors.has('vat_number') && (
                                                    <FormMessage type="invalid">
                                                        {this.formErrors.first('vat_number')}
                                                    </FormMessage>
                                                )}
                                            </FormGroup>
                                            <FormGroup>
                                                <FormLabel>Rekeningnummer</FormLabel>
                                                <FormInput
                                                    maxLength={20}
                                                    name="bank_number"
                                                    value={this.company.bank_number}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                                    this.company!.bank_number = event.target.value;
                                                    }}
                                                    hasError={this.formErrors.has('bank_number')}
                                                />
                                                {this.formErrors.has('bank_number') && (
                                                    <FormMessage type="invalid">
                                                        {this.formErrors.first('bank_number')}
                                                    </FormMessage>
                                                )}
                                            </FormGroup>
                                            <FormGroup>
                                                <FormLabel>Rekeningnummer t.n.v.</FormLabel>
                                                <FormInput
                                                    maxLength={100}
                                                    name="bank_holder_name"
                                                    value={this.company.bank_holder_name}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                                    this.company!.bank_holder_name = event.target.value;
                                                    }}
                                                    hasError={this.formErrors.has('bank_holder_name')}
                                                />
                                                {this.formErrors.has('bank_holder_name') && (
                                                    <FormMessage type="invalid">
                                                        {this.formErrors.first('bank_holder_name')}
                                                    </FormMessage>
                                                )}
                                            </FormGroup>
                                        </div>
                                    </div>
                                    <div className="form-section grid grid-cols-2">
                                        <div>
                                            <h2 className="mb-4">Factuurinstellingen</h2>
                                            <FormGroup>
                                                <FormLabel>Volgende factuurnummer</FormLabel>
                                                <FormInput
                                                    name="last_invoice_number"
                                                    type="number"
                                                    value={this.company.last_invoice_number !== '' ? Number(this.company.last_invoice_number) + 1 : ''}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                        if (event.target.value === '') {
                                                                        this.company!.last_invoice_number = '';
                                                        } else {
                                                                        this.company!.last_invoice_number = String(
                                                                            Number(event.target.value) - 1
                                                                        );
                                                        }
                                                    }}
                                                    hasError={this.formErrors.has('last_invoice_number')}
                                                />
                                                {this.formErrors.has('last_invoice_number') && (
                                                    <FormMessage type="invalid">
                                                        {this.formErrors.first('last_invoice_number')}
                                                    </FormMessage>
                                                )}
                                            </FormGroup>
                                            <FormGroup>
                                                <FormLabel>Factuurnummer</FormLabel>
                                                <FormInput
                                                    maxLength={50}
                                                    name="invoice_number_format"
                                                    value={this.company.invoice_number_format || ''}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                        this.company!.invoice_number_format = event.target.value;
                                                    }}
                                                    hasError={this.formErrors.has('invoice_number_format')}
                                                />
                                                {this.formErrors.has('invoice_number_format') && (
                                                    <FormMessage type="invalid">
                                                        {this.formErrors.first('invoice_number_format')}
                                                    </FormMessage>
                                                )}
                                                {!!this.company.invoice_number_format && (
                                                    <FormHelper id="invoice-number-example">
                                                        {`Voorbeeld: ${invoiceStore?.getInvoiceNumberExample(
                                                            this.company.invoice_number_format
                                                        )}`}
                                                    </FormHelper>
                                                )}
                                            </FormGroup>
                                            Te gebruiken variabelen:
                                            <div className="mb-4">
                                                {invoiceStore?.renderDocumentNumberVariables((key: string) => {
                                                    if (this.company) {
                                                        // eslint-disable-next-line max-len
                                                        this.company.invoice_number_format = this.company.invoice_number_format
                                                            ? this.company.invoice_number_format + key : key;
                                                    }
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-section grid grid-cols-2">
                                        <div>
                                            <h2 className="mb-4">Offerteïnstellingen</h2>
                                            <FormGroup>
                                                <FormLabel>Volgende offertenummer</FormLabel>
                                                <FormInput
                                                    name="last_quote_number"
                                                    type="number"
                                                    value={this.company.last_quote_number !== '' ? Number(this.company.last_quote_number) + 1 : ''}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                        if (event.target.value === '') {
                                                                        this.company!.last_quote_number = '';
                                                        } else {
                                                                        this.company!.last_quote_number = String(
                                                                            Number(event.target.value) - 1
                                                                        );
                                                        }
                                                    }}
                                                    hasError={this.formErrors.has('last_quote_number')}
                                                />
                                                {this.formErrors.has('last_quote_number') && (
                                                    <FormMessage type="invalid">
                                                        {this.formErrors.first('last_quote_number')}
                                                    </FormMessage>
                                                )}
                                            </FormGroup>
                                            <FormGroup>
                                                <FormLabel>Offertenummer</FormLabel>
                                                <FormInput
                                                    maxLength={50}
                                                    name="quote_number_format"
                                                    value={this.company.quote_number_format || ''}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                        this.company!.quote_number_format = event.target.value;
                                                    }}
                                                    hasError={this.formErrors.has('quote_number_format')}
                                                />
                                                {this.formErrors.has('quote_number_format') && (
                                                    <FormMessage type="invalid">
                                                        {this.formErrors.first('quote_number_format')}
                                                    </FormMessage>
                                                )}
                                                {!!this.company.quote_number_format && (
                                                    <FormHelper id="quote-number-example">
                                                        {`Voorbeeld: ${invoiceStore?.getInvoiceNumberExample(
                                                            this.company.quote_number_format
                                                        )}`}
                                                    </FormHelper>
                                                )}
                                            </FormGroup>
                                            Te gebruiken variabelen:
                                            <div className="mb-4">
                                                {invoiceStore?.renderDocumentNumberVariables((key: string) => {
                                                    if (this.company) {
                                                        // eslint-disable-next-line max-len
                                                        this.company.quote_number_format = this.company.quote_number_format
                                                            ? this.company.quote_number_format + key : key;
                                                    }
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                    <Button
                                        type="submit"
                                        variant="primary"
                                        loading={companyStore?.isLoading('edit-company')}
                                    >
                                        Bedrijf opslaan
                                    </Button>
                                </form>
                            </Card>
                        )}
                    </Container>
                </UsesCompany>
            </Page>
        );
    }
}

export default EditCompanyPage;
