import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import Card from '../Card';
import FormGroup from '../Forms/Group';
import FormInput from '../Forms/Input';
import FormLabel from '../Forms/Label';
import Button from '../Button';
import List from '../List';
import ListItem from '../List/Item';
import Notification from '../Notification';
import debounce from '../../../app/utils/debounce';
import { ICustomer } from '../../../app/stores/customers/interfaces/ICustomer';
import { CompanyStore } from '../../../app/stores/company/CompanyStore';

export interface CustomerSearcherProps {
    companyStore?: CompanyStore;
    companyUuid: string;
    onCustomerSelected: (customer: ICustomer) => void;
    className?: string;
}

@inject('companyStore')
@observer
class CustomerSearcher extends React.Component<CustomerSearcherProps, unknown> {
    @observable private searchString = '';
    @observable private searched = false;
    @observable private result?: ICustomer[];
    private debouncedSearch: (searchString: string) => void;

    public constructor(props: CustomerSearcherProps) {
        super(props);

        this.debouncedSearch = debounce<(searchString: string) => void>(
            this.search.bind(this),
        300);
    }

    private onCustomerSelected(customer: ICustomer): void {
        const { onCustomerSelected } = this.props;

        onCustomerSelected(customer);
        this.resetSearchResult();
        this.searchString = '';
    }

    private onSearchChanged(searchString: string): void {
        this.resetSearchResult();
        this.searched = false;

        this.searchString = searchString;

        if (!searchString) {
            return;
        }

        this.debouncedSearch(searchString);
    }

    private resetSearchResult(): void {
        this.result = undefined;
        this.searched = false;
    }

    private search(searchString: string): void {
        const { companyStore, companyUuid } = this.props;

        companyStore?.getCustomers(companyUuid, searchString)
            .then((result: ICustomer[]): void => {
                this.searched = true;
                this.result = result;
            });
    }

    private submitSearch(event: React.FormEvent): void {
        event.preventDefault();

        if (!this.searchString) {
            return;
        }

        this.search(this.searchString);
    }

    private searchResultFormat(customer: ICustomer): string {
        if (customer.company_name) {
            return customer.company_name;
        }

        return '';
    }

    private renderResult(): React.ReactNode {
        return (
            <>
                <div className="searcher-result">
                    <List className="searcher-result-list">
                        {!this.result || !this.result.length ? (
                            <Notification
                                variant="info"
                                message="Er zijn geen klanten gevonden met deze zoekterm"
                                id="no-customers"
                            />
                        ) : this.result.map((customer: ICustomer) => (
                            <ListItem
                                key={customer.uuid}
                                className="searcher-result-item"
                                onClick={() => {
                                    this.onCustomerSelected(customer);
                                }}
                            >
                                <strong>{`${customer.firstname} ${customer.lastname}`}</strong>
                                <span className="block">{ this.searchResultFormat(customer) }</span>
                                <span className="block">{customer.email}</span>
                            </ListItem>
                        ))}
                    </List>
                </div>
            </>
        );
    }

    public render(): React.ReactNode {
        const { companyStore, className } = this.props;

        return (
            <Card
                className={className}
            >
                <div className="searcher-container">
                    <form onSubmit={(event: React.FormEvent) => this.submitSearch(event)}>
                        <FormGroup>
                            <FormLabel>Klant zoeken</FormLabel>
                            <FormInput
                                name="search"
                                append={(
                                    <Button
                                        type="submit"
                                        variant="primary"
                                        loading={companyStore?.isLoading('get-customers')}
                                    >
                                        <FontAwesomeIcon icon={faSearch} />
                                    </Button>
                                )}
                                value={this.searchString}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    this.onSearchChanged(event.target.value);
                                }}
                            />
                        </FormGroup>
                    </form>
                    {this.searched && this.renderResult()}
                </div>
            </Card>
        );
    }
}

export default CustomerSearcher;
