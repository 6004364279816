import React, { useState } from 'react';
import generateGuid from '../../../app/utils/generateGuid';

export interface IncludingVatSwitcherProps {
    name: string;
    value: boolean;
    firstOptionText: string;
    secondOptionText: string;
    onSwitch: (choice: boolean) => void;
}

export const FormSwitcher: React.FunctionComponent<IncludingVatSwitcherProps> = (
    {
        name,
        onSwitch,
        value,
        firstOptionText,
        secondOptionText
    }
) => {
    const [option1Id] = useState(generateGuid());
    const [option2Id] = useState(generateGuid());

    return (
        <div className="form-switcher">
            <input
                checked={value}
                type="radio"
                id={option1Id}
                name={name}
                onChange={() => onSwitch(true)}
            />
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor={option1Id} className="form-switcher-option">
                {firstOptionText}
            </label>
            <input
                checked={!value}
                type="radio"
                id={option2Id}
                name={name}
                onChange={() => onSwitch(false)}
            />
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor={option2Id} className="form-switcher-option">
                {secondOptionText}
            </label>
        </div>
    );
};
