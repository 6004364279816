import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import Container from '../../shared/Container';
import Page from '../../shared/Page';
import Card from '../../shared/Card';
import { UserStore } from '../../../app/stores/user/UserStore';
import { NotificationStore } from '../../../app/stores/notifications/NotificationStore';
import { AuthStore } from '../../../app/stores/auth/AuthStore';
import { IEditUser } from '../../../app/stores/user/interfaces/IEditUser';
import FormLabel from '../../shared/Forms/Label';
import FormInput from '../../shared/Forms/Input';
import FormGroup from '../../shared/Forms/Group';
import { FormErrors } from '../../../app/errors/FormErrors';
import FormMessage from '../../shared/Forms/Message';
import FormHelper from '../../shared/Forms/FormHelper';
import Button from '../../shared/Button';
import { IUser } from '../../../app/stores/auth/interfaces/IUser';
import { DataInvalidError } from '../../../app/errors/DataInvalidError';

export interface SettingsPageProps {
    userStore?: UserStore;
    notificationStore?: NotificationStore;
    authStore?: AuthStore;
}

@inject('userStore', 'notificationStore', 'authStore')
@observer
class SettingsPage extends React.Component<SettingsPageProps, unknown> {
    @observable private formValues: IEditUser = {
        name: '',
        surname: '',
        email: '',
        password: '',
        password_confirmation: ''
    };
    @observable private formErrors: FormErrors;

    public constructor(props: SettingsPageProps) {
        super(props);

        this.formErrors = new FormErrors();
    }

    public componentDidMount(): void {
        const { authStore } = this.props;

        if (authStore?.user) {
            this.formValues = {
                name: authStore.user.name,
                surname: authStore.user.surname
            };
        }
    }

    private submitEdit(event: React.FormEvent): void {
        event.preventDefault();

        const { userStore, notificationStore, authStore } = this.props;

        notificationStore?.clear();
        this.formErrors.clear();

        userStore?.editUser(this.formValues).then((user: IUser | undefined) => {
            if (user) {
                notificationStore?.addNotification({
                    message: 'Gegevens succesvol aangepast',
                    variant: 'success'
                });
                if (authStore?.user) {
                    authStore.user = {
                        ...authStore.user,
                        name: this.formValues.name,
                        surname: this.formValues.surname,
                        email: this.formValues.email || authStore.user.email
                    };
                }
                this.formValues = {
                    ...this.formValues,
                    password: '',
                    password_confirmation: '',
                    email: ''
                };
            }
        }).catch((e: DataInvalidError) => {
            notificationStore?.addNotification({
                message: e.message,
                variant: 'danger'
            });
            this.formErrors.set(e.errors);
        });
    }

    public render(): React.ReactNode {
        const { authStore, userStore } = this.props;

        return (
            <Page title="Instellingen">
                <Container center className="w-3/4">
                    <Card>
                        <form onSubmit={(event: React.FormEvent) => this.submitEdit(event)}>
                            <div className="form-section grid grid-cols-2">
                                <div>
                                    <FormGroup>
                                        <FormLabel>Voornaam</FormLabel>
                                        <FormInput
                                            name="name"
                                            maxLength={50}
                                            value={this.formValues.name}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                this.formValues.name = event.target.value;
                                            }}
                                            hasError={this.formErrors.has('name')}
                                        />
                                        {this.formErrors.has('name') && (
                                            <FormMessage type="invalid">
                                                {this.formErrors.first('name')}
                                            </FormMessage>
                                        )}
                                    </FormGroup>
                                    <FormGroup>
                                        <FormLabel>Achternaam</FormLabel>
                                        <FormInput
                                            name="surname"
                                            maxLength={50}
                                            value={this.formValues.surname}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                this.formValues.surname = event.target.value;
                                            }}
                                            hasError={this.formErrors.has('surname')}
                                        />
                                        {this.formErrors.has('surname') && (
                                            <FormMessage type="invalid">
                                                {this.formErrors.first('surname')}
                                            </FormMessage>
                                        )}
                                    </FormGroup>
                                </div>
                            </div>
                            <div className="form-section grid grid-cols-2">
                                <FormGroup>
                                    <FormLabel>Nieuw e-mailadres</FormLabel>
                                    <FormInput
                                        name="email"
                                        maxLength={50}
                                        value={this.formValues.email || ''}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            this.formValues.email = event.target.value;
                                        }}
                                        hasError={this.formErrors.has('email')}
                                    />
                                    {this.formErrors.has('email') && (
                                        <FormMessage type="invalid">
                                            {this.formErrors.first('email')}
                                        </FormMessage>
                                    )}
                                    <FormHelper id="current-email">
                                        {`Huidige e-mailadres: ${authStore?.user?.email}`}
                                    </FormHelper>
                                </FormGroup>
                            </div>
                            <div className="form-section grid grid-cols-2">
                                <div>
                                    <FormGroup>
                                        <FormLabel>Nieuw wachtwoord</FormLabel>
                                        <FormInput
                                            name="password"
                                            value={this.formValues.password || ''}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                this.formValues.password = event.target.value;
                                            }}
                                            hasError={this.formErrors.has('password')}
                                            type="password"
                                        />
                                        {this.formErrors.has('password') && (
                                            <FormMessage type="invalid">
                                                {this.formErrors.first('password')}
                                            </FormMessage>
                                        )}
                                    </FormGroup>
                                    <FormGroup>
                                        <FormLabel>Nieuw wachtwoord (herhaling)</FormLabel>
                                        <FormInput
                                            name="password_confirmation"
                                            value={this.formValues.password_confirmation || ''}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                this.formValues.password_confirmation = event.target.value;
                                            }}
                                            hasError={this.formErrors.has('password_confirmation')}
                                            type="password"
                                        />
                                        {this.formErrors.has('password_confirmation') && (
                                            <FormMessage type="invalid">
                                                {this.formErrors.first('password_confirmation')}
                                            </FormMessage>
                                        )}
                                    </FormGroup>
                                </div>
                            </div>
                            <Button
                                type="submit"
                                variant="primary"
                                loading={userStore?.isLoading('edit-user')}
                            >
                                Opslaan
                            </Button>
                        </form>
                    </Card>
                </Container>
            </Page>
        );
    }
}

export default SettingsPage;
