import * as React from 'react';
import clsx from 'clsx';

type FormGroupProps = React.HTMLAttributes<HTMLDivElement>;

const FormGroup = React.forwardRef<HTMLDivElement, FormGroupProps>((
    {
        children,
        className
    },
    ref
): React.ReactElement => (
    <div
        ref={ref}
        className={clsx(
            'form-group',
            className
        )}
    >
        {children}
    </div>
));

FormGroup.displayName = 'FormGroup';

export default FormGroup;
