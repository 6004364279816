import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { RouteComponentProps, withRouter } from 'react-router';
import { observable } from 'mobx';
import moment from 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { NotFoundError } from '../../../app/errors/NotFoundError';
import { NotificationStore } from '../../../app/stores/notifications/NotificationStore';
import Page from '../../shared/Page';
import UsesCompany from '../../shared/company/UsesCompany';
import Container from '../../shared/Container';
import Card from '../../shared/Card';
import { UserStore } from '../../../app/stores/user/UserStore';
import { QuoteStore } from '../../../app/stores/quotes/QuoteStore';
import { IQuote } from '../../../app/stores/quotes/interfaces/IQuote';
import { IQuoteItem } from '../../../app/stores/quotes/interfaces/IQuoteItem';
import Button from '../../shared/Button';

interface QuoteDetailsPageParams {
    uuid: string;
}

export interface QuoteDetailsPageProps extends RouteComponentProps<QuoteDetailsPageParams> {
    quoteStore?: QuoteStore;
    userStore?: UserStore;
    notificationStore?: NotificationStore;
}

@inject('quoteStore', 'notificationStore', 'userStore')
@observer
class QuoteDetailsPage extends React.Component<QuoteDetailsPageProps, unknown> {
    @observable private quote?: IQuote;

    public componentDidMount(): void {
        const { userStore } = this.props;

        if (userStore?.currentCompany) {
            this.refreshQuote();
        }
    }

    public refreshQuote(): void {
        const {
            quoteStore,
            notificationStore,
            match: {
                params: {
                    uuid
                }
            }
        } = this.props;

        quoteStore?.getQuote(uuid).then((quote: IQuote | undefined) => {
            if (quote) {
                this.quote = quote;
            }
        }).catch((e: NotFoundError) => {
            notificationStore?.addNotification({
                message: e?.message || 'Offerte niet gevonden',
                messageDetails: e?.details,
                variant: 'danger',
                closable: false
            });
            this.quote = undefined;
        });
    }

    private changeCompany(): void {
        const { notificationStore } = this.props;

        notificationStore?.clear();

        this.refreshQuote();
    }

    private downloadQuote() {
        const { quoteStore } = this.props;

        if (this.quote) {
            quoteStore?.downloadQuote(this.quote.uuid, this.quote.quote_number);
        }
    }

    public render(): React.ReactNode {
        const { quoteStore } = this.props;

        return (
            <Page
                title={`Offertedetails ${this.quote
                    ? `van offerte ${this.quote.quote_number}`
                    : ''}`}
                actions={[
                    <Button
                        variant="secondary"
                        key="download-pdf"
                        id="download-pdf"
                        loading={quoteStore?.isLoading(`download-quote-${this.quote?.uuid}`)}
                        disabled={!this.quote}
                        onClick={() => {
                            this.downloadQuote();
                        }}
                        icon={<FontAwesomeIcon icon={faDownload} />}
                    >
                        PDF Downloaden
                    </Button>
                ]}
            >
                <Container center className="w-3/4">
                    <UsesCompany
                        onCompanySelected={() => {
                            this.changeCompany();
                        }}
                    >
                        {this.quote && (
                            <Card className="invoice-wrapper mx-auto">
                                {this.quote.company.logo && (
                                    <div className="mb-16">
                                        <img
                                            alt="logo"
                                            src={this.quote.company.logo}
                                            id="company-logo"
                                            className="company-logo"
                                        />
                                    </div>
                                )}
                                <div className="grid grid-cols-3 grid-gap-4 mb-8">
                                    <div className="customer-details">
                                        {/* eslint-disable-next-line max-len */}
                                        <div>{`${this.quote.customer.firstname} ${this.quote.customer.lastname}`}</div>
                                        <div>{this.quote.customer.address}</div>
                                        {/* eslint-disable-next-line max-len */}
                                        <div>{`${this.quote.customer.address_postcode} ${this.quote.customer.address_city}`}</div>
                                    </div>
                                    <div />
                                    <div className="company-details">
                                        <strong>{this.quote.company.name}</strong>
                                        <div>{this.quote.company.address}</div>
                                        <div className="mb-4">
                                            {/* eslint-disable-next-line max-len */}
                                            {`${this.quote.company.address_postcode} ${this.quote.company.address_city}`}
                                        </div>
                                        <div>{`Kvk: ${this.quote.company.coc_number}`}</div>
                                        <div>{`BTW: ${this.quote.company.vat_number}`}</div>
                                        <div>{`Bank: ${this.quote.company.bank_number}`}</div>
                                    </div>
                                </div>
                                <div className="grid grid-cols-3 grid-gap-4 mb-16">
                                    <div>
                                        <h2 className="quote-number">{`Offerte ${this.quote.quote_number}`}</h2>
                                    </div>
                                    <div />
                                    <div id="quote-dates">
                                        <div>
                                            {`Offertedatum: ${moment(this.quote.created_at)
                                                .format('DD-MM-YYYY')}`}
                                        </div>
                                    </div>
                                </div>
                                <section className="items-section">
                                    <table className="table items-table">
                                        <thead>
                                            <tr>
                                                <th><strong>Omschrijving</strong></th>
                                                <th className="text-right"><strong>Aantal</strong></th>
                                                <th className="text-right"><strong>Prijs</strong></th>
                                                <th className="text-right"><strong>Totaal</strong></th>
                                                <th className="text-right"><strong>BTW</strong></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.quote.items.map((item: IQuoteItem) => (
                                                <tr
                                                    key={String(item.price) + String(item.quantity) + item.description}
                                                    className="items-table-item"
                                                >
                                                    <td>{item.description}</td>
                                                    <td className="text-right">{item.quantity}</td>
                                                    <td className="text-right">{`€ ${item.price}`}</td>
                                                    <td className="text-right">{`€ ${item.total}`}</td>
                                                    <td className="text-right">{`${item.vat_rate.percentage}%`}</td>
                                                </tr>
                                            ))}
                                            <tr>
                                                <td />
                                                <td />
                                                <td className="text-right total">
                                                    <strong>Subtotaal</strong>
                                                </td>
                                                <td className="text-right total">
                                                    {`€ ${this.quote.sub_total}`}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td />
                                                <td />
                                                <td className="text-right total">
                                                    <strong>Totaal</strong>
                                                </td>
                                                <td className="text-right total">
                                                    {`€ ${this.quote.total}`}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </section>
                            </Card>
                        )}
                    </UsesCompany>
                </Container>
            </Page>
        );
    }
}

export default withRouter(QuoteDetailsPage);
