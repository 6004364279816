import * as React from 'react';
import ReactModal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Card from '../Card';
import Button from '../Button';

export interface ModalProps {
    isOpen: boolean;
    closable?: boolean;
    title?: string;
    onRequestClose?(event: React.MouseEvent | React.KeyboardEvent): void;
}

const Modal = React.forwardRef<ReactModal, React.PropsWithChildren<ModalProps>>((
    {
        children,
        onRequestClose,
        isOpen,
        title,
        closable
    },
    ref
): React.ReactElement => (
    <ReactModal
        ref={ref}
        overlayClassName="modal-backdrop modal-container"
        className="modal-content mx-auto"
        onRequestClose={onRequestClose}
        isOpen={isOpen}
    >
        <Card>
            {(title || closable) && (
                <div className="flex items-center mb-4">
                    {title && <h2>{title}</h2>}
                    {closable && (
                        <Button
                            variant="light"
                            onClick={onRequestClose}
                            className="ml-auto"
                            id="modal-close"
                        >
                            <FontAwesomeIcon icon={faTimes} />
                        </Button>
                    )}
                </div>
            )}
            {children}
        </Card>
    </ReactModal>
));

Modal.displayName = 'Modal';

export default Modal;
