import { LoadingStore } from '../loading/LoadingStore';
import { INewCustomer } from './interfaces/INewCustomer';
import { api } from '../../services/api';
import { ResponseStatus } from '../../services/api/ResponseStatus';
import { DataInvalidError } from '../../errors/DataInvalidError';
import { IErrorResponse } from '../../services/api/IErrorResponse';
import { IResponse } from '../../services/api/IResponse';
import { ICustomer } from './interfaces/ICustomer';
import { application } from '../../application';
import { SomethingHappenedError } from '../../errors/SomethingHappenedError';
import { ApiError } from '../../errors/ApiError';
import { IListCustomer } from './interfaces/IListCustomer';

export class CustomerStore extends LoadingStore {
    public async createCustomer(customer: INewCustomer): Promise<ICustomer | undefined> {
        this.setLoading('create-customer');

        try {
            const response = await api.post<IResponse<ICustomer> | IErrorResponse>(
                'api/customers/create',
                customer
            );
            /* istanbul ignore else */
            if (response.status === ResponseStatus.CREATED) {
                return (response.data as IResponse<ICustomer>).data;
            }
        } catch (e) {
            /* istanbul ignore else */
            if (e.response.status === ResponseStatus.INVALID) {
                throw new DataInvalidError(
                    (e.response.data as IErrorResponse).message,
                    (e.response.data as IErrorResponse).errors!
                );
            }
            /* istanbul ignore next */
            application.handleError(new ApiError(e));
        } finally {
            this.setLoaded('create-customer');
        }
        /* istanbul ignore next */
        application.handleError(new SomethingHappenedError());
        /* istanbul ignore next */
        return undefined;
    }

    public async deleteCustomer(customer: IListCustomer): Promise<void> {
        this.setLoading(`delete-customer-${customer.uuid}`);

        try {
            const response = await api.delete(`/api/customers/${customer.uuid}`);

            if (response.status === ResponseStatus.EMPTY) {
                return;
            }
        } catch (e) {
            application.handleError(new ApiError(e));

            throw new SomethingHappenedError();
        }

        application.handleError(new SomethingHappenedError());
    }
}

const customerStore = new CustomerStore();

export {
    customerStore
};
