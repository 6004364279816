import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import Page from '../../shared/Page';
import Card from '../../shared/Card';
import Container from '../../shared/Container';
import FormGroup from '../../shared/Forms/Group';
import FormLabel from '../../shared/Forms/Label';
import { FormErrors } from '../../../app/errors/FormErrors';
import FormInput from '../../shared/Forms/Input';
import Button from '../../shared/Button';
import { NotificationStore } from '../../../app/stores/notifications/NotificationStore';
import { DataInvalidError } from '../../../app/errors/DataInvalidError';
import FormMessage from '../../shared/Forms/Message';
import { UserStore } from '../../../app/stores/user/UserStore';
import { INewUser } from '../../../app/stores/user/interfaces/INewUser';
import { IUser } from '../../../app/stores/auth/interfaces/IUser';
import { AuthStore } from '../../../app/stores/auth/AuthStore';

export interface CreateUserPageProps {
    userStore?: UserStore;
    notificationStore?: NotificationStore;
    authStore?: AuthStore;
}

@inject('notificationStore', 'userStore', 'authStore')
@observer
class CreateUserPage extends React.Component<CreateUserPageProps, unknown> {
    private defaultFormValues: INewUser = {
        name: '',
        surname: '',
        email: '',
        timezone_uuid: ''
    }
    @observable private formErrors: FormErrors;
    @observable private formValues: INewUser;

    public constructor(props: CreateUserPageProps) {
        super(props);

        this.formValues = this.defaultFormValues;
        this.formErrors = new FormErrors();
    }

    private async submitUser(event: React.FormEvent): Promise<void> {
        event.preventDefault();

        const { notificationStore, userStore } = this.props;

        this.formErrors.clear();
        notificationStore?.clear();

        userStore?.createUser(this.formValues).then((user: IUser | undefined): void => {
            /* istanbul ignore else */
            if (user) {
                this.formValues = this.defaultFormValues;
                notificationStore?.addNotification({
                    variant: 'success',
                    message: 'Gebruiker succesvol gemaakt'
                });
            }
        }).catch((e: DataInvalidError) => {
            notificationStore?.addNotification({
                variant: 'danger',
                message: e.message,
                closable: true
            });
            this.formErrors.set(e.errors);
        });
    }

    public render(): React.ReactNode {
        const { userStore } = this.props;

        return (
            <Page title="Gebruiker toevoegen">
                <Container center className="w-3/4">
                    <Card>
                        <form onSubmit={(event: React.FormEvent) => this.submitUser(event)}>
                            <div className="form-section grid grid-cols-2">
                                <div>
                                    <FormGroup>
                                        <FormLabel>Voornaam</FormLabel>
                                        <FormInput
                                            name="name"
                                            maxLength={50}
                                            value={this.formValues.name}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                this.formValues.name = event.target.value;
                                            }}
                                            hasError={this.formErrors.has('name')}
                                        />
                                        {this.formErrors.has('name') && (
                                            <FormMessage type="invalid">
                                                {this.formErrors.first('name')}
                                            </FormMessage>
                                        )}
                                    </FormGroup>
                                    <FormGroup>
                                        <FormLabel>Achternaam</FormLabel>
                                        <FormInput
                                            name="surname"
                                            maxLength={50}
                                            value={this.formValues.surname}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                this.formValues.surname = event.target.value;
                                            }}
                                            hasError={this.formErrors.has('surname')}
                                        />
                                        {this.formErrors.has('surname') && (
                                            <FormMessage type="invalid">
                                                {this.formErrors.first('surname')}
                                            </FormMessage>
                                        )}
                                    </FormGroup>
                                    <FormGroup className="max-w-1/2">
                                        <FormLabel>E-mailadres</FormLabel>
                                        <FormInput
                                            maxLength={255}
                                            name="email"
                                            value={this.formValues.email}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                this.formValues.email = event.target.value;
                                            }}
                                            hasError={this.formErrors.has('email')}
                                        />
                                        {this.formErrors.has('email') && (
                                            <FormMessage type="invalid">
                                                {this.formErrors.first('email')}
                                            </FormMessage>
                                        )}
                                    </FormGroup>
                                </div>
                            </div>
                            <Button
                                type="submit"
                                variant="primary"
                                loading={userStore?.isLoading('create-user')}
                            >
                                Gebruiker maken
                            </Button>
                        </form>
                    </Card>
                </Container>
            </Page>
        );
    }
}

export default CreateUserPage;
