import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import FormGroup from '../Forms/Group';
import FormInput from '../Forms/Input';
import FormLabel from '../Forms/Label';
import Button from '../Button';
import { KvkStore } from '../../../app/stores/kvk/KvkStore';
import { IKvkEntry } from '../../../app/stores/kvk/interfaces/IKvkEntry';
import List from '../List';
import ListItem from '../List/Item';
import Notification from '../Notification';
import { IPaginated } from '../../../app/utils/interface/IPaginated';
import Paginate from '../Paginate';
import debounce from '../../../app/utils/debounce';

export interface KvkSearcherProps {
    kvkStore?: KvkStore;
    onCompanySelected: (company: IKvkEntry) => void;
}

@inject('kvkStore')
@observer
class KvkSearcher extends React.Component<KvkSearcherProps, unknown> {
    @observable private searchString = '';
    @observable private searched = false;
    @observable private result?: IPaginated<IKvkEntry>;
    private debouncedSearch: (searchString: string, start: number) => void;

    public constructor(props: KvkSearcherProps) {
        super(props);

        this.debouncedSearch = debounce<(searchString: string, start: number) => void>(
            this.search.bind(this),
        300);
    }

    private onCompanySelected(company: IKvkEntry): void {
        const { onCompanySelected } = this.props;

        onCompanySelected(company);
        this.resetSearchResult();
        this.searchString = '';
    }

    private onSearchChanged(searchString: string): void {
        this.resetSearchResult();
        this.searched = false;

        this.searchString = searchString;

        if (!searchString) {
            return;
        }

        this.debouncedSearch(searchString, 0);
    }

    private resetSearchResult(): void {
        this.result = undefined;
        this.searched = false;
    }

    private search(searchString: string, start: number): void {
        const { kvkStore } = this.props;

        kvkStore?.searchCompany(searchString, start)
            .then((result: IPaginated<IKvkEntry> | undefined): void => {
                this.searched = true;
                this.result = result;
            });
    }

    private submitSearch(event: React.FormEvent): void {
        event.preventDefault();

        if (!this.searchString) {
            return;
        }

        this.search(this.searchString, 0);
    }

    private renderResult(): React.ReactNode {
        return (
            <>
                <div className="searcher-result">
                    {this.result?.data.length && (
                        <div className="flex">
                            <Paginate
                                className="ml-auto"
                                pageCount={this.result.meta.last_page}
                                onPageChange={({ selected }) => {
                                    this.search(this.searchString, selected);
                                }}
                            />
                        </div>
                    )}
                    <List className="searcher-result-list">
                        {!this.result || !this.result.data.length ? (
                            <Notification
                                variant="info"
                                message="Er zijn geen bedrijven gevonden met deze zoekterm"
                                id="no-companies"
                            />
                        ) : this.result.data.map((entry: IKvkEntry) => (
                            <ListItem
                                key={entry.coc_number + entry.name}
                                className="searcher-result-item"
                                onClick={() => {
                                    this.onCompanySelected(entry);
                                }}
                            >
                                <strong>{entry.name}</strong>
                                <span className="block">{`${entry.coc_number} · ${entry.type}`}</span>
                            </ListItem>
                        ))}
                    </List>
                </div>
            </>
        );
    }

    public render(): React.ReactNode {
        const { kvkStore } = this.props;

        return (
            <div className="mb-4">
                <div className="searcher-container">
                    <form onSubmit={(event: React.FormEvent) => this.submitSearch(event)}>
                        <FormGroup>
                            <FormLabel>Bedrijf zoeken</FormLabel>
                            <FormInput
                                name="search"
                                append={(
                                    <Button
                                        type="submit"
                                        variant="primary"
                                        loading={kvkStore?.isLoading('search-company')}
                                    >
                                        <FontAwesomeIcon icon={faSearch} />
                                    </Button>
                                )}
                                value={this.searchString}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    this.onSearchChanged(event.target.value);
                                }}
                            />
                        </FormGroup>
                    </form>
                    {this.searched && this.renderResult()}
                </div>
            </div>
        );
    }
}

export default KvkSearcher;
