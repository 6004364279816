import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import FormGroup from '../../shared/Forms/Group';
import FormLabel from '../../shared/Forms/Label';
import FormInput from '../../shared/Forms/Input';
import { FormErrors } from '../../../app/errors/FormErrors';
import Button from '../../shared/Button';
import { UserStore } from '../../../app/stores/user/UserStore';
import { DataInvalidError } from '../../../app/errors/DataInvalidError';
import FormMessage from '../../shared/Forms/Message';
import { PaymentMethodStore } from '../../../app/stores/payment-methods/PaymentMethodStore';
import { IPaymentMethod } from '../../../app/stores/payment-methods/interfaces/IPaymentMethod';
import { NotificationStore } from '../../../app/stores/notifications/NotificationStore';
import { INotification } from '../../../app/stores/notifications/interfaces/INotification';
import Notification from '../../shared/Notification';

export interface CreatePaymentMethodModalProps {
    onPaymentMethodCreated: () => void;
    paymentMethodStore?: PaymentMethodStore;
    userStore?: UserStore;
    notificationStore?: NotificationStore;
}

@inject('paymentMethodStore', 'userStore', 'notificationStore')
@observer
class CreatePaymentMethodModal extends React.Component<CreatePaymentMethodModalProps, unknown> {
    public static NOTIFICATION_NAMESPACE = 'create_payment_method';

    @observable private formValues: Omit<IPaymentMethod, 'uuid'> = {
        name: ''
    };
    @observable private formErrors: FormErrors;

    public constructor(props: CreatePaymentMethodModalProps) {
        super(props);

        this.formErrors = new FormErrors();
    }

    private createPaymentMethod(event: React.FormEvent) {
        event.preventDefault();

        const {
            onPaymentMethodCreated,
            paymentMethodStore,
            userStore,
            notificationStore
        } = this.props;

        notificationStore?.clear(CreatePaymentMethodModal.NOTIFICATION_NAMESPACE);
        this.formErrors.clear();

        if (userStore?.currentCompany) {
            paymentMethodStore?.createPaymentMethod(
                userStore.currentCompany.company.uuid,
                this.formValues.name
            ).then((paymentMethod: IPaymentMethod | undefined) => {
                if (paymentMethod) {
                    onPaymentMethodCreated();
                }
            }).catch((e: DataInvalidError) => {
                this.formErrors.set(e.errors);
                notificationStore?.addNotification({
                    variant: 'danger',
                    message: e.message,
                    namespace: CreatePaymentMethodModal.NOTIFICATION_NAMESPACE
                });
            });
        }
    }

    public render(): React.ReactNode {
        const { notificationStore } = this.props;

        return (
            <>
                <div className="mb-4">
                    {notificationStore?.all(
                        CreatePaymentMethodModal.NOTIFICATION_NAMESPACE
                    ).map((notification: INotification) => (
                        <Notification key={notification.id} {...notification} />
                    ))}
                </div>
                <form
                    onSubmit={(event: React.FormEvent) => this.createPaymentMethod(event)}
                >
                    <FormGroup>
                        <FormLabel>Naam</FormLabel>
                        <FormInput
                            name="name"
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                this.formValues.name = event.target.value;
                            }}
                            value={this.formValues.name}
                            hasError={this.formErrors.has('name')}
                            maxLength={50}
                        />
                        {this.formErrors.has('name') && (
                            <FormMessage type="invalid">
                                {this.formErrors.first('name')}
                            </FormMessage>
                        )}
                    </FormGroup>
                    <Button
                        type="submit"
                        variant="primary"
                    >
                        Toevoegen
                    </Button>
                </form>
            </>
        );
    }
}

export default CreatePaymentMethodModal;
