import { IKvkEntry } from './interfaces/IKvkEntry';
import { LoadingStore } from '../loading/LoadingStore';
import { api } from '../../services/api';
import { ResponseStatus } from '../../services/api/ResponseStatus';
import { application } from '../../application';
import { ApiError } from '../../errors/ApiError';
import { SomethingHappenedError } from '../../errors/SomethingHappenedError';
import { IPaginated } from '../../utils/interface/IPaginated';

export class KvkStore extends LoadingStore {
    public async searchCompany(searchString: string, start: number): Promise<IPaginated<IKvkEntry> | undefined> {
        this.setLoading('search-company');

        try {
            const response = await api.get<IPaginated<IKvkEntry>>(`/api/kvk/search/${searchString}?page=${start}`);

            if (response.status === ResponseStatus.OK) {
                return response.data;
            }
        } catch (e) {
            if (e.response?.status !== ResponseStatus.NOT_FOUND) {
                application.handleError(new ApiError(e));
            }

            return undefined;
        } finally {
            this.setLoaded('search-company');
        }

        application.handleError(new SomethingHappenedError());

        return undefined;
    }
}

const kvkStore = new KvkStore();

export {
    kvkStore
};
