import * as React from 'react';
import clsx from 'clsx';

const FormHelper = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>((
    {
        className,
        children
    },
    ref
): React.ReactElement => (
    <div
        ref={ref}
        className={clsx(
            'form-helper',
            className
        )}
    >
        {children}
    </div>
));

FormHelper.displayName = 'FormHelper';

export default FormHelper;
